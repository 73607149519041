import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import useDashboard from "../../hooks/useDashboard";

const ReportDateSelect = () => {
  const { getIntakes } = useDashboard();
  const [dateStart, setDateStart] = useState();
  const [dateFinish, setDateFinish] = useState();
  const [convertDateStart, setConvertDateStart] = useState();
  const [convertDateFinish, setConvertDateFinish] = useState();

  const handleSetDate = (e) => {
    if (e.target.name === "start") {
      setDateStart(new Date(e.target.value).toISOString());
      setConvertDateStart(e.target.value);
    } else {
      setDateFinish(new Date(e.target.value).toISOString());
      setConvertDateFinish(e.target.value);
    }
  };

  useEffect(() => {
    const date = new Date().toISOString();
    let previusDate = new Date(date);
    previusDate = previusDate.setMonth(previusDate.getMonth() - 1);
    setDateStart(new Date(previusDate).toISOString());
    setConvertDateStart(new Date(previusDate).toISOString().slice(0, 10));
    setDateFinish(date);
    setConvertDateFinish(date.slice(0, 10));
  }, []);

  return (
    <Row className="mt-3">
      <Col>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label as="h6">Start date:</Form.Label>
          <Form.Control
            type="date"
            value={convertDateStart}
            name="start"
            onChange={handleSetDate}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label as="h6">Finish date:</Form.Label>
          <Form.Control
            type="date"
            value={convertDateFinish}
            name="end"
            onChange={handleSetDate}
          />
        </Form.Group>
      </Col>
      <Col>
        <Button
          className="mt-4 btnDark px-5"
          onClick={() => getIntakes(dateStart, dateFinish)}
        >
          Load data
        </Button>
      </Col>
    </Row>
  );
};

export default ReportDateSelect;
