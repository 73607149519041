import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import useIntakes from "../../hooks/useIntakes";
import useBasicQuestions from "../../hooks/useBasicQuestions";

const ModalNewContact = (props) => {
  const {
    handleSubmitNewContact,
    clientSelected,
    handleEditContact,
    handleSubmitEditContact
  } = useIntakes();

  const { handleExtraQuestions } = useBasicQuestions();

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {clientSelected.id ? "Edit contact information" : "Add new Contact"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Row className="mb-4">
          <h5>Basic Info</h5>
          <Col>
            <TextField
              className="mt-3"
              required
              name="first_name"
              value={clientSelected.first_name || ""}
              onChange={handleEditContact}
              variant="outlined"
              size="small"
              label="First name"
              fullWidth
            />
            <FormControl fullWidth className="mt-3">
              <InputLabel id="imput-to">Gender</InputLabel>
              <Select
                labelId="imput-to"
                value={clientSelected.sex || ""}
                name="sex"
                label="Gender"
                onChange={handleEditContact}
                size="small"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col>
            <TextField
              className="mt-3"
              required
              name="last_name"
              variant="outlined"
              size="small"
              label="Last name"
              value={clientSelected.last_name || ""}
              onChange={handleEditContact}
              fullWidth
            />
            <TextField
              className="mt-3"
              label="A-Number"
              name="a_number"
              value={clientSelected.a_number || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="SSN"
              name="ssn"
              value={clientSelected.ssn || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 9 }}
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Citizen status"
              name="citizen_status"
              value={clientSelected.citizen_status || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <h5>Contact Info</h5>
          <Col>
            <TextField
              className="mt-3"
              required
              variant="outlined"
              name="primary_phone"
              label="Primary phone"
              value={clientSelected.primary_phone || ""}
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              variant="outlined"
              label="Email"
              name="email"
              value={clientSelected.email || ""}
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Home phone"
              name="home_phone"
              value={clientSelected.home_phone || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Secondary email"
              name="secondary_email"
              value={clientSelected.secondary_email || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Work phone"
              name="work_phone"
              value={clientSelected.work_phone || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Contact preference"
              name="contact_preference"
              value={clientSelected.contact_preference || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <h5>Address Info</h5>
          <Col>
            <TextField
              className="mt-3"
              label="Address"
              name="address"
              value={clientSelected.address || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="City"
              name="city"
              value={clientSelected.city || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Province"
              name="province"
              value={clientSelected.province || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Type Address"
              name="type_address"
              value={clientSelected.type_address || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="State (Ex: CA)"
              name="state"
              value={clientSelected.state || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Postal Code"
              name="postal_code"
              value={clientSelected.postal_code || ""}
              variant="outlined"
              onChange={handleEditContact}
              inputProps={{ maxLength: 9 }}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Number (If Select type)"
              name="type_number"
              value={clientSelected.type_number || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Zip Code"
              name="zip"
              value={clientSelected.zip || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              inputProps={{ maxLength: 5 }}
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Country (Ex: USA)"
              name="country_address"
              value={clientSelected.country_address || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <h5>Birth Info</h5>
          <Col>
            <TextField
              className="mt-3"
              label="Date of birth (mm/dd/yyyy)"
              name="date_of_birth"
              value={clientSelected.date_of_birth || ""}
              variant="outlined"
              onChange={handleEditContact}
              type="text"
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="City of birth"
              name="city_birth"
              value={clientSelected.city_birth || ""}
              variant="outlined"
              onChange={handleEditContact}
              type="text"
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Country of birth"
              name="country_birth"
              value={clientSelected.country_birth || ""}
              variant="outlined"
              onChange={handleEditContact}
              type="text"
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="State of birth"
              name="state_birth"
              value={clientSelected.state_birth || ""}
              variant="outlined"
              onChange={handleEditContact}
              type="text"
              size="small"
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <h5>Extra Info</h5>
          <Col>
            <TextField
              className="mt-3"
              label="Language"
              name="language"
              value={clientSelected.language || ""}
              variant="outlined"
              onChange={handleEditContact}
              size="small"
              fullWidth
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={props.onHide}
        >
          Close
        </Button>
        <Button
          className="btnDark px-5"
          onClick={() => {
            handleExtraQuestions();
            props.onHide(false);
          }}
        >
          Open Extra Question
        </Button>
        <Button
          className="btnDark px-5"
          onClick={() => {
            clientSelected.id
              ? handleSubmitEditContact()
              : handleSubmitNewContact();

            props.onHide(false);
          }}
          type="submit"
        >
          {clientSelected.id ? "Save edit" : "Save new contact"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNewContact;
