import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CalendarComp from "../components/calendar/CalendarComp";
import Loadign from "../components/Loadign";
import useEvents from "../hooks/useEvents";
import RenderCategoriesEvent from "../components/calendar/RenderCategoriesEvent";
import CheckPermissions from "../components/CheckPermissions";
import useStaff from "../hooks/useStaff";

const Calendar = () => {
  const { load } = useStaff();
  const { events, loadEventData, readyToRender } = useEvents();

  const [eventsByCat, setEventsByCat] = useState([]);

  const handleFilterCategory = (id) => {
    const data = events.filter((data) => data.category === id);
    return setEventsByCat(data);
  };

  useEffect(() => {
    if (events.length > 0) return;
    if (load) {
      loadEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckPermissions page={"calendar"}>
      <Row>
        <RenderCategoriesEvent
          handleFilterCategory={handleFilterCategory}
          setEventsByCat={setEventsByCat}
        />
        {readyToRender !== false ? (
          <Col className="p-5 pb-0">
            {eventsByCat.length > 0 ? (
              <CalendarComp events={eventsByCat} />
            ) : (
              <CalendarComp events={events} />
            )}
          </Col>
        ) : (
          <Loadign />
        )}
      </Row>
    </CheckPermissions>
  );
};

export default Calendar;
