import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import useStaff from "../../hooks/useStaff";

const ModalEditUser = ({ openModalUser, setOpenModalUser }) => {
  const { userSelected, handleEditUser, handleSubmitEditUser } = useStaff();

  return (
    <Modal
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalUser}
    >
      <Modal.Header closeButton onHide={() => setOpenModalUser(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
          {userSelected.id ? "Edit user information" : "Add new Contact"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Row>
          <Col>
            <TextField
              className="mt-3"
              required
              name="username"
              value={userSelected.username || ""}
              onChange={handleEditUser}
              variant="outlined"
              size="small"
              label="Username"
              fullWidth
            />
            <TextField
              className="mt-3"
              required
              variant="outlined"
              name="phone"
              label="Phone number"
              value={userSelected.phone || ""}
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Job title"
              name="job_title"
              value={userSelected.job_title || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Group"
              name="group"
              value={userSelected.group || ""}
              variant="outlined"
              onChange={handleEditUser}
              disabled
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              required
              name="full_name"
              variant="outlined"
              size="small"
              label="Full name"
              value={userSelected.full_name || ""}
              onChange={handleEditUser}
              fullWidth
            />
            <TextField
              className="mt-3"
              variant="outlined"
              label="Email"
              name="email"
              value={userSelected.email || ""}
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Role"
              name="rol"
              value={userSelected.rol || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
            <TextField
              className="mt-3"
              label="Background Color"
              name="background_color"
              value={userSelected.background_color || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
          </Col>
          <Col>
            <TextField
              className="mt-3"
              label="Abbreviation name"
              name="abbreviation_name"
              value={userSelected.abbreviation_name || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="mt-3"
              label="Job title"
              name="job_title"
              value={userSelected.job_title || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Status account
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userSelected.status_account || "invalid"}
                label="Status account"
                name="status_account"
                onChange={handleEditUser}
                size="small"
                fullWidth
              >
                <MenuItem value="valid">Activated</MenuItem>
                <MenuItem value="invalid">Deactivated</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className="mt-3"
              label="Text Color"
              name="text_color"
              value={userSelected.text_color || ""}
              variant="outlined"
              onChange={handleEditUser}
              size="small"
              fullWidth
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={() => setOpenModalUser(false)}
        >
          Close
        </Button>
        <Button
          variant="dark"
          className="bgDark px-5"
          style={{ borderRadius: "15px" }}
          onClick={() => {
            handleSubmitEditUser();
            setOpenModalUser(false);
          }}
          type="submit"
        >
          {userSelected.id ? "Edit user" : "Save new contact"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditUser;
