import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import TableContact from "../contacts/TableContact";

const IntakeForm = () => {
  const {
    typeOfContact,
    typeOfCase,
    contactStatus,
    source,
    clientSelected,
    handleAddtoIntake,
    handleSubmitNewIntake
  } = useIntakes();

  const { userInfo } = useStaff();
  const navigate = useNavigate();

  return (
    <>
      <Form className=" px-5 ">
        <Row className="mt-4">
          <Col>
            <FormLabel>Type of contact</FormLabel>
            <Form.Select
              name="type_of_contact"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {typeOfContact?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.attributes.type_of_contact_name}
                </option>
              ))}
            </Form.Select>
            <br />
            <FormLabel>Source</FormLabel>
            <Form.Select
              name="source"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {source?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.attributes.source}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <FormLabel>Type of case</FormLabel>
            <Form.Select
              name="type_of_case"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {typeOfCase?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.attributes.type_of_case_name}
                </option>
              ))}
            </Form.Select>
            <br />
            <FormLabel>Assignee</FormLabel>
            <Form.Select
              name="assignee"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {userInfo?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.full_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <FormLabel>Status</FormLabel>
            <Form.Select
              name="status"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {contactStatus?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.attributes.status}
                </option>
              ))}
            </Form.Select>
            <br />
            <FormLabel>Owner</FormLabel>
            <Form.Select
              name="owner"
              onChange={(e) => {
                handleAddtoIntake(e);
              }}
            >
              <option> Select an option </option>
              {userInfo?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.full_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <FormLabel>
              <h6 className="m-0">Selected contact:</h6>
            </FormLabel>
            <p className="m-2">Name: {clientSelected.name} </p>
            <p className="m-2">Phone: {clientSelected.phone}</p>
            <p className="m-2">Email: {clientSelected.email}</p>
          </Col>
          <Col>
            <FormLabel>Require Call?</FormLabel>
            <div className="mb-3">
              <Form.Check
                inline
                label="Yes"
                type="radio"
                value={true}
                name="pending_call"
                onChange={(e) => handleAddtoIntake(e)}
              />
              <Form.Check
                inline
                label="No"
                value={false}
                type="radio"
                name="pending_call"
                onChange={(e) => handleAddtoIntake(e)}
              />
            </div>
            <div className="d-grid gap-2">
              <Button
                variant="dark"
                className="px-3 bgDark"
                style={{ borderRadius: "15px" }}
                onClick={() => {
                  handleSubmitNewIntake();
                }}
              >
                Make new intake
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <hr />
        <Row>
          <TableContact />
        </Row>
        <hr />
        <Row className="mb-5">
          <Col></Col>
          <Col>
            <Row>
              <Col className="d-flex align-items-center">
                <p className="fw-semibold m-0 p-0 ">Don't see your contact?</p>
              </Col>
              <Col>
                <Button
                  variant="dark"
                  className="px-5 bgDark"
                  style={{ borderRadius: "15px" }}
                  onClick={() => {
                    navigate("/contacts");
                  }}
                >
                  Add new Contact
                </Button>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

export default IntakeForm;
