import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@mui/material";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import SelectComponent from "../SelectComponent";

const IntakeInformation = () => {
  const { userInfo } = useStaff();
  const {
    intake,
    typeOfCase,
    contactStatus,
    handleEditIntake,
    handleSubmitEditIntake
  } = useIntakes();

  return (
    <div className="d-flex flex-column ">
      <Row>
        <Col>
          <SelectComponent
            label="Assigned to"
            handleFunction={handleEditIntake}
            mapOptions={userInfo}
            valueData={intake.assignee || ""}
            nameData={"assignee"}
          />
        </Col>
        <Col>
          <SelectComponent
            label="Owner"
            handleFunction={handleEditIntake}
            mapOptions={userInfo}
            valueData={intake.owner || ""}
            nameData={"owner"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl fullWidth className="mt-3">
            <InputLabel id="imput-to">Type of Case</InputLabel>
            <Select
              labelId="imput-to"
              value={intake.type_of_case || ""}
              name="type_of_case"
              label="Type of Case"
              onChange={handleEditIntake}
              size="small"
            >
              {typeOfCase?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.attributes.type_of_case_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-3">
            <InputLabel id="imput-to">Status</InputLabel>
            <Select
              labelId="imput-to"
              value={intake.status || ""}
              name="status"
              label="Status"
              onChange={handleEditIntake}
              size="small"
            >
              {contactStatus?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.attributes.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="d-flex gap-3">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Reschedule call (Call center option)
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="pending_call"
              onChange={handleEditIntake}
            >
              <FormControlLabel
                value={true}
                name="pending_call"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                name="pending_call"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Col>
      </Row>
      <div className="d-grid gap-2 mt-3">
        <Button
          className="px-3 btnDark"
          style={{ borderRadius: "15px" }}
          onClick={() => handleSubmitEditIntake()}
        >
          Save intake changes
        </Button>
      </div>
    </div>
  );
};

export default IntakeInformation;
