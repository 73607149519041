import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { typeOfClient } from "../../Extra/interfaces";

const ModalAddEditTypeOfContact = ({
  openModalTypeOfClient,
  setOpenModalTypeOfClient,
  selectedTypeOfClient,
  setSelectedTypeOfClient,
  handleEditStatus,
  handleSubmitTypeOfClient
}) => {
  const [disabled, setDisabled] = useState();

  const handleClose = () => {
    setOpenModalTypeOfClient(false);
    setSelectedTypeOfClient(typeOfClient);
  };

  const handleChange = (e) => {
    setSelectedTypeOfClient({
      ...selectedTypeOfClient,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setDisabled(false);
  }, []);

  return (
    <Modal
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalTypeOfClient}
    >
      <Modal.Header closeButton onHide={() => handleClose()}>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedTypeOfClient.id !== 0
            ? "Edit type of client"
            : "Add new type of client"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <TextField
          className="mt-3"
          required
          name="type_of_contact_name"
          value={selectedTypeOfClient?.type_of_contact_name || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          label="Type of client name"
          fullWidth
        />
        <TextField
          className="mt-3"
          required
          name="message"
          value={selectedTypeOfClient?.message || ""}
          onChange={handleChange}
          variant="outlined"
          multiline
          maxRows={8}
          minRows={4}
          size="small"
          label="Type of client message "
          fullWidth
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={() => handleClose()}
        >
          Close
        </Button>
        <Button
          variant="dark"
          className="bgDark px-5"
          style={{ borderRadius: "15px" }}
          disabled={disabled}
          onClick={() => {
            handleSubmitTypeOfClient();
            setDisabled(true);
          }}
        >
          {selectedTypeOfClient.id !== 0
            ? "Edit type of client"
            : "Save new type of client"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddEditTypeOfContact;
