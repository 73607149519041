import useStaff from "../hooks/useStaff";
import ErrorPermissions from "./ErrorPermissions";

const CheckPermissions = ({ page, children }) => {
  const { permissionsUser } = useStaff();
  const result = permissionsUser.includes(page);

  return result ? children : <ErrorPermissions />;
};

export default CheckPermissions;
