import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useIntakes from "../hooks/useIntakes";
import useStaff from "../hooks/useStaff";
import { GetQuery, PostQuery } from "../Extra/Querys";
import { blankEvent, blankNote, configToast } from "../Extra/interfaces";
import { loadStatusNotes } from "./eventsFunctions/loadStatusNotes";
import { loadCategoryEvents } from "./eventsFunctions/loadCategoryEvents";
import { loadCategoryNotes } from "./eventsFunctions/loadCategoryNotes";

const EventsContext = createContext();

const EventsProvider = ({ children }) => {
  const { staff, load } = useStaff();
  const { intake, handleGetIndividualIntake } = useIntakes();

  //initialStates
  const [eventStates, setEventStates] = useState();

  const [event, setEvent] = useState(blankEvent);

  const [note, setNote] = useState(blankNote);

  const [events, setEvents] = useState([]);
  const [readyToRender, setReadyToRender] = useState(false);
  const [modalShowCalendar, setModalShowCalendar] = useState(false);

  //Filters URL
  const filterbyGroup = `[filters][group][$eq]=${staff?.group}`;
  // console.log(staff.group);

  const selectColorCategory = (category) => {
    const { categoryEvent } = eventStates;
    const data = categoryEvent.filter((data) => data.id === category);
    return data[0].color;
  };

  const selectCategoryName = (category) => {
    const { categoryEvent } = eventStates;
    const data = categoryEvent.filter((data) => data.id === category);
    return data[0].title;
  };

  //str.replace(/[^A-Z0-9]+/ig, "_")

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  //Notes

  const handleNote = (e) => {
    setNote((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleNoteRefresh = () => setNote(blankNote);

  const handleSaveNote = async () => {
    const data = {
      data: {
        ...note,
        group: Number(staff.group),
        owner: Number(staff.id),
        intake: Number(intake.id)
      }
    };
    await PostQuery(`/notes`, "post", data);
    handleGetIndividualIntake(intake.id);
  };

  const handleSaveBiliableNote = async (type) => {
    if (type === "note") {
      const data = {
        data: {
          ...note,
          group: Number(staff.group),
          owner: Number(staff.id),
          time: note.time,
          intake: intake.id,
          billtype: note.billtype,
          biliable: true
        }
      };
      await PostQuery(`/notes`, "post", data);
      handleGetIndividualIntake(intake.id);
    }
  };

  const handleNoteToModal = (noteToEdit, noteId, intakeId) => {
    const { createdAt, updatedAt, publishedAt, ...newNote } =
      noteToEdit.attributes;
    setNote({ ...newNote, id: noteId, intake: intakeId });
  };

  const handleEditNote = () => {
    if (note.id) {
      handleSubmitEditNote(note);
    }
  };

  const handleDeleteNote = (noteToEdit, noteId, intakeNote) => {
    console.log("handleDeleteNote", noteToEdit.id);
    handleSubmitEditNote({ id: noteId, active: false, intake: intakeNote });
  };

  const handleSubmitEditNote = async (dataToEdit) => {
    const data = JSON.stringify({ data: { ...dataToEdit } });
    await PostQuery(`/notes/${dataToEdit.id}`, "PUT", data);
    notification("success", "Changes saved successfully");
    setNote(blankNote);
    setTimeout(() => {
      handleGetIndividualIntake(dataToEdit.intake);
    }, 2000);
  };

  //Events

  const handleEvent = (e) => {
    setEvent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleRefreshEvent = () => setEvent(blankEvent);

  const handleUpdateEvent = async () => {
    const data = { data: event };
    await PostQuery(`/events/${event.id}`, "put", data);
    notification("success", "Changes saved successfully");
  };

  const loadEvent = async (id) => {
    const getEvent = await GetQuery(`/events/${id}`);
    setEvent({ id: getEvent.data.id, ...getEvent.data.attributes });
  };

  const loadEventData = async () => {
    setEvents([]);
    setReadyToRender(false);
    await GetQuery(
      `/events?populate=*&${filterbyGroup}&pagination[pageSize]=1000`
    )
      .then((res) => {
        // console.log(res.data);
        res.data.map((event) => {
          const id = event.id;
          const title = event.attributes.title;
          const description = event.attributes.description
            ? event.attributes.description
            : "No description";

          const owner = event.attributes.owner
            ? event.attributes.owner
            : "Error owner";
          const assignee = event.attributes.assignee
            ? event.attributes.assignee
            : "Error Assignee";

          const time_start = event.attributes.time_start;
          const time_end = event.attributes.time_end
            ? event.attributes.time_end
            : null;
          const start = `${event.attributes.start}T${event.attributes.time_start}`;
          const startDate = event.attributes.start;
          const end = event.attributes.end ? event.attributes.end : null;
          const endDate = event.attributes.end ? event.attributes.end : null;
          const allDay = event.attributes.AllDay
            ? event.attributes.AllDay
            : false;
          const color = event.attributes.category
            ? selectColorCategory(event.attributes.category)
            : "#000";
          const categoryName = event.attributes.category
            ? selectCategoryName(event.attributes.category)
            : "no category";
          const category = event.attributes.category;
          const intakeId = event.attributes.intake.data.id
            ? event.attributes.intake.data.id
            : null;

          return setEvents((prev) => [
            ...prev,
            {
              id,
              title,
              description,
              owner,
              assignee,
              time_start,
              time_end,
              start,
              end,
              allDay,
              color,
              category,
              categoryName,
              startDate,
              endDate,
              intakeId
            }
          ]);
        });
      })
      .then((res) => setReadyToRender(true));
  };

  const loadDataCategory = async () => {
    const resCategoriesEvents = await loadCategoryEvents(staff);

    setEventStates((prevState) => ({
      ...prevState,
      categoryEvent: resCategoriesEvents
    }));
  };

  const loadDataCategoryNotes = async () => {
    const resCategoryNotes = await loadCategoryNotes();

    setEventStates((prevState) => ({
      ...prevState,
      categoryNotes: resCategoryNotes
    }));
  };

  const loadDataStatusNotes = async () => {
    const resStatusNotes = await loadStatusNotes();

    setEventStates((prevState) => ({
      ...prevState,
      statusNotes: resStatusNotes
    }));
  };

  const loadInitialDate = async () => {
    await loadDataStatusNotes();
    await loadDataCategory();
    await loadDataCategoryNotes();
  };

  useEffect(() => {
    if (load) {
      loadInitialDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  return (
    <EventsContext.Provider
      value={{
        handleEvent,
        event,
        handleUpdateEvent,
        loadEvent,
        handleNote,
        note,
        setNote,
        loadEventData,
        readyToRender,
        events,
        handleSaveNote,
        modalShowCalendar,
        setModalShowCalendar,
        handleNoteToModal,
        handleEditNote,
        handleDeleteNote,
        handleNoteRefresh,
        handleSaveBiliableNote,
        //New functions
        handleRefreshEvent,
        eventStates,
        selectColorCategory
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export { EventsProvider };
export default EventsContext;
