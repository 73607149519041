import { TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import useDocuments from "../../../hooks/useDocuments";

const CreateFolderModal = ({ show, onHide }) => {
  const { setFolderName, folderName, handlerCreateNewFolder } = useDocuments();

  const handleFolderName = (e) => {
    setFolderName(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide}  centered>
      <Modal.Header closeButton>
        <Modal.Title>Create new folder</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <TextField
          className="mt-3"
          required
          name="folder_name"
          value={folderName}
          onChange={handleFolderName}
          variant="outlined"
          size="small"
          label="Folder name"
          fullWidth
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btnMuted px-4" onClick={onHide}>
          Close
        </Button>
        <Button
          className="btnDark px-4"
          onClick={() => {
            handlerCreateNewFolder();
            onHide();
          }}
        >
          Create folder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFolderModal;
