import { Col, Row } from "react-bootstrap";

const MENU_LIST = [
  {
    name: "Firm Settings",
    secction: "firm-settings"
  },
  {
    name: "Manager Users",
    secction: "manager-users"
  },
  {
    name: "Manager Permissions",
    secction: "manager-permissions"
  },
  {
    name: "Phone Numbers",
    secction: "phone-numbers"
  },
  {
    name: "Case Types",
    secction: "case-types"
  },
  {
    name: "Client Types",
    secction: "client-types"
  },
  {
    name: "Statuses",
    secction: "statuses"
  },
  {
    name: "Microsoft Settings",
    secction: "microsoft-settings"
  }
];

const MenuSetup = ({ setSecction, setSecctionTitle }) => {
  return (
    <Row className="w-100 px-3">
      {MENU_LIST.map((item, index) => (
        <Col
          className="m-auto my-2 "
          style={{ cursor: "pointer" }}
          md={5}
          lg={4}
          sm={6}
          key={index}
        >
          <div
            className="text-white rounded py-4 m-2 rounded btnDark fw-bold text-center"
            onClick={() => {
              setSecction(item.secction);
              setSecctionTitle(item.name);
            }}
          >
            {item.name}
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default MenuSetup;
