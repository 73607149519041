import ProgressBar from "react-bootstrap/ProgressBar";
import { CurrentCall } from "./CurrentCall";
import Intake from "../pages/Intake";
import useTwilio from "../hooks/useTwilio";
import { useEffect } from "react";
import { Row } from "react-bootstrap";

export const OnCall = ({ callsList, setCallList }) => {
  const { onCall, talkingWith, time, setTime } = useTwilio();

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <>
      {time > 0 && (
        <Row className="my-3 mx-5">
          <h6>Calling to {talkingWith.nameContact}</h6>
          <ProgressBar variant="success" now={time} />
        </Row>
      )}
      <CurrentCall callsList={callsList} setCallList={setCallList} />
      {Object.keys(onCall).length !== 0 && <Intake />}
    </>
  );
};
