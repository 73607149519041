import { Col, Row } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useEvents from "../../hooks/useEvents";

const RenderCategoriesEvent = ({ handleFilterCategory, setEventsByCat }) => {
  const { eventStates } = useEvents();
  const { categoryEvent } = eventStates;

  return (
    <Row className="mt-5 px-5 d-flex">
      <Col className="d-flex flex-column">
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Categories of events
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="all"
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="All Categories"
              onChange={() => setEventsByCat([])}
            />
            {categoryEvent.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Radio
                    value={category.id}
                    onChange={() => handleFilterCategory(category.id)}
                    sx={{
                      color: category.color,
                      "&.Mui-checked": {
                        color: category.color
                      }
                    }}
                  />
                }
                label={category.title}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Col>
    </Row>
  );
};

export default RenderCategoriesEvent;
