import { BrowserRouter, Routes, Route } from "react-router-dom";

import useStaff from "./hooks/useStaff";

import { IntakesProvider } from "./context/IntakesProvider";
// import { TwilioProvider } from "./context/TwilioProvider";
import { EventsProvider } from "./context/EventsProvider";
import { DocumentsProvider } from "./context/DocumentsProvider";
import { BasicQuestionProvider } from "./context/BasicQuestionProvider";
import { DashboardProvider } from "./context/DashboardProvider";
import { SoftphoneProvider } from "./context/SoftphoneProvider";

import ProtectedRoutes from "./Extra/ProtectedRoutes";
import Layout from "./Layout/Layout";
import Agenda from "./pages/Agenda";
import Calendar from "./pages/Calendar";
import Contacts from "./pages/Contacts";
import Dashboard from "./pages/Dashboard";
import Dialer from "./pages/Dialer";
import Inbox from "./pages/Inbox";
import NewIntake from "./pages/NewIntake";
import Login from "./pages/Login";
import Intake from "./pages/Intake";
import Import from "./pages/Import";
import Setup from "./pages/Setup";
import Reports from "./pages/Reports";
import ContactSupport from "./pages/ContactSupport";
import ReportCalls from "./components/reports/ReportCalls";
import { ReportCallsByUser } from "./components/reports/ReportCallsByUser";
import ReportSms from "./components/reports/ReportSms";
import ReportCallsMade from "./components/reports/ReportCallsMade";
import ReportCallsPending from "./components/reports/ReportCallsPending";
import ReportIntakesByStatuses from "./components/reports/ReportIntakesByStatuses";
import ReportIntakeByTypeOfCase from "./components/reports/ReportIntakeByTypeOfCase";
import ReportIntakeBySource from "./components/reports/ReportIntakeBySource";
import ReportIntakesByAssignee from "./components/reports/ReportIntakesByAssignee";
import ImportNotes from "./pages/ImportNotes";
// import Softphone from "./components/softphone/Softphone";

function App() {
  const { logged } = useStaff();
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoutes isAllowed={logged} />}>
          <Route
            path="/"
            element={
              <IntakesProvider>
                <SoftphoneProvider>
                  {/* <TwilioProvider> */}
                    <EventsProvider>
                      <DocumentsProvider>
                        <BasicQuestionProvider>
                          <DashboardProvider>
                            <Layout />
                          </DashboardProvider>
                        </BasicQuestionProvider>
                      </DocumentsProvider>
                    </EventsProvider>
                  {/* </TwilioProvider> */}
                </SoftphoneProvider>
              </IntakesProvider>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route index path="inbox" element={<Inbox />} />
            <Route path="agenda" element={<Agenda />} />
            <Route path="new-intake" element={<NewIntake />} />
            {/* <Route path="leads" element={<Leads />} /> */}
            {/* <Route path="cases" element={<Cases />} /> */}
            <Route path="dialer" element={<Dialer />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="intake" element={<Intake />} />
            <Route path="import" element={<Import />} />
            <Route path="import-notes" element={<ImportNotes />} />
            <Route path="reports" element={<Reports />} />
            <Route path="reports/calls" element={<ReportCalls />} />
            <Route path="reports/callsbyuser" element={<ReportCallsByUser />} />
            <Route path="reports/sms" element={<ReportSms />} />
            <Route path="reports/calls-made" element={<ReportCallsMade />} />
            <Route
              path="reports/calls-pending"
              element={<ReportCallsPending />}
            />
            <Route
              path="reports/intake-by-status"
              element={<ReportIntakesByStatuses />}
            />
            <Route
              path="reports/intake-by-type-of-case"
              element={<ReportIntakeByTypeOfCase />}
            />
            <Route
              path="reports/intake-by-source"
              element={<ReportIntakeBySource />}
            />
            <Route
              path="reports/intake-by-assignee"
              element={<ReportIntakesByAssignee />}
            />
            <Route path="setup" element={<Setup />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
        {/* <Route path="/redirect" element={<Redirect />}></Route> */}
        <Route path="/contact-support" element={<ContactSupport />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
