import { Button, Col, Form, Row } from "react-bootstrap";

import { useEffect, useState } from "react";

import ChartsCallsPending from "./charts/ChartsCallsPending";
import ChartsCallsMade from "./charts/ChartsCallsMade";
import ChartsStatus from "./charts/ChartsStatus";
import ChartTypeOfCase from "./charts/ChatsTypeOfCase";
import ChartsSource from "./charts/ChartsSource";
import ChartsStatusbyAssignee from "./charts/ChartsStatusbyAssignee";
import useDashboard from "../hooks/useDashboard";
import Loadign from "./Loadign";

const DashboardAdmin = () => {
  const { loaded, getIntakes } = useDashboard();
  const [dateStart, setDateStart] = useState();
  const [dateFinish, setDateFinish] = useState();
  const [convertDateStart, setConvertDateStart] = useState();
  const [convertDateFinish, setConvertDateFinish] = useState();

  const handleSetDate = (e) => {
    if (e.target.name === "start") {
      setDateStart(new Date(e.target.value).toISOString());
      setConvertDateStart(e.target.value);
    } else {
      setDateFinish(new Date(e.target.value).toISOString());
      setConvertDateFinish(e.target.value);
    }
  };

  useEffect(() => {
    const date = new Date().toISOString();
    let previusDate = new Date(date);
    previusDate = previusDate.setMonth(previusDate.getMonth() - 1);
    setDateStart(new Date(previusDate).toISOString());
    setConvertDateStart(new Date(previusDate).toISOString().slice(0, 10));
    setDateFinish(date);
    setConvertDateFinish(date.slice(0, 10));
  }, []);

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label as="h6">Start date:</Form.Label>
            <Form.Control
              type="date"
              value={convertDateStart}
              name="start"
              onChange={handleSetDate}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label as="h6">Finish date:</Form.Label>
            <Form.Control
              type="date"
              value={convertDateFinish}
              name="end"
              onChange={handleSetDate}
            />
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="mt-4 btnDark px-5"
            onClick={() => getIntakes(dateStart, dateFinish)}
          >
            Load data
          </Button>
        </Col>
      </Row>
      {loaded ? (
        <>
          <Row className="p-5">
            <Col>
              <ChartsCallsPending />
            </Col>
            <Col>
              <ChartsCallsMade />
            </Col>
          </Row>
          <hr />
          <Row className="p-5">
            <ChartsStatus />
          </Row>
          <hr />
          <Row className="p-5">
            <Col>
              <ChartTypeOfCase />
            </Col>
            <Col>
              <ChartsSource />
            </Col>
          </Row>
          <hr />
          <Row className="p-5">
            <ChartsStatusbyAssignee />
          </Row>
        </>
      ) : (
        <Loadign />
      )}
    </>
  );
};

export default DashboardAdmin;
