import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import useIntakes from "../hooks/useIntakes";
import useTwilio from "../hooks/useTwilio";
import useStaff from "../hooks/useStaff";
import { capitalize } from "../Extra/capitalize";

export const CurrentCall = ({
  callsList,
  setCallList,
  setOutgoingCall = () => console.log("setOutgoingCall"),
  outgointCall = false,
  transferCall
}) => {
  const { setCallActive } = useStaff();
  const {
    actionCall,
    onCall,
    talkingWith,
    setTalkingWith,
    setdisableButtonCall,
    handleStatusChange,
    setOnCall,
    callStatus,
    callSid
  } = useTwilio();
  const { intake } = useIntakes();

  const handleEndCall = () => {
    setTalkingWith({});
    setOnCall([]);
    setdisableButtonCall(false);
    setCallActive(false);

    if (callStatus === "in-progress") {
      console.log("para in progress");
      actionCall(callSid, "end");
      updateCallList(talkingWith.intakeId);
      handleStatusChange(intake.id);
    } else {
      console.log("le dio en completed");
      actionCall(callSid, "completed");
    }

    if (callStatus === "completed") {
      handleStatusChange(intake.id);
    }

    if (outgointCall) {
      setOutgoingCall(false);
    }
  };

  const updateCallList = (id) => {
    if (callsList) {
      const updatedList = callsList.filter((call) => call.id !== id);
      setCallList(updatedList);
    }
  };

  useEffect(() => {
    if (Object.keys(onCall).length !== 0) {
      setCallActive(true);
    }
  }, [onCall, setCallActive]);

  return (
    <Row className="my-4 mx-0">
      <h6>Talking with</h6>
      {outgointCall && <hr className="mx-3 mt-3" />}
      <Row className="m-0 p-0">
        <Col>
          <h5>{talkingWith.nameContact}</h5>
          <p className="m-0 fw-bold">{capitalize(callStatus)}</p>
        </Col>
        <Col>
          <h6>{talkingWith.phoneContact}</h6>
          <p className="m-0">{talkingWith.emailContact}</p>
        </Col>
        <Col>
          <div className="d-grid">
            <Button className="btn btn-danger" onClick={handleEndCall}>
              End Call
            </Button>
            <Button className="btn btnDark mt-3" onClick={transferCall}>
              Transfer Call
            </Button>
          </div>
        </Col>
      </Row>
    </Row>
  );
};
