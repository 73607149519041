import { Button, Col, Row } from "react-bootstrap";
import { BsTelephoneForwardFill } from "react-icons/bs";
// import useTwilio from "../hooks/useTwilio";
import useSoftphone from "../hooks/useSoftphone";
import useIntakes from "../hooks/useIntakes";
// import useStaff from "../hooks/useStaff";
import { useNavigate } from "react-router-dom";

const CallList = ({ call }) => {
  const navigate = useNavigate();
  // const { setCallActive } = useStaff();

  // const {
  //   // startCall,
  //   // setTalkingWith,
  //   // setTime,
  //   // setdisableButtonCall,
  //   // audioContextActive
  // } = useTwilio();
  const {
    callJsSIP,
    // answerJsSIP,
    // register,
    // activo,
    // inCall,
    // setInCall
  } = useSoftphone();
  const { handleGetIndividualIntake } = useIntakes();
  const { first_name, last_name } = call.contact.data.attributes;
  // const { primary_phone, email } = call.contact.data.attributes;
  const { primary_phone } = call.contact.data.attributes;

  const handleStartCall = () => {
    // startCall(primary_phone);
    callJsSIP(primary_phone);
    // setTime(100);
    // setTalkingWith({
    //   intakeId: call.id,
    //   phoneContact: primary_phone,
    //   nameContact: `${first_name} ${last_name}`,
    //   emailContact: email
    // });
    // setdisableButtonCall(true);
    // handleGetIndividualIntake(call.id);
    // setCallActive(true);
  };

  return (
    <>
      <Row className="my-2">
        <Col md={6} className="border-end">
          <Button
            className="btnBlank"
            onClick={async () => {
              const res = await handleGetIndividualIntake(call.id);
              if (res) {
                navigate(`/intake`);
              }
            }}
          >
            {`${first_name} ${last_name}`}
          </Button>
        </Col>
        <Col md={4}>
          <p>
            <small>{primary_phone}</small>
          </p>
        </Col>
        <Col md={2} className="m-0 p-0">
          <Button
            style={{ borderRadius: "15px" }}
            className="px-3 d-flex gap-2 justify-content-center align-items-center w-100"
            size={"sm"}
            variant="outline-success"
            onClick={handleStartCall}
            // disabled={!audioContextActive}
          >
            <BsTelephoneForwardFill />
            Call
          </Button>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default CallList;
