/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BsCalendar4Event,
  BsFileEarmarkRuled,
  BsFolder2Open,
  BsJournalText
} from "react-icons/bs";

const BottomMenu = ({ handleStep, setHandleStep }) => {
  const MENU_LIST = [
    {
      name: "Calls, SMS and Events",
      icon: <BsCalendar4Event />,
      step: 1
    },
    {
      name: "Documents Automations",
      icon: <BsFileEarmarkRuled />,
      step: 2
    },
    {
      name: "Notes",
      icon: <BsJournalText />,
      step: 3
    },
    {
      name: "Documents",
      icon: <BsFolder2Open />,
      step: 4
    }
  ];

  return (
    <div className="d-flex justify-content-around bgDark p-2 rounded align-items-center mb-3">
      {MENU_LIST.map((item, index) => (
        <a
          key={index}
          className={`text-light my-2 btn d-flex align-items-center gap-2 ${
            handleStep === item.step && "fw-bold border-bottom border-3"
          }`}
          onClick={() => {
            setHandleStep(item.step);
          }}
        >
          {item.icon}
          {item.name}
        </a>
      ))}
    </div>
  );
};

export default BottomMenu;
