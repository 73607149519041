import { Button, Modal } from "react-bootstrap";
import useDocuments from "../../../hooks/useDocuments";

const UploadDocumentModal = ({ show, onHide }) => {
  const { setFiles, hanldeMGUploadDoc } = useDocuments();

  return (
    <Modal show={show} onHide={onHide} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <input
          id="outlined-basic"
          className="mt-3"
          variant="outlined"
          type="file"
          size="small"
          onChange={(e) => setFiles(e.target.files)}
          required
        />
        <p
          className=" fw-light"
          style={{ fontSize: "14px", marginTop: "10px" }}
        >
          <small>Select a new document to add in this inkate</small>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btnMuted px-4" onClick={onHide}>
          Close
        </Button>
        <Button
          className="btnDark px-4"
          onClick={() => {
            hanldeMGUploadDoc();
            onHide();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocumentModal;
