import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useDashboard from "../../hooks/useDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
};

const ChartTypeOfCase = () => {
  const { intakeForDash, loaded } = useDashboard();
  const [values, setValues] = useState([]);
  const [loadComponent, setLoadComponent] = useState(false);

  const loadValues = () => {
    setLoadComponent(false);

    const statusinIntake = intakeForDash.reduce(
      (acc, { attributes: { type_of_case } }) => {
        if (
          type_of_case &&
          type_of_case.data &&
          type_of_case.data.attributes &&
          type_of_case.data.attributes.type_of_case_name
        ) {
          const type_of_case_name =
            type_of_case.data.attributes.type_of_case_name;
          acc[type_of_case_name] = (acc[type_of_case_name] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    setValues(statusinIntake);
    setLoadComponent(true);
  };

  useEffect(() => {
    if (loaded) {
      loadValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "Intakes by type of case",
          data: values,
          backgroundColor: "#183440",
        },
      ],
      labels: Object.keys(values),
    };
  });

  return <>{loadComponent && <Bar data={data} options={options} />}</>;
};

export default ChartTypeOfCase;
