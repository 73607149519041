import { TextField } from "@mui/material";

const BasicQuestionForm = ({
  formLabel,
  formName,
  formValue,
  formFunction,
  formType,
  maxLength
}) => {
  return (
    <>
      {formType === "date" && (
        <TextField
          label={formLabel}
          name={formName}
          value={formValue}
          type={formType}
          onChange={formFunction}
          size="small"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
      {formType === "text" && (
        <TextField
          label={formLabel}
          name={formName}
          value={formValue}
          type={formType}
          onChange={formFunction}
          inputProps={maxLength ? { maxLength } : {}}
          size="small"
          fullWidth
        />
      )}
      {formType === "textArea" && (
        <TextField
          label={formLabel}
          name={formName}
          value={formValue}
          type={formType}
          rows={3}
          multiline
          onChange={formFunction}
          inputProps={maxLength ? { maxLength } : {}}
          size="small"
          fullWidth
        />
      )}
    </>
  );
};

export default BasicQuestionForm;
