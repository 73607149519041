export const contactInformationFields = [
    { name: "first_name", label: "First  name" },
    {
      name: "last_name",
      label: "Last name"
    },
    {
      name: "email",
      label: "Email"
    },
    {
      name: "primary_phone",
      label: "Primary phone"
    },
    {
      name: "address",
      label: "Address"
    },
    {
      name: "ssn",
      label: "SSN"
    }
  ];