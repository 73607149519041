import { Col } from "react-bootstrap";

const QuestionTitle = ({ question }) => {
  return (
    <Col  md={12} className="mb-3">
      <h5>{question.name}</h5>
    </Col>
  );
};

export default QuestionTitle;
