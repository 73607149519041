import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { CgOptions } from "react-icons/cg";
import useStaff from "../../hooks/useStaff";
import useIntakes from "../../hooks/useIntakes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEvents from "../../hooks/useEvents";
import { blankEvent } from "../../Extra/interfaces";
import { TextField } from "@mui/material";

const ModalShowEvent = ({ onHide, dataEvent, show }) => {
  const navigate = useNavigate();

  const { userInfo } = useStaff();
  const { handleGetIndividualIntake, clientSelected } = useIntakes();
  const { handleEvent, event, setEvent, handleUpdateEvent } = useEvents();
  const handleSaveEvent = () => {
    alert("Please make event into one intake");
  };

  const { title, backgroundColor } = dataEvent;
  const { categoryName, intakeId } = dataEvent.extendedProps;

  useEffect(() => {
    if (show) {
      handleGetIndividualIntake(intakeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const filterName = (id) => {
    if (show) {
      // console.log("id", id);
      // console.log("userInfo", userInfo);
      const data = userInfo?.filter((data) => data.id === id);
      // console.log("data", data);
      if (data.length === 0) return "No assignee";
      return data[0].full_name;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setEvent(blankEvent);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          backgroundColor: backgroundColor,
          color: "white"
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="px-3">
          <Form>
            <Row>
              <TextField
                label="Title"
                size="small"
                name="title"
                value={event.title}
                onChange={(e) => handleEvent(e)}
              />
            </Row>

            <Row className="mt-3">
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Start at</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control
                        type="date"
                        value={event.start}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        step="0.001"
                        name="time_start"
                        value={event.time_start}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Ends at</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control
                        type="date"
                        value={event.end}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        step="0.001"
                        name="time_end"
                        value={event.time_end}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Label as="h6">Event Category</Form.Label>
                <p
                  className="px-3 rounded mt-3 fw-bold"
                  style={{ color: backgroundColor }}
                >
                  {categoryName}
                </p>
              </Col>
              <Col md={3}>
                <Form.Label as="h6">Client phone number</Form.Label>
                <p className="px-3 rounded mt-3 ">
                  {clientSelected.primary_phone}
                </p>
              </Col>

              <Col>
                <Form.Label as="h6">Client name</Form.Label>
                <Form.Control
                  disabled
                  value={`${clientSelected?.first_name} ${clientSelected?.last_name} `}
                />
              </Col>
            </Row>
            <Row className="px-2">
              <Form.Label as="h6">Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={event.description}
                onChange={(e) => handleEvent(e)}
              />
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label as="h6">Assignee</Form.Label>
                <Form.Select name="assignee" onChange={(e) => handleEvent(e)}>
                  <option>
                    {event.assignee !== ""
                      ? filterName(event.assignee)
                      : "No assignee"}
                  </option>
                  {userInfo?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.full_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <Form.Label as="h6">Owner</Form.Label>
                <Form.Control
                  value={
                    event.owner !== "" ? filterName(event.owner) : "No owner"
                  }
                  disabled
                />
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Container className="d-flex justify-content-between">
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              event.id !== "" ? handleUpdateEvent() : handleSaveEvent();
              onHide();
            }}
          >
            {event.id !== "" ? "Save changes" : "Save"}
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={onHide}
          >
            Delete
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              navigate("/intake");
              setEvent(blankEvent);
            }}
          >
            <CgOptions /> Go to intake
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            onClick={() => {
              onHide();
            }}
            style={{ borderRadius: "15px" }}
          >
            Exit without changes
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalShowEvent;
