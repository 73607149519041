import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Col } from "react-bootstrap";

const QuestionSelect = ({ question, answer, size, handler }) => {
  return (
    <Col md={size} className="mb-3">
      <FormControl sx={{ width: "100%" }} size="small" className="mt-3 mt-lg-0">
        <InputLabel id="imput-to">{question.name}</InputLabel>
        <Select
          labelId="imput-to"
          label={question.name}
          defaultValue=""
          value={`${
            answer[question.value] !== undefined
              ? answer[question.value]
              : ""
          }`}
          name={question.value}
          onChange={(e) => handler(e)}
          size="small"
        >
          {question.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Col>
  );
};

export default QuestionSelect;
