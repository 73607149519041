import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CheckPermissions from "../components/CheckPermissions";
import { GetQuery } from "../Extra/Querys";
import { callListQuery } from "../Extra/UrlQuery";
import useStaff from "../hooks/useStaff";
import TwilioApp from "../twilio/TwilioApp";
import Loadign from "../components/Loadign";
import { OnCall } from "../twilio/OnCall";
import { fillDataArray } from "../Extra/fillDataArray";

const Dialer = () => {
  const { callActive, menuOpen } = useStaff();
  const [colOne, setColOne] = useState();
  const [colTwo, setColTwo] = useState();

  const [pendingCallsQuantity, setPendingCallsQuantity] = useState();
  const [callsList, setCallList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [statusSelect, setStatusSelect] = useState();

  const loadData = async () => {
    const staffResult = JSON.parse(localStorage.getItem("staff"));
    if (staffResult === null || staffResult === undefined) return;
    setLoaded(false);
    const filterByStatus = `&[filters][status][id][$eq]=${statusSelect}`;
    const filterByStaff = `&[filters][assignee][$eq]=${staffResult.id}`;
    const getCalls = await GetQuery(
      `${callListQuery}${statusSelect ? filterByStatus : ""}${filterByStaff}`
    );
    setCallList(fillDataArray(getCalls.data));
    console.log("Call list", fillDataArray(getCalls.data));
    setPendingCallsQuantity(getCalls.meta.pagination.total);
    setLoaded(true);
  };

  console.log("callActive", callActive);

  useEffect(() => {
    loadData();
    if (!callActive) {
      setColOne(8);
      setColTwo(4);
    } else {
      setColOne(0);
      setColTwo(12);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callActive, statusSelect]);

  return (
    <CheckPermissions page={"dialer"}>
      {callActive === true ? (
        <OnCall callsList={callsList} setCallList={setCallList} />
      ) : (
        <>
          {loaded ? (
            <Row className={`${!menuOpen ? "px-5" : ""}`}>
              {!callActive ? (
                <Col
                  lg={colOne}
                  xl={colOne - 2}
                  xxl={colOne - 3}
                  className={`pt-4 ${!menuOpen ? "px-5" : ""}`}
                  style={{ height: "100vh" }}
                >
                  <TwilioApp
                    callsList={callsList}
                    pendingCallsQuantity={pendingCallsQuantity}
                    statusSelect={statusSelect}
                    setStatusSelect={setStatusSelect}
                  />
                </Col>
              ) : null}

              <Col
                lg={colTwo}
                xl={colTwo + 2}
                xxl={colTwo + 3}
                className="px-5"
              ></Col>
            </Row>
          ) : (
            <Loadign />
          )}
        </>
      )}
    </CheckPermissions>
  );
};

export default Dialer;
