import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsFillRecordFill } from "react-icons/bs";

const CardUserStatus = ({ user }) => {
  return (
    <Row>
      <Col className="d-flex justify-content-between align-items-center gap-2">
        {user.full_name} <BsFillRecordFill className={`${user.status}`} />
      </Col>
    </Row>
  );
};

export default CardUserStatus;
