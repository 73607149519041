import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { configToast } from "../Extra/interfaces";
import { GetMGData } from "../Extra/QueryMG";
import { GetQuery } from "../Extra/Querys";
import useIntakes from "../hooks/useIntakes";
import useStaff from "../hooks/useStaff";

const DocumentsContext = createContext();

const DocumentsProvider = ({ children }) => {
  const graphEndpoint = process.env.REACT_APP_GRAPH_ENDPOINT;
  const rootFolderID = process.env.REACT_APP_FOLDER_ID;
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const { staff } = useStaff();
  const { intake, clientSelected } = useIntakes();

  const [files, setFiles] = useState();
  const [filesByIntake, setFilesByIntake] = useState([]);
  const [filesInsideIntake, setFilesInsideIntake] = useState([]);
  const [loadignDocs, setLoadignDocs] = useState(false);
  const [folderPath, setFolderPath] = useState("");
  const [folderName, setFolderName] = useState("");
  const [idFolder, setIdFolder] = useState();
  const customPath =
    `${clientSelected.first_name}_${clientSelected.last_name}_${intake.id}`.replace(
      /[^A-Z0-9]+/gi,
      "_"
    );

  const notificationToast = (type, msg) => {
    if (type === "success") return toast.success(msg, configToast);

    if (type === "error") return toast.error(msg, configToast);
  };

  const getItemsbyIntake = async () => {
    setLoadignDocs(true);
    const mgToken = await getMGTokens();

    const url = `${graphEndpoint}/v1.0/drive/items/${rootFolderID}/children`;
    const resultMasterData = await GetMGData(url, mgToken);

    const folderId = resultMasterData.value.find(
      (folder) => folder.name === customPath
    );

    if (folderId) {
      const urlID = `${graphEndpoint}/v1.0/drive/items/${folderId.id}/children`;
      const { value } = await GetMGData(urlID, mgToken);
      setFilesByIntake(value);
      setLoadignDocs(false);
    }
  };

  const getFoldersInsideIntake = async (id) => {
    setLoadignDocs(true);
    const mgToken = await getMGTokens();

    const urlID = `${graphEndpoint}/v1.0/drive/items/${id}/children`;
    const { value } = await GetMGData(urlID, mgToken);
    setFilesInsideIntake(value);
    setLoadignDocs(false);
  };

  const getMGTokens = async () => {
    const mgToken = await GetQuery(`/microsoft-tokens?sort=createdAt:DESC`);

    return mgToken.data[0].attributes.token;
  };

  const hanldeMGUploadDoc = async () => {
    const mgToken = await getMGTokens();
    console.log(mgToken);
    const filename = files[0].name;
    console.log(filename);
    const fileToUplode = files[0];

    const filereader = new FileReader();
    filereader.onload = async (event) => {
      await uploadDocument(filename, event.target.result, mgToken);
    };
    filereader.readAsArrayBuffer(fileToUplode);
  };

  async function uploadDocument(filename, fileToUpload, token) {
    const siteURL = process.env.REACT_APP_SITE_URL;
    let optionalUrl;

    if (folderPath !== "") {
      const folderEncode = encodeURIComponent(folderPath);
      optionalUrl = `${graphEndpoint}/v1.0/sites/${siteURL}/drive/root:/Case_Docs/${customPath}/${folderEncode}/${filename}:/content`;
    } else {
      optionalUrl = `${graphEndpoint}/v1.0/sites/${siteURL}/drive/root:/Case_Docs/${customPath}/${filename}:/content`;
    }

    try {
      await axios.put(optionalUrl, fileToUpload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json"
        }
      });

      notificationToast("success", "Document uploaded successfully");
      getItemsbyIntake();
    } catch (error) {
      console.log(error);
      notificationToast("error", "Error uploading document");
    }
  }

  //"Provided identifier is malformed - site id is not valid"

  const handlerCreateNewFolder = async () => {
    const mgToken = await getMGTokens();
    if (folderName === "") return notificationToast("error", "Incorrect name");
    await createNewFolder(folderName, mgToken);
  };

  async function createNewFolder(name, token) {
    const url = `${graphEndpoint}/v1.0/drive/items/${rootFolderID}/children`;
    const resultMasterData = await GetMGData(url, token);

    const folderId = resultMasterData.value.find(
      (folder) => folder.name === customPath
    );

    if (folderId) {
      let endpoint;
      if (idFolder) {
        endpoint = `${graphEndpoint}/v1.0/drive/items/${idFolder}/children`;
      } else {
        endpoint = `${graphEndpoint}/v1.0/drive/items/${folderId.id}/children`;
      }
      const data = {
        name,
        folder: {},
        "@microsoft.graph.conflictBehavior": "rename"
      };

      await axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          }
        })
        .then((res) => {
          notificationToast("success", "Carpeta creada exitosamente");
          setFolderName("");
          setIdFolder("");
        })
        .catch((err) => {
          setFolderName("");
          notificationToast("error", "Error al crear carpeta");
        });
    }
  }

  const documentAutomation = async (id, pdf, name, document_name) => {
    if (intake.id === undefined || intake.id === null)
      return notificationToast("error", "Need select intake");
    const url = `${backendURL}/automation/${id}?document=${pdf}&assignee=${staff.id}&intake=${intake.id}`;
    await axios({
      url,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      // crea un objeto URL a partir de la respuesta blob
      const fileUrl = URL.createObjectURL(response.data);
      // crea un enlace <a> con el objeto URL y haz clic en él para descargar el archivo
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${name}_${document_name}.pdf`;
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    setFilesByIntake([]);
  }, [intake]);

  return (
    <DocumentsContext.Provider
      value={{
        setFiles,
        hanldeMGUploadDoc,
        filesByIntake,
        getItemsbyIntake,
        filesInsideIntake,
        setFilesInsideIntake,
        getFoldersInsideIntake,
        loadignDocs,
        handlerCreateNewFolder,
        folderName,
        setFolderName,
        setFolderPath,
        setIdFolder,
        documentAutomation
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export { DocumentsProvider };
export default DocumentsContext;
