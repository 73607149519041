import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import useBasicQuestions from "../../hooks/useBasicQuestions";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useIntakes from "../../hooks/useIntakes";
import ExtraQuestionValidate from "./ExtraQuestionValidate";

const ExtraQuestions = ({ reOpenModalConmtact }) => {
  const { clientSelected, handleSubmitEditContact } = useIntakes();
  const {
    showExtraQuestions,
    handleExtraQuestions,
    extraQuestionsForm,
    extraQuestionsList,
    handleSelectEQForm
  } = useBasicQuestions();

  const { structure } = extraQuestionsForm?.attributes;
  const { extra_answer } = clientSelected;


  return (
    <Modal
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleExtraQuestions}
      show={showExtraQuestions}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="col-6">
          Extra Questions
        </Modal.Title>
        <FormControl sx={{ width: "100%" }} size="small" className="mx-3">
          <InputLabel id="imput-to">Change form type</InputLabel>
          <Select
            labelId="imput-to"
            label="Change form type"
            onChange={handleSelectEQForm}
            size="small"
          >
            {extraQuestionsList.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.attributes.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Row>
          {structure && (
            <ExtraQuestionValidate
              structure={structure}
              extra_answer={extra_answer}
            />
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={() => {
            handleExtraQuestions();
            reOpenModalConmtact();
          }}
        >
          Close
        </Button>
        <Button
          className="btnDark px-5"
          style={{ borderRadius: "15px" }}
          onClick={() => handleSubmitEditContact()}
          type="submit"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtraQuestions;
