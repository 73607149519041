import { DataGrid } from "@mui/x-data-grid";
import { Button, Row } from "react-bootstrap";
import useStaff from "../../hooks/useStaff";
import { BsPencilSquare } from "react-icons/bs";
import ModalEditUser from "./ModalEditUser";
import { useEffect } from "react";

const ManagerUsers = () => {
  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];

  const {
    handleSetUserSelected,
    openModalUser,
    setOpenModalUser,
    getUserAdmin,
    fullUsersInfo
  } = useStaff();

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "full_name", headerName: "Name", width: 130 },
    {
      headerName: "Email",
      field: "email",
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Phone",
      field: "phone",
      minWidth: 80,
      maxWidth: 160
    },
    {
      headerName: "Role",
      field: "rol",
      minWidth: 90,
      maxWidth: 95
    },
    {
      headerName: "Status",
      field: "activation",
      renderCell: (cellValues) => {
        return (
          <p
            className="m-auto fw-bold"
            style={{
              color: cellValues.row.status_account === "valid" ? "green" : "red"
            }}
          >
            {cellValues.row.status_account === "valid" ? "Active" : "Inactive"}
          </p>
        );
      },
      minWidth: 90,
      maxWidth: 95
    },
    {
      headerName: "Edit",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {
              handleSetUserSelected(cellValues.row.id);
              setOpenModalUser(true);
            }}
          >
            <BsPencilSquare /> Edit
          </Button>
        );
      },
      width: 80,
      flex: 1
    }
  ];

  useEffect(() => {
    getUserAdmin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className="mt-5 px-5">
      <DataGrid
        autoHeight
        rows={fullUsersInfo}
        columns={columns}
        sortModel={sortModel}
      />
      <ModalEditUser
        openModalUser={openModalUser}
        setOpenModalUser={setOpenModalUser}
      />
    </Row>
  );
};

export default ManagerUsers;
