import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { configToast, typeOfClient } from "../../Extra/interfaces";
import { PostQuery } from "../../Extra/Querys";
import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import ModalAddEditTypeOfContact from "./ModalAddEditTypeOfContact";

const ClientTypes = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { staff } = useStaff();
  const { typeOfContact } = useIntakes();
  const [openModalTypeOfClient, setOpenModalTypeOfClient] = useState();
  const [selectedTypeOfClient, setSelectedTypeOfClient] =
    useState(typeOfClient);

  const handleSetTypeOfClient = (id) => {
    const type = typeOfContact.filter((type) => type.id === id);
    const typeId = type[0].id;
    const { type_of_contact_name, message } = type[0].attributes;
    setSelectedTypeOfClient({ id: typeId, type_of_contact_name, message });
    setOpenModalTypeOfClient(true);
  };

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  const closeAndNotification = (status) => {
    if (status === 200) {
      notification("success", "Saved successfully");
      setOpenModalTypeOfClient(false);
    } else {
      notification("error", "Ups... something went wrong");
    }
  };

  const handleSubmitTypeOfClient = async () => {
    const url = `${backendURL}/type-of-contacts`;
    if (selectedTypeOfClient.id === 0) {
      //nuevo
      const { type_of_contact_name, message } = selectedTypeOfClient;
      const dataToSend = {
        data: { type_of_contact_name, message, group: staff.group }
      };
      const res = await PostQuery(url, "POST", dataToSend);
      closeAndNotification(res.status);
    } else {
      //editado
      const dataToSend = { data: { ...selectedTypeOfClient } };
      const res = await PostQuery(
        `${url}/${selectedTypeOfClient.id}`,
        "PUT",
        dataToSend
      );

      closeAndNotification(res.status);
    }
  };

  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      headerName: "Type of contact",
      field: "type_of_contact_name",
      valueGetter: (parame) => {
        return parame.row.attributes.type_of_contact_name;
      },
      minWidth: 120,
      maxWidth: 160
    },
    {
      headerName: "Message",
      field: "message",
      valueGetter: (parame) => {
        return parame.row.attributes.message;
      },
      minWidth: 120,
      flex: 2
    },
    {
      headerName: "Group",
      field: "group",
      valueGetter: (parame) => {
        return parame.row.attributes.group;
      },
      minWidth: 70,
      maxWidth: 90
    },
    {
      headerName: "Edit",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {
              handleSetTypeOfClient(cellValues.row.id);
            }}
          >
            Edit
          </Button>
        );
      },
      minWidth: 180,
      maxWidth: 250
    }
  ];

  return (
    <Row className="px-5 mt-4">
      <Button
        className="my-3 btnDark"
        onClick={() => setOpenModalTypeOfClient(true)}
      >
        Add new type of client
      </Button>
      <DataGrid
        autoHeight
        rows={typeOfContact}
        columns={columns}
        sortModel={sortModel}
      />
      <ModalAddEditTypeOfContact
        openModalTypeOfClient={openModalTypeOfClient}
        setOpenModalTypeOfClient={setOpenModalTypeOfClient}
        selectedTypeOfClient={selectedTypeOfClient}
        setSelectedTypeOfClient={setSelectedTypeOfClient}
        handleSubmitTypeOfClient={handleSubmitTypeOfClient}
      />
    </Row>
  );
};

export default ClientTypes;
