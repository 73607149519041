export const validateNote = (note) => {
  if (!note.note) {
    return { pass: false, msg: "Note is required" };
  }
  if (!note.owner) {
    return { pass: false, msg: "Owner is required" };
  }
  if (!note.category) {
    return { pass: false, msg: "Category is required" };
  }
  if (note.event) {
    if (!note.assignee) {
      return { pass: false, msg: "Assignee is required" };
    }
    if (!note.start) {
      return { pass: false, msg: "Start date is required" };
    }
    if (!note.time_start) {
      return { pass: false, msg: "Start time is required" };
    }
  }
  if (note.todo) {
    if (!note.todo_status) {
      return { pass: false, msg: "ToDo Status is required" };
    }
  }

  return { pass: true };
};
