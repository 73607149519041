import useIntakes from "../../hooks/useIntakes";
import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { formatMyDate } from "../../Extra/index";

import useStaff from "../../hooks/useStaff";
import { useNavigate } from "react-router-dom";
import { GetQuery } from "../../Extra/Querys";
import useDocuments from "../../hooks/useDocuments";

const TableContact = () => {
  const { setClientSelected, handleGetIndividualIntake, setIntake, intake } =
    useIntakes();
  const { setFilesInsideIntake } = useDocuments();
  const navigate = useNavigate();
  const { staff } = useStaff();
  const [contactList, setContactList] = useState([]);
  const [pageState, setPageState] = useState({
    pageSize: 25,
    total: 0,
    page: 1,
    isLoadign: true
  });
  const firstUpdate = useRef(true);

  //Filters URL
  const basicURL = `/contacts?sort=createdAt:DESC&populate=*`;
  const filterbyGroup = `[filters][group][$eq]=${staff?.group}`;
  const filterPagination = `&pagination[page]=${pageState.page}&pagination[pageSize]=${pageState.pageSize}`;

  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const searchData = async () => {
    const field = queryOptions.filterModel.items[0].columnField
      ? queryOptions.filterModel.items[0].columnField
      : "";
    const fieldValue = queryOptions.filterModel.items[0].value
      ? queryOptions.filterModel.items[0].value
      : "";

    setPageState((prev) => ({
      ...prev,
      isLoadign: true
    }));
    const getData = await GetQuery(
      `${basicURL}&filters[${field}][$containsi]=${fieldValue}`
    );
    setContactList(getData.data);
    setPageState((prev) => ({
      ...prev,
      page: getData.meta.pagination.page,
      total: getData.meta.pagination.total,
      isLoadign: false
    }));
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue === ""
    ) {
      loadData();
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue !== ""
    ) {
      searchData();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptions]);

  const loadData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoadign: true
    }));
    const getData = await GetQuery(
      `${basicURL}&${filterbyGroup}${filterPagination}`
    );
    setContactList(getData.data);
    setPageState((prev) => ({
      ...prev,
      page: getData.meta.pagination.page,
      total: getData.meta.pagination.total,
      isLoadign: false
    }));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    { headerName: "Id", field: "id", width: 70 },
    {
      headerName: "First Name",
      field: "first_name",
      valueGetter: (parame) => {
        return parame.row.attributes.first_name;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "last_name",
      valueGetter: (parame) => {
        return parame.row.attributes.last_name;
      },
      minWidth: 120,
      flex: 1
    },
    {
      field: "Action",
      minWidth: 120,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark"
            onClick={async () => {
              const res = await handleGetIndividualIntake(
                cellValues.row.attributes.intakes.data[0].id
              );
              setFilesInsideIntake([]);
              if (res) {
                navigate("/intake");
              }
            }}
            disabled={
              cellValues.row.attributes.intakes.data.length === 0 ? true : false
            }
          >
            {cellValues.row.attributes.intakes.data.length === 0
              ? "Dont have intake"
              : "Open Intake"}
          </Button>
        );
      }
    },
    {
      headerName: "Phone",
      field: "primary_phone",
      valueGetter: (parame) => {
        return parame.row.attributes.primary_phone;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Date",
      field: "date",
      renderCell: (cellValues) => {
        return formatMyDate(cellValues.row.attributes.createdAt);
      },
      minWidth: 120,
      flex: 1
    },
    {
      field: "Select for make intake",
      minWidth: 120,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark px-5"
            onClick={() => {
              setClientSelected({
                id: cellValues.row.id,
                name: cellValues.row.attributes.first_name,
                phone: cellValues.row.attributes.primary_phone,
                email: cellValues.row.attributes.email
              });
              setIntake({
                ...intake,
                group: staff.group,
                contact: cellValues.row.id
              });
              navigate("/new-intake");
            }}
          >
            Select
          </Button>
        );
      }
    }
  ];

  return (
    <div className="px-5 mt-4" style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        loading={pageState.isLoadign}
        rows={contactList}
        rowCount={pageState.total}
        columns={columns}
        paginationMode="server"
        pageSize={pageState.pageSize}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) =>
          setPageState((prev) => ({ ...prev, page: newPage }))
        }
        onPageSizeChange={(newPageSize) =>
          setPageState((prev) => ({ ...prev, pageSize: newPageSize }))
        }
        rowsPerPageOptions={[25, 50, 100]}
      />
    </div>
  );
};

export default TableContact;
