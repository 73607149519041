import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useDashboard from "../../hooks/useDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
};

const ChartsSource = () => {
  const { intakeForDash, loaded } = useDashboard();
  const [values, setValues] = useState([]);

  const loadValues = () => {
    const sourceIntake = intakeForDash.reduce(
      (acc, { attributes: { source } }) => {
        if (
          source &&
          source.data &&
          source.data.attributes &&
          source.data.attributes.source
        ) {
          acc[source.data.attributes.source] =
            (acc[source.data.attributes.source] || 0) + 1;
        }

        return acc;
      },
      {}
    );

    setValues(sourceIntake);
  };

  useEffect(() => {
    if (loaded) {
      loadValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "Intakes by source",
          data: values,
          backgroundColor: "#183440",
        },
      ],
      labels: Object.keys(values),
    };
  });

  return <>{loaded && <Bar data={data} options={options} />}</>;
};

export default ChartsSource;
