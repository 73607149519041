import { Button, Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  BsInboxFill,
  BsBell,
  BsPersonCircle,
  BsPeople,
  BsHeadset,
  BsFillTelephoneForwardFill
} from "react-icons/bs";
import useStaff from "../hooks/useStaff";
// import useTwilio from "../hooks/useTwilio";
import useSoftphone from "../hooks/useSoftphone";
import MenuExtraAdmin from "./MenuExtraAdmin";
import MenuStaff from "./MenuStaff";
import CardUserStatus from "../components/CardUserStatus";
//import { EnqueueCalls } from "../components/call/EnqueueCalls";

const Header = () => {

  const {
    handleLogout,
    menuOpen,
    setMenuOpen,
    staff,
    userInfo,
    updadateStatus
  } = useStaff();
  const {
    // callJsSIP,
    // answerJsSIP,
    register,
    activo,
    // inCall,
    // setInCall
  } = useSoftphone();

  // const callPhone = () => {
  //   if(!inCall){
  //     const elemento = document.getElementById("phone_number");
  //     const numero = elemento !== null ? elemento.value : "";
  //     if(numero != '' && numero.includes("+")){
  //       callJsSIP(numero);
  //     }
  //   }
  // };

  return (
    <>
      <Row className={`px-0  mx-0 ${menuOpen && "pt-4 "} `}>
        {!menuOpen && (
          <Row className="mb-3 px-0  mx-0 ">
            <Col
              style={{ backgroundColor: "#183440" }}
              className="d-flex justify-content-center p-0 text-light "
            >
              <p className="text-center mt-2 mx-2">
                Hello, <span className="fw-bold">{staff.full_name}</span>
              </p>
              {menuOpen && (
                <p className="text-center m-0 mb-5">Welcome to CASE</p>
              )}
              {staff.rol === "sales" ? <MenuStaff /> : null}
              {staff.rol === "admin" ? <MenuStaff /> : null}
              {staff.rol === "admin" ? <MenuExtraAdmin /> : null}
            </Col>
          </Row>
        )}
        <Row className={` px-5 ${menuOpen && "px-0 "}`}>
          <Col md={3} className={`align-items-center p-0 m-0`}></Col>
          <Col className="d-flex justify-content-end p-0 m-0">
            <Button
              variant="outline-light"
              className="bg-light text-secondary mx-1 "
            >
              <BsInboxFill />
            </Button>
            <Button
              variant="outline-light"
              className="bg-light text-secondary mx-1 "
            >
              <BsBell />
            </Button>
            <Button
              className={`${activo ? "btnGreen" : "btnRed"}`}
              // onClick={handleAudioContext}
              onClick={() => {register()}}
            >
              Dialer <BsHeadset />
            </Button>
            <Button
              className="mx-2 btnDark px-3"
              // onClick={handleShowCallsOnHold}
              // disabled={!callsOnHold}
            >
              <BsFillTelephoneForwardFill />
            </Button>
            {/* <input type="text" id="phone_number"/>
            <Button
              className="mx-2 btnDark px-4"
              onClick={callJsSIP(numero)}
              // disabled={!callsOnHold}
            >
              <BsFillTelephoneForwardFill />
            </Button> */}
            <DropdownButton
              title={<BsPeople className="text-muted" />}
              variant="light"
              className="bg-light text-secondary mx-1 "
              onClick={(e) => updadateStatus()}
            >
              {userInfo.map((user) => (
                <Dropdown.Item key={user.id}>
                  <CardUserStatus user={user} />
                  <hr className="mb-0" />
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton
              title={<BsPersonCircle className="text-muted" />}
              variant="light"
              className="bg-light text-secondary mx-1 "
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                {menuOpen ? "Close Menu" : "Open Menu"}
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  handleLogout();
                }}
              >
                Log out
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      </Row>
      <hr className="mt-2 mb-0" />
      {/* <EnqueueCalls /> */}
    </>
  );
};

export default Header;
