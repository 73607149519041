import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { BsFillPlayFill } from "react-icons/bs";
import useStaff from "../../hooks/useStaff";

import ReportHeader from "./ReportHeader";
import { formatMyDate, timeSeparation } from "../../Extra";

const ReportCalls = () => {
  const twilioUrl = process.env.REACT_APP_CALLCENTER_URL;
  const { staff } = useStaff()
  const [callsMade, setCallsMade] = useState([]);

  const [pageState, setPageState] = useState({
    pageSize: 25,
    total: 0,
    page: 1,
    isLoadign: true
  });

  const [queryOptions, setQueryOptions] = useState({});
  const firstUpdate = useRef(true);

  const getCalls = async () => {
    const getCallsMade = await axios(
      `${twilioUrl}/call/group/${staff.group}?page=${pageState.page}&pageSize=${pageState.pageSize}`
    );
    setCallsMade(getCallsMade.data.callList);

    setPageState((prev) => ({
      ...prev,
      total: getCallsMade.data.total,
      isLoadign: false
    }));
  };

  const searchData = async () => {
    const field = queryOptions.filterModel.items[0].columnField
      ? queryOptions.filterModel.items[0].columnField
      : "";
    const fieldValue = queryOptions.filterModel.items[0].value
      ? queryOptions.filterModel.items[0].value
      : "";

    setPageState((prev) => ({
      ...prev,
      isLoadign: true
    }));

    const getFiltersCalls = await axios(
      `${twilioUrl}/call/search?searchField=${field}&searchValue=${fieldValue}`
    );

    setCallsMade(getFiltersCalls.data);

    setPageState((prev) => ({
      pageSize: 25,
      total: 0,
      page: 1,
      isLoadign: false
    }));
  };

  useEffect(() => {
    getCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue === ""
    ) {
      getCalls();
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue !== ""
    ) {
      searchData();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptions]);

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const columns = [
    {
      headerName: "Play",
      renderCell: (cellValues) => {
        return (
          <a
            href={cellValues.value}
            target="_blank"
            rel="noreferrer"
            className="bgDark text-white d-flex justify-content-center align-items-center m-auto"
            style={{ borderRadius: "50%", padding: "8px" }}
            onClick={() => {}}
          >
            <BsFillPlayFill />
          </a>
        );
      },
      field: "RecordingUrl",
      width: 80
    },
    {
      headerName: "Agent",
      field: "name",
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Phone",
      field: "To",
      width: 130,
      flex: 1
    },
    {
      headerName: "Caller ID",
      field: "From",
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Date",
      field: "updatedAt",
      renderCell: (cellValues) => {
        return formatMyDate(cellValues.value);
      },
      width: 100,
      flex: 1
    },
    {
      headerName: "Time",
      field: "createdAt",
      renderCell: (cellValues) => {
        return timeSeparation(cellValues.value);
      },
      width: 100,
      flex: 1
    },
    {
      headerName: "Type",
      field: "_type",
      width: 120,
      flex: 1
    }
  ];

  return (
    <>
      <ReportHeader>Report calls</ReportHeader>
      <div className="px-5 mt-3">
        <DataGrid
          autoHeight
          loading={pageState.isLoadign}
          rows={callsMade}
          rowCount={pageState.total}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          getRowId={(row) => row._id}
          sortModel={[{ field: "createdAt", sort: "desc" }]}
          paginationMode="server"
          pageSize={pageState.pageSize}
          page={pageState.page}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onPageChange={(newPage) =>
            setPageState((prev) => ({ ...prev, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((prev) => ({ ...prev, pageSize: newPageSize }))
          }
        />
      </div>
    </>
  );
};

export default ReportCalls;
