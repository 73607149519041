/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft, BsCloudUploadFill } from "react-icons/bs";
import Papa from "papaparse";
import { configToast } from "../Extra/interfaces";
import CheckPermissions from "../components/CheckPermissions";
import { toast } from "react-toastify";
import { PostQuery } from "../Extra/Querys";

const allowedExtensions = ["csv"];

const ImportNotes = () => {

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  const navigate = useNavigate();

  const [jsonResult, setJsonResult] = useState();
  const [time, setTime] = useState();

  const handleImportNotes = async (dataRecived) => {
    // const newdate = dataRecived.date.split("/").reverse().join("-");
    const partes = dataRecived.date.split("/");
    const day = parseInt(partes[0]);
    const month = parseInt(partes[1]);
    const year = parseInt(partes[2]);

    const newYear = year < 100 ? year + 2000 : year;
    const fechaCorregida = [month, day, newYear].join("/");

    const formatedDate = new Date(fechaCorregida).toISOString();

    const data = {
      date_on_import: formatedDate,
      ...dataRecived
    };

    let datatosend = JSON.stringify({ data });
    const res = await PostQuery(`/notes`, "POST", datatosend);
    console.log(res);
  };

  const handleSubmit = () => {
    if (!jsonResult) return notification("error", "Please load a file");

    jsonResult.map(async (contact) => {
      await handleImportNotes(contact);
    });

    notification("success", "Import finished successfully");
  };

  const hanldeLoad = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setJsonResult(results.data);
      }
    });
  };

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 130);
    }
  }, [time]);

  return (
    <CheckPermissions page={"import"}>
      <Row className="mt-3 px-4">
        <Col>
          <h2 className="txtDark m-0 p-0" style={{ fontWeight: "700" }}>
            Import notes to intake from csv
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => navigate("/new-intake")}
          >
            <BsChevronLeft /> Back
          </Button>
        </Col>
      </Row>
      <Row className="px-5 mt-5">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Enter CSV File</Form.Label>
            <Form.Control
              onChange={(e) => hanldeLoad(e)}
              id="csvInput"
              name="file"
              type="File"
              accept=".csv"
            />
          </Form.Group>
        </Col>
        <Col className="align-self-end">
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end mb-3"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              handleSubmit();
              setTime(200);
            }}
          >
            <BsCloudUploadFill /> Load File
          </Button>
        </Col>
        <Row className="px-5">
          {time > 0 ? (
            <>
              <ProgressBar variant="success" now={time} />
            </>
          ) : null}
        </Row>
      </Row>
    </CheckPermissions>
  );
};

export default ImportNotes;
