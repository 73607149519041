import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fillClient } from "../Extra/fillClient";
import { fillIntake } from "../Extra/fillIntake";

import { blankClient, blankIntake, configToast } from "../Extra/interfaces";
import { GetQuery, PostQuery } from "../Extra/Querys";
import useStaff from "../hooks/useStaff";
import {
  validateNewIntake,
  validateNewContactImport
} from "./intakeFunctions/validateNewIntake";

const IntakesContext = createContext();

const IntakesProvider = ({ children }) => {
  const { load, staff } = useStaff();
  const twilioUrl = process.env.REACT_APP_CALLCENTER_URL;

  const [typeOfContact, setTypeOfContact] = useState();
  const [typeOfCase, setTypeOfCase] = useState();
  const [contactStatus, setContactStatus] = useState();
  const [source, setSource] = useState();

  const [result, setResult] = useState("");
  const [clientSelected, setClientSelected] = useState(blankClient);
  const [intakeIndividualLoad, setIntakeIndividualLoad] = useState(false);
  const [callsMade, setCallsMade] = useState([]);
  const [smsMade, setSmsMade] = useState([]);
  const [notes, setNotes] = useState([]);

  const [intake, setIntake] = useState(blankIntake);
  const [intakeImport, setIntakeImport] = useState({});

  const handleIntakeImport = (e) => {
    setIntakeImport({ ...intakeImport, [e.target.name]: e.target.value });
  };

  //Filters URL
  const filterbyGroup = `[filters][group][$eq]=${staff?.group}&sort=id:asc`;

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  const handleEditIntake = (e) => {
    setIntake({
      ...intake,
      [e.target.name]: e.target.value
    });
  };

  const handleEditContact = (e) => {
    setClientSelected({
      ...clientSelected,
      [e.target.name]: e.target.value
    });
  };

  const handleAddtoIntake = (e) => {
    setIntake({ ...intake, [e.target.name]: e.target.value });
  };

  //functions submit

  const handleSubmitEditContact = async () => {
    const dataToSend = JSON.stringify({ data: clientSelected });
    const result = await PostQuery(
      `/contacts/${clientSelected.id}`,
      "PUT",
      dataToSend
    );
    if (result) {
      notification("success", "Contact saved successfully");
    }
  };

  const handleSubmitNewContact = async () => {
    try {
      const clientFill = { ...clientSelected, group: staff.group };
      const data = JSON.stringify({ data: clientFill });
      const result = await PostQuery(`/contacts`, "POST", data);
      console.log(result);

      notification("success", "Intake saved successfully");
      setClientSelected(blankClient);
    } catch (error) {
      notification("error", error);
      console.error(error);
    }
  };

  const handleImportContacts = async (data) => {
    const resultValidation = validateNewContactImport(intakeImport);

    if (resultValidation.continue === true) {
      const datatosend = { data: { ...data, ...intakeImport } };
      console.log(datatosend);
      const res = await PostQuery(`/contacts`, "POST", datatosend);
      console.log(res);
    } else {
      return notification(
        "error",
        "Need complete all fields for example Type of contact, Type of case, Status, Source, Assignee, Owner"
      );
    }
  };

  const handleSubmitNewIntake = async () => {
    const resultValidation = validateNewIntake(intake);
    if (resultValidation.continue === true) {
      const dataToSend = JSON.stringify({ data: intake });
      const res = await PostQuery(`/intakes`, "POST", dataToSend);
      console.log(res);
      return notification("success", "Changes saved successfully");
    } else {
      return notification("error", resultValidation.message);
    }
  };

  const handleSubmitEditIntake = async () => {
    const dataToSend = JSON.stringify({ data: intake });
    const res = await PostQuery(`/intakes/${intake.id}`, "PUT", dataToSend);
    if (res.name !== "AxiosError") {
      return notification("success", "Changes saved successfully");
    } else {
      console.error(res);
    }
  };

  const handleGetIndividualIntake = async (id) => {
    setIntake(blankIntake);
    setClientSelected(blankClient);
    const getIndividualIntake = await GetQuery(`/intakes/${id}?populate=%2A`);
    fillNewIntake(getIndividualIntake.data);
    return true;
  };

  const getIndividualIntakeByContact = async (type, value) => {
    setIntake(blankIntake);
    setClientSelected(blankClient);
    const res = await GetQuery(
      `/intakes?populate=*&filsters[contact][${type}]=${value}&sort=id:desc`
    );
    console.log("getIndividualIntakeByContact", res);
    fillNewIntake(res.data[0]);
    return true;
  };

  const handleSubmitTypeOfCase = async (selectedTypeOfCase) => {
    if (selectedTypeOfCase.id !== 0) {
      const dataToSend = {
        data: {
          type_of_case_name: selectedTypeOfCase.type_of_case_name
        }
      };
      const res = await PostQuery(
        `/type-of-cases/${selectedTypeOfCase.id}`,
        "PUT",
        dataToSend
      );
      if (res.name !== "AxiosError") {
        return notification("success", "Changes saved successfully");
      } else {
        console.error(res);
      }
    } else {
      const dataToSend = {
        data: {
          type_of_case_name: selectedTypeOfCase.type_of_case_name,
          group: staff.group
        }
      };
      const res = await PostQuery(`/type-of-cases`, "POST", dataToSend);
      if (res.name !== "AxiosError") {
        return notification("success", "New type of case saved successfully");
      } else {
        console.error(res);
      }
    }
  };

  const handleSubmitStatus = async (selectedStatus) => {
    if (selectedStatus.id !== 0) {
      const dataToSend = { data: { ...selectedStatus } };
      const res = await PostQuery(
        `/statuses/${selectedStatus.id}`,
        "PUT",
        dataToSend
      );
      if (res.name !== "AxiosError") {
        return notification("success", "Changes saved successfully");
      } else {
        console.error(res);
      }
    } else {
      const dataToSend = { data: { ...selectedStatus, group: staff.group } };
      const res = await PostQuery(`/statuses`, "POST", dataToSend);
      if (res.name !== "AxiosError") {
        return notification("success", "New Status saved successfully");
      } else {
        console.error(res);
      }
    }
  };

  const fillNewIntake = async (intake) => {
    // getCallsAndSMS(intake.attributes.contact.data.attributes.primary_phone);
    const client_fill = fillClient(intake);
    setClientSelected(client_fill);
    const intakeFill = fillIntake(intake);
    setIntake(intakeFill);
    setIntakeIndividualLoad(true);
    loadNotes(intake.id);
  };

  //hacer una peticion de notes
  const loadNotes = async (id) => {
    const res = await GetQuery(
      `/notes?populate=intake&[filters][intake]=${id}&pagination[pageSize]=300`
    );
    setNotes(res.data);
  };

  // const getCallsAndSMS = async (phoneNumber) => {
  //   const searchQuery = `/by-intake/${phoneNumber}`;
  //   const getCallsMade = await axios(`${twilioUrl}/call${searchQuery}`);
  //   setCallsMade(getCallsMade.data);
  //   const getSmsMade = await axios(`${twilioUrl}/sms${searchQuery}`);
  //   setSmsMade(getSmsMade.data);
  // };

  useEffect(() => {
    if (load) {
      loadData();
    }
    // console.log("cambia load", load);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  //load data to fill selects on new intake
  const loadData = async () => {
    // Verificar si los datos están en el almacenamiento local
    const storedStatus = localStorage.getItem("contactStatus");
    const storedTypeOfContact = localStorage.getItem("typeOfContact");
    const storedTypeOfCase = localStorage.getItem("typeOfCase");
    const storedSource = localStorage.getItem("source");

    if (
      storedStatus &&
      storedTypeOfContact &&
      storedTypeOfCase &&
      storedSource
    ) {
      // Los datos están presentes en el almacenamiento local, usarlos
      setContactStatus(JSON.parse(storedStatus));
      setTypeOfContact(JSON.parse(storedTypeOfContact));
      setTypeOfCase(JSON.parse(storedTypeOfCase));
      setSource(JSON.parse(storedSource));
    } else {
      // Los datos no están en el almacenamiento local, realizar solicitudes HTTP

      const getStatus = await GetQuery(`/statuses?${filterbyGroup}`);
      setContactStatus(getStatus.data);
      localStorage.setItem("contactStatus", JSON.stringify(getStatus.data));

      const getTypeOfContact = await GetQuery(
        `/type-of-contacts?${filterbyGroup}`
      );
      setTypeOfContact(getTypeOfContact.data);
      localStorage.setItem(
        "typeOfContact",
        JSON.stringify(getTypeOfContact.data)
      );

      const getTypeOfCase = await GetQuery(`/type-of-cases`);
      setTypeOfCase(getTypeOfCase.data);
      localStorage.setItem("typeOfCase", JSON.stringify(getTypeOfCase.data));

      const getSource = await GetQuery(`/sources?${filterbyGroup}`);
      setSource(getSource.data);
      localStorage.setItem("source", JSON.stringify(getSource.data));
    }
  };

  return (
    <IntakesContext.Provider
      value={{
        typeOfContact,
        typeOfCase,
        contactStatus,
        source,
        intake,
        result,
        setResult,
        setIntake,
        clientSelected,
        setClientSelected,
        fillNewIntake,
        intakeIndividualLoad,
        setIntakeIndividualLoad,
        handleEditContact,
        handleSubmitEditContact,
        handleAddtoIntake,
        handleEditIntake,
        handleImportContacts,
        handleSubmitNewIntake,
        handleSubmitEditIntake,
        handleSubmitNewContact,
        handleGetIndividualIntake,
        callsMade,
        smsMade,
        notes,
        loadNotes,
        setNotes,
        handleSubmitTypeOfCase,
        handleSubmitStatus,
        //new functions
        intakeImport,
        handleIntakeImport,
        getIndividualIntakeByContact
      }}
    >
      {children}
    </IntakesContext.Provider>
  );
};

export { IntakesProvider };
export default IntakesContext;
