import {
  BsFileEarmarkMedical,
  BsFileEarmarkExcel,
  BsFileEarmarkWord,
  BsFileEarmarkPdf,
  BsFileEarmarkPpt,
  BsFileEarmarkPlay,
  BsFileEarmarkZip,
  BsFileImage,
  BsFolder
} from "react-icons/bs";

export const getIcon = (ext) => {
  switch (ext) {
    case ".pdf":
      return <BsFileEarmarkPdf size={30} />;
    case ".docx":
      return <BsFileEarmarkWord size={30} />;
    case ".xlsx":
      return <BsFileEarmarkExcel size={30} />;
    case ".csv":
      return <BsFileEarmarkExcel size={30} />;
    case ".pptx":
      return <BsFileEarmarkPpt size={30} />;
    case ".mp4":
      return <BsFileEarmarkPlay size={30} />;
    case ".zip":
      return <BsFileEarmarkZip size={30} />;
    case ".png":
      return <BsFileImage size={30} />;
    case ".jpg":
      return <BsFileImage size={30} />;
    case ".jpeg":
      return <BsFileImage size={30} />;
    case "Folder":
      return <BsFolder />;
    default:
      return <BsFileEarmarkMedical size={30} />;
  }
};
