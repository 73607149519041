import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import useBasicQuestions from "../../hooks/useBasicQuestions";
import IntakeQuestions from "./IntakeQuestions";

export const IntakeFormStepTwo = () => {
  const {
    basicQuestionList,
    basicQuestionForm,
    handleSelectBQForm,
    handleSubmitBasicQuestions,
    handleEditBasicQuestions,
    editingBQ
  } = useBasicQuestions();

  return (
    <>
      <Row className="px-5">
        <Col
          xs={12}
          className="d-flex gap-3 align-items-center justify-content-between mb-3"
        >
          <p className="fw-semibold fs-5">Basic Questions</p>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Form type</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={basicQuestionForm.id}
              label="Form type"
              onChange={handleSelectBQForm}
            >
              {basicQuestionList.map((form) => (
                <MenuItem key={form.id} value={form.id}>
                  {form.attributes.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <IntakeQuestions />
        <Col xs={12}>
          <Button
            variant="dark"
            className="fw-bold mt-5 mb-5 px-5 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              editingBQ
                ? handleEditBasicQuestions()
                : handleSubmitBasicQuestions();
            }}
          >
            {editingBQ ? "Save changes" : "Submit"}
          </Button>
        </Col>
      </Row>
    </>
  );
};
