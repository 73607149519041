import useBasicQuestions from "../../hooks/useBasicQuestions";
import QuestionTextField from "../questions/QuestionTextField";
import QuestionTitle from "../questions/QuestionTitle";
import QuestionHr from "../questions/QuestionHr";
import QuestionSelect from "../questions/QuestionSelect";

const IntakeQuestions = () => {
  const { handleBasicQuestion, basicQuestionForm, basicQuestions } =
    useBasicQuestions();
  const { structure } = basicQuestionForm.attributes;
  let showSelect = true;
  let showTittle = true;
  let showHr = true;
  let size;
  let sizeText;
  let showTextField = true;

  return (
    <>
      {Array.isArray(structure) &&
        // eslint-disable-next-line array-callback-return
        structure.map((question, index) => {
          if (question.type === "text" || question.type === "textArea") {
            sizeText = question.size ? +question.size : 4;
            showTextField = true;
            if (question.conditional) {
              showTextField = false;
              const result =
                basicQuestions[question.conditional.when] ===
                question.conditional.is;
              showTextField = result;
            }

            if (showTextField) {
              //Retorna TextField
              return (
                <QuestionTextField
                  key={question.value || index}
                  question={question}
                  handler={handleBasicQuestion}
                  answer={basicQuestions}
                  size={sizeText}
                />
              );
            }
          }

          if (question.type === "title") {
            if (question.conditional) {
              showTittle = false;
              const result =
                basicQuestions[question.conditional.when] ===
                question.conditional.is;
              showTittle = result;
            }
            //Retorna titulos
            if (showTittle)
              return (
                <QuestionTitle
                  key={question.value || index}
                  question={question}
                />
              );
          } else if (question.type === "hr") {
            if (question.conditional) {
              showHr = false;
              const result =
                basicQuestions[question.conditional.when] ===
                question.conditional.is;
              showHr = result;
            }

            //Retorna HR
            if (showHr)
              return (
                <QuestionHr key={question.value || index} question={question} />
              );
          } else if (question.type === "select") {
            size = question.size ? +question.size : 4;

            if (question.conditional !== undefined) {
              showSelect = false;
              if (question.conditional.or) {
                const result =
                  basicQuestions[question.conditional.when] ===
                    question.conditional.is ||
                  basicQuestions[question.conditional.when] ===
                    question.conditional.or;
                showSelect = result;
              } else {
                const result =
                  basicQuestions[question.conditional.when] ===
                  question.conditional.is;
                showSelect = result;
              }
            } else {
              showSelect = true;
            }

            if (showSelect) {
              //Retorna Select
              return (
                <QuestionSelect
                  key={question.value || index}
                  question={question}
                  answer={basicQuestions}
                  size={size}
                  handler={handleBasicQuestion}
                />
              );
            }
          }
        })}
    </>
  );
};

export default IntakeQuestions;
