import { Button, Container, Form, Image } from "react-bootstrap";
import useStaff from "../hooks/useStaff";

const FormLogin = () => {
  const { dataLogin, HandleChangeDataLogin, handleLogin } = useStaff();

  const login = async () => {
    handleLogin();
  };

  return (
    <>
      <Container className="px-5">
        <Image className="rounded mx-auto d-block" src="/img/logo.png" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={dataLogin.identifier}
              name="identifier"
              onChange={(e) => HandleChangeDataLogin(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={dataLogin.password}
              onChange={(e) => HandleChangeDataLogin(e)}
              name="password"
            />
          </Form.Group>
          <br />
          <Button
            size="lg"
            style={{ width: "100%" }}
            type="submit"
            className="btnDark"
            id="play-button"
          >
            Login
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default FormLogin;
