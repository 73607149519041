import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CheckPermissions from "../components/CheckPermissions";

import ModalNewContact from "../components/contacts/ModalNewContact";

import TableContact from "../components/contacts/TableContact";
import { blankClient } from "../Extra/interfaces";
import useIntakes from "../hooks/useIntakes";
import ExtraQuestions from "../components/contacts/ExtraQuestions";

const Contacts = () => {
  const { setClientSelected } = useIntakes();
  const [modalShow, setModalShow] = useState(false);

  return (
    <CheckPermissions page={"contacts"}>
      <Row className="mt-3 px-4">
        <Col>
          <h2 className="txtDark" style={{ fontWeight: "700" }}>
            Contacts
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="dark"
            className="px-3 bgDark"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              setModalShow(true);
              setClientSelected(blankClient);
            }}
          >
            Add new Contact
          </Button>
        </Col>
      </Row>
      <TableContact />
      <ModalNewContact show={modalShow} onHide={() => setModalShow(false)} />
      <ExtraQuestions/>
    </CheckPermissions>
  );
};

export default Contacts;
