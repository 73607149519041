import { Button, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useStaff from "../hooks/useStaff";

const ContactSupport = () => {
  const navigate = useNavigate();
  const { handleLogout } = useStaff();

  return (
    <Row
      className=" w-100 p-3 bgDark m-0 d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <Col className="m-auto text-white d-flex flex-column ">
        <Image
          src="/img/logo-horizontal.png"
          alt="logo"
          className="w-15 m-auto"
        />
        <p className="fs-4 m-auto mt-5">
          Please contact support to reactivate your account.
        </p>
        <Col className="m-auto mt-5">
          <Button
            className=" btnMuted mt-5 px-5"
            onClick={() => {
              handleLogout();
              navigate("/login");
            }}
          >
            Logout
          </Button>
        </Col>
      </Col>
    </Row>
  );
};

export default ContactSupport;
