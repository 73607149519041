import React from "react";
import ChartsCallsPending from "../charts/ChartsCallsPending";
import ReportDateSelect from "./ReportDateSelect";
import ReportHeader from "./ReportHeader";

const ReportCallsPending = () => {
  return (
    <>
      <ReportHeader>Charts Calls Pending</ReportHeader>
      <ReportDateSelect />
      <ChartsCallsPending />
    </>
  );
};

export default ReportCallsPending;
