import React from "react";
import CheckPermissions from "../components/CheckPermissions";
import DashboardAdmin from "../components/DashboardAdmin";
import Soon from "../components/Soon";
import useStaff from "../hooks/useStaff";

const Dashboard = () => {
  const { staff } = useStaff();

  return (
    <CheckPermissions page={"dashboard"}>
      {staff.rol === "sales" ? <Soon /> : null}
      {staff.rol === "admin" ? <DashboardAdmin /> : null}
    </CheckPermissions>
  );
};

export default Dashboard;
