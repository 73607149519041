import { FormControlLabel, Switch, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalEditStatus = ({
  openModalStatus,
  setOpenModalStatus,
  selectedStatus,
  setSelectedStatus,
  handleSubmitStatus
}) => {
  const handleChange = (e) => {
    setSelectedStatus({
      ...selectedStatus,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Modal
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalStatus}
    >
      <Modal.Header closeButton onHide={() => setOpenModalStatus(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedStatus.id !== 0 ? "Edit status" : "Add new status"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Row className="d-flex justify-content-between align-content-center">
          <Col md={7}>
            <TextField
              className="mt-3"
              required
              name="status"
              value={selectedStatus?.status || ""}
              onChange={handleChange}
              variant="outlined"
              size="small"
              label="Status name"
              fullWidth
            />
          </Col>
          <Col className="m-auto">
            <FormControlLabel
              className="m-auto"
              control={
                <Switch
                  checked={selectedStatus?.notification || false}
                  name="notification"
                  onChange={(e) =>
                    setSelectedStatus({
                      ...selectedStatus,
                      notification: e.target.checked
                    })
                  }
                />
              }
              label="Notification"
            />
          </Col>
        </Row>
        <TextField
          className="mt-3"
          required
          name="message"
          value={selectedStatus?.message || ""}
          onChange={handleChange}
          variant="outlined"
          multiline
          maxRows={8}
          minRows={4}
          size="small"
          label="Status notification message "
          fullWidth
        />
        <TextField
          className="mt-3"
          required
          name="color"
          value={selectedStatus?.color || "#FFFFFF"}
          onChange={handleChange}
          variant="outlined"
          size="small"
          label="Text color"
          fullWidth
          type="color"
        />
        <TextField
          className="mt-3"
          required
          name="backgroundColor"
          value={selectedStatus?.backgroundColor || "#FFFFFF"}
          onChange={handleChange}
          variant="outlined"
          size="small"
          label="Background color"
          type="color"
          fullWidth
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={() => setOpenModalStatus(false)}
        >
          Close
        </Button>
        <Button
          variant="dark"
          className="bgDark px-5"
          style={{ borderRadius: "15px" }}
          onClick={() => {
            handleSubmitStatus();
          }}
        >
          {selectedStatus.id !== 0
            ? "Edit type of case"
            : "Save new type of case"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditStatus;
