import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BsCircleFill } from "react-icons/bs";

const SelectComponent = ({
  label,
  handleFunction,
  mapOptions,
  valueData,
  nameData
}) => {
  return (
    <FormControl fullWidth className="mt-lg-0" size="small">
      <InputLabel id="imput-to">{label}</InputLabel>
      <Select
        labelId="imput-to"
        value={valueData}
        name={nameData}
        label={label}
        onChange={handleFunction}
        size="small"
      >
        {mapOptions?.map((type) => (
          <MenuItem key={type.id} value={type.id} className="gap-2">
            {type.full_name || type.name || type.title}{" "}
            {type.status === "Online" && (
              <BsCircleFill size={10} color="green" />
            )}
            {type.status === "Offline" && (
              <BsCircleFill size={10} color="red" />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
