import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { CgOptions } from "react-icons/cg";

const ModalAddEvent = ({ onHide, show, newEventState, handleSetNewEvent }) => {
  const { title, start, end } = newEventState;
  const staffResult = JSON.parse(localStorage.getItem("staff"));

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", backgroundColor: "red", color: "white" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form>
            <Row>
              <Col md={2}>
                <h6 className="mt-2">Title: </h6>
              </Col>
              <Col>
                <Form.Control
                  placeholder="Write the title event"
                  value={title}
                  name="title"
                  onChange={(e) => handleSetNewEvent(e)}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Start at:</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control
                        type="date"
                        value={start}
                        name="start"
                        onChange={(e) => handleSetNewEvent(e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        name="time_start"
                        onChange={(e) => handleSetNewEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Ends at:</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control type="date" value={end} />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        name="timen_end"
                        onChange={(e) => handleSetNewEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Label as="h6">Type of contact:</Form.Label>
                <p className="mt-3">Client</p>
              </Col>
              <Col md={3}>
                <Form.Label as="h6">Type of contact:</Form.Label>
                <p className="bg-success text-light px-3 rounded mt-3">
                  New Lead
                </p>
              </Col>

              <Col>
                <Form.Label as="h6">Name:</Form.Label>
                <Form.Control disabled />
              </Col>
            </Row>
            <Row className="px-2">
              <Form.Label as="h6">Name:</Form.Label>
              <Form.Control as="textarea" placeholder="Write the title event" />
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Label as="h6">Created by:</Form.Label>
                <Form.Control value={staffResult.full_name} disabled />
              </Col>
              <Col md={6}>
                <Form.Label as="h6">Created by:</Form.Label>
                <Form.Control value={staffResult.full_name} disabled />
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container className="d-flex justify-content-between">
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
          >
            Save
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={onHide}
          >
            Delete
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
          >
            <CgOptions /> More options
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
          >
            Exit without changes
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddEvent;
