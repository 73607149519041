import React, { useEffect, useState } from "react";
import useStaff from "../hooks/useStaff";
import { Button, Container, Form, Image } from "react-bootstrap";

const TwoFactor = () => {
  const { handleChangeDataTwoFactor, twoFactorData, handleValidateTwoFactor } =
    useStaff();
  const [seconds, setSeconds] = useState(60);
  const [requestOtherCode, setRequestOtherCode] = useState(true);

  const login = async () => {
    handleValidateTwoFactor();
  };

  useEffect(() => {
    // Función que se ejecutará cada segundo
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds === 0) {
      setRequestOtherCode(false);
    }

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setRequestOtherCode(false);
    }
  }, [seconds]);

  return (
    <Container className="px-5">
      <Image className="rounded mx-auto d-block" src="/img/logo.png" />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Write the code</Form.Label>
          <Form.Control
            type="number"
            placeholder="Check the SMS in your phone"
            value={twoFactorData}
            name="twoFactorData"
            onChange={handleChangeDataTwoFactor}
          />
        </Form.Group>
        <br />
        <Button
          size="lg"
          style={{ width: "100%" }}
          type="submit"
          className="btnDark"
        >
          Verify
        </Button>
      </Form>

      {seconds > 0 && (
        <p className="text-center">
          {seconds} secods left to request another code
        </p>
      )}
      <Button
        size="lg"
        style={{ width: "100%" }}
        type="submit"
        disabled={requestOtherCode}
        className="btnDark mt-5"
      >
        Request another code
      </Button>
    </Container>
  );
};

export default TwoFactor;
