import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useStaff from "../hooks/useStaff";
import useIntakes from "../hooks/useIntakes";
import { GetQuery } from "../Extra/Querys";
import { intakeListUrl } from "../Extra/UrlQuery";
import { formatMyDate } from "../Extra/index";
import { blankClient } from "../Extra/interfaces";
import ModalNewContact from "../components/contacts/ModalNewContact";
import CheckPermissions from "../components/CheckPermissions";
import useDocuments from "../hooks/useDocuments";
import ExtraQuestions from "../components/contacts/ExtraQuestions";
import { fillDataArray } from "../Extra/fillDataArray";

const Inbox = () => {
  const { userInfo, staff } = useStaff();
  const { handleGetIndividualIntake, setClientSelected } = useIntakes();
  const { setFilesInsideIntake } = useDocuments();

  const [leadsInbox, setLeadsInbox] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageState, setPageState] = useState({
    pageSize: 25,
    total: 0,
    page: 1,
    isLoadign: true
  });

  const navigate = useNavigate();

  const loadData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoadign: true
    }));

    const variantUrl = `${staff.id}&pagination[page]=${pageState.page}&pagination[pageSize]=${pageState.pageSize} `;
    const { data, meta } = await GetQuery(`${intakeListUrl}${variantUrl}`);
    setLeadsInbox(fillDataArray(data));

    setPageState((prev) => ({
      ...prev,
      page: meta.pagination.page,
      total: meta.pagination.total,
      isLoadign: false
    }));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    { headerName: "Id", field: "id", width: 70 },
    {
      headerName: "First Name",
      valueGetter: (parame) => {
        return parame.row.contact.data
          ? parame.row.contact.data.attributes.first_name
          : "Error contacto";
      },
      field: "first_name",
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "last_name",
      valueGetter: (parame) => {
        return parame.row.contact.data
          ? parame.row.contact.data.attributes.last_name
          : "Error contacto";
      },
      minWidth: 120,
      flex: 1
    },
    {
      field: "Action",
      width: 130,
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark"
            onClick={async () => {
              const res = await handleGetIndividualIntake(cellValues.row.id);
              setFilesInsideIntake([]);
              if (res) {
                navigate("/intake");
              }
            }}
          >
            Open Intake
          </Button>
        );
      }
    },
    {
      headerName: "Phone",
      field: "primary_phone",
      valueGetter: (parame) => {
        return parame.row.contact.data
          ? parame.row.contact.data.attributes.primary_phone
          : "Error contacto";
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Source",
      field: "source",
      valueGetter: (parame) => {
        return parame.row.source.data.attributes.source;
      },
      width: 100
    },
    {
      field: "status",
      minWidth: 200,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <p
            className="customPill m-auto"
            style={{
              backgroundColor:
                cellValues.row.status.data.attributes.backgroundColor,
              color: cellValues.row.status.data.attributes.color
            }}
          >
            {cellValues.row.status.data.attributes.status}
          </p>
        );
      }
    },
    {
      headerName: "Date",
      field: "date",
      renderCell: (cellValues) => {
        return formatMyDate(cellValues.row.createdAt);
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Owner",
      field: "owner",
      renderCell: (cellValuesOwner) => {
        return filterUser(cellValuesOwner.row.owner);
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Assigned to",
      field: "assignee",
      renderCell: (cellValuesAssignee) => {
        return filterUser(cellValuesAssignee.row.assignee);
      },
      minWidth: 120,
      flex: 1
    }
  ];

  const filterUser = (id) => {
    if (userInfo.length !== 0) {
      const data = userInfo?.filter((data) => data.id === id);
      return data[0].full_name;
    }
  };

  return (
    <CheckPermissions page={"inbox"}>
      <Row className="mt-3 px-3">
        <Col sx={12} sm={3}>
          <h2 className="txtDark" style={{ fontWeight: "700" }}>
            Inbox
          </h2>
        </Col>
        <Col
          className="d-flex sx-justify-content-start justify-content-end"
          sx={12}
          sm={9}
        >
          <div className="d-flex justify-content-between gap-3">
            <Button
              variant="dark"
              className="px-3 bgDark"
              style={{ borderRadius: "15px" }}
              onClick={() => {
                setModalShow(true);
                setClientSelected(blankClient);
              }}
            >
              Add new Contact
            </Button>
            <Button
              className="px-5 btnDark fs-6 fw-semi fw-semibold"
              style={{ borderRadius: "15px" }}
              onClick={() => navigate("/new-intake")}
            >
              Add new Intake
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
      <div className="px-5" style={{ height: 600, width: "100%" }}>
        <DataGrid
          autoHeight
          loading={pageState.isLoadign}
          rows={leadsInbox}
          rowCount={pageState.total}
          columns={columns}
          paginationMode="server"
          pageSize={pageState.pageSize}
          // filterMode="server"
          // onFilterModelChange={onFilterChange}
          onPageChange={(newPage) =>
            setPageState((prev) => ({ ...prev, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((prev) => ({ ...prev, pageSize: newPageSize }))
          }
          rowsPerPageOptions={[25, 50, 100]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
        />
      </div>
      <ModalNewContact show={modalShow} onHide={() => setModalShow(false)} />
      <ExtraQuestions />
    </CheckPermissions>
  );
};

export default Inbox;
