import { useEffect, useState } from "react";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { BsFillPlayFill } from "react-icons/bs";
import useStaff from "../../hooks/useStaff";

import ReportHeader from "./ReportHeader";
import { formatMyDate, timeSeparation } from "../../Extra";
import { Col, Row } from "react-bootstrap";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const ReportCallsByUser = () => {
  const twilioUrl = process.env.REACT_APP_CALLCENTER_URL;
  const { staff, userInfo } = useStaff();
  const [callsMade, setCallsMade] = useState([]);
  const [selectedUser, setSelectedUSer] = useState(staff.abbreviation_name);

  const [pageState, setPageState] = useState({
    pageSize: 25,
    total: 0,
    page: 1,
    isLoadign: true,
  });

  const handleSelectUser = (e) => {
    setSelectedUSer(e.target.value);
  };

  const getCalls = async () => {
    const getCallsMade = await axios(
      `${twilioUrl}/call/user/${selectedUser}?page=${pageState.page}&pageSize=${pageState.pageSize}`
    );
    console.log(getCallsMade);
    setCallsMade(getCallsMade.data.callList);

    setPageState((prev) => ({
      ...prev,
      total: getCallsMade.data.total,
      isLoadign: false,
    }));
  };

  useEffect(() => {
    getCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize, selectedUser]);

  const columns = [
    {
      headerName: "Play",
      renderCell: (cellValues) => {
        return (
          <a
            href={cellValues.value}
            target="_blank"
            rel="noreferrer"
            className="bgDark text-white d-flex justify-content-center align-items-center m-auto"
            style={{ borderRadius: "50%", padding: "8px" }}
            onClick={() => {}}
          >
            <BsFillPlayFill />
          </a>
        );
      },
      field: "RecordingUrl",
      width: 80,
    },
    {
      headerName: "Agent",
      field: "name",
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Phone",
      field: "To",
      width: 130,
      flex: 1,
    },
    {
      headerName: "Caller ID",
      field: "From",
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Date",
      field: "updatedAt",
      renderCell: (cellValues) => {
        return formatMyDate(cellValues.value);
      },
      width: 100,
      flex: 1,
    },
    {
      headerName: "Time",
      field: "createdAt",
      renderCell: (cellValues) => {
        return timeSeparation(cellValues.value);
      },
      width: 100,
      flex: 1,
    },
    {
      headerName: "Type",
      field: "_type",
      width: 120,
      flex: 1,
    },
  ];

  return (
    <Row>
      <ReportHeader>Report Calls by User</ReportHeader>
      <Col className="mt-3 px-5">
        <FormControl fullWidth className="mt-lg-0" size="small">
          <InputLabel id="imput-to">Select User</InputLabel>
          <Select
            labelId="imput-to"
            value={selectedUser}
            name="selecteduser"
            label="Select User"
            onChange={handleSelectUser}
            size="small"
          >
            {userInfo?.map((type) => (
              <MenuItem key={type.id} value={type.abbreviation_name}>
                {type.full_name || type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <div className="px-5 mt-3">
        <DataGrid
          autoHeight
          loading={pageState.isLoadign}
          rows={callsMade}
          rowCount={pageState.total}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          getRowId={(row) => row._id}
          sortModel={[{ field: "createdAt", sort: "desc" }]}
          paginationMode="server"
          pageSize={pageState.pageSize}
          page={pageState.page}
          onPageChange={(newPage) =>
            setPageState((prev) => ({ ...prev, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((prev) => ({ ...prev, pageSize: newPageSize }))
          }
        />
      </div>
    </Row>
  );
};
