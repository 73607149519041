export const fillIntake = (intake) => {
  const intakeFill = {
    id: intake.id,
    open: intake.attributes.open,
    contact_BQ_complete: intake.attributes.contact_BQ_complete
      ? intake.attributes.contact_BQ_complete
      : false,
    pending_call: intake.attributes.pending_call,
    group: intake.attributes.group,
    owner: intake.attributes.owner,
    assignee: intake.attributes.assignee,
    status: intake.attributes.status.data.id,
    type_of_contact: intake.attributes.type_of_contact.data.id,
    contact: intake.attributes.contact.data.id,
    source: intake.attributes.source.data.id,
    type_of_case: intake.attributes.type_of_case.data.id,
    createdAt: intake.attributes.createdAt,
    basic_question: intake.attributes.contact_BQ_complete
      ? intake.attributes.basic_question.data.id
      : null,
    basic_question_answer: intake.attributes.contact_BQ_complete
      ? intake.attributes.basic_question_answer.data.id
      : null,
    events:
      intake.attributes.events.data.length > 0
        ? intake.attributes.events.data
        : null,
    notes:
      intake.attributes.notes.data.length > 0
        ? intake.attributes.notes.data
        : null,
    bill_histories:
      intake.attributes.bill_histories.data.length > 0
        ? intake.attributes.bill_histories.data
        : null
  };

  return intakeFill;
};
