import React, { useState } from "react";
import CheckPermissions from "../components/CheckPermissions";
import CaseTypes from "../components/settings/CaseTypes";
import ClientTypes from "../components/settings/ClientTypes";
import FirmSettings from "../components/settings/FirmSettings";
import HeaderSettings from "../components/settings/HeaderSettings";
import ManagerPermissions from "../components/settings/ManagerPermissions";
import ManagerUsers from "../components/settings/ManagerUsers";
import MenuSetup from "../components/settings/MenuSetup";
import MicrosoftSettigns from "../components/settings/MicrosoftSettigns";
import PhoneNumber from "../components/settings/PhoneNumber";
import Statuses from "../components/settings/Statuses";

const Setup = () => {
  const [secction, setSecction] = useState("menu-settings");
  const [secctionTitle, setSecctionTitle] = useState("Settings");
  console.log("secction", secction);
  console.log("secctionTitle", secctionTitle);
  return (
    <CheckPermissions page="setup">
      <HeaderSettings
        setSecction={setSecction}
        secctionTitle={secctionTitle}
        setSecctionTitle={setSecctionTitle}
      >
        {secction === "menu-settings" && (
          <MenuSetup
            setSecction={setSecction}
            setSecctionTitle={setSecctionTitle}
          />
        )}
        {secction === "firm-settings" && <FirmSettings />}
        {secction === "manager-users" && <ManagerUsers />}
        {secction === "manager-permissions" && <ManagerPermissions />}
        {secction === "case-types" && <CaseTypes />}
        {secction === "client-types" && <ClientTypes />}
        {secction === "statuses" && <Statuses />}
        {secction === "microsoft-settings" && <MicrosoftSettigns />}
        {secction === "phone-numbers" && <PhoneNumber />}
      </HeaderSettings>
    </CheckPermissions>
  );
};

export default Setup;
