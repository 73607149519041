import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { blankIntake } from "../Extra/interfaces";
import CheckPermissions from "../components/CheckPermissions";
import useIntakes from "../hooks/useIntakes";
import TopMenu from "../components/intake/menu/TopMenu";
// import SendSMSModal from "../components/SendSMSModal";
import {
  IntakeFormStepOne,
  IntakeFormStepTwo,
  IntakeFormStepTree,
  IntakeFormStepFour
} from "../components/intake";
import useStaff from "../hooks/useStaff";
// import { ModalTranferCall } from "../components/call/ModalTranferCall";

const Intake = () => {
  const navigate = useNavigate();
  const { setIntake } = useIntakes();
  const { callActive } = useStaff();

  const [step, setStep] = useState(1);
  //TODO: revisar si es necesario
  // const [modalShow, setModalShow] = useState(false);

  const handleStep = (number) => {
    setStep(number);
  };

  return (
    <CheckPermissions page={"intake"}>
      {!callActive && (
        <Row className="my-3 px-4">
          <Col>
            <h2 className="txtDark m-0 p-0" style={{ fontWeight: "700" }}>
              Intake
            </h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              className="fw-bold px-5 btnDark align-self-center justify-content-end"
              style={{ borderRadius: "15px" }}
              onClick={() => {
                navigate("/inbox");
                setIntake(blankIntake);
              }}
            >
              <BsChevronLeft /> Back
            </Button>
          </Col>
        </Row>
      )}
      <TopMenu handleStep={handleStep} step={step} />
      {step === 1 && <IntakeFormStepOne />}
      {step === 2 && <IntakeFormStepTwo />}
      {step === 3 && <IntakeFormStepTree />}
      {step === 4 && <IntakeFormStepFour />}
      {/* <SendSMSModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      {/* <ModalTranferCall/> */}
    </CheckPermissions>
  );
};

export default Intake;
