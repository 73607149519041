import { FirstGetQuery } from "../../Extra/Querys";
// import { fillSetup } from "../../Extra/fillSetupData";

export const getUsersData = async (staff, token) => {
  const res = await FirstGetQuery(
    `/users?populate=*&sort=id:asc&[filters][activation][$eq]=yes&[filters][group][$eq]=${staff.group}`,
    token
  );

  return res;
};
