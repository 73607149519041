export const intakeListUrl =
  "/intakes?sort=createdAt:desc&populate=*&filters[open][$eq]=true&filters[assignee][$eq]=";

// export const callListQuery =
//   "/intakes?sort=createdAt:desc:desc&populate=*&[filters][pending_call][$eq]=true";

export const callListQuery =
  "/intakes?sort=createdAt:desc&populate=*&[filters][pending_call][$eq]=true";

export const leadsGetUrl =
  "/leads?sort=createdAt:desc&populate=*&filters[active][$eq]=true&filters[requested][$eq]=false&filters[group][$eq]=";

export const leadsInboxUrl =
  "/leads?sort=createdAt:desc&populate=*&filters[active][$eq]=true&filters[group][$eq]=";
