import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField
} from "@mui/material";
import { ContentNote } from "./ContentNote";
import { notification } from "../../../Extra/notification";
import { createNote } from "../../../context/eventsFunctions/createNote";
import useStaff from "../../../hooks/useStaff";
import useIntakes from "../../../hooks/useIntakes";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "15px"
};

export const ModalNewNote = ({ open, handleClose, note, handleFillNote }) => {
  const { staff } = useStaff();
  const { intake, handleGetIndividualIntake } = useIntakes();

  const [biliableNote, setBiliableNote] = useState(false);

  const handleIsBiliableNote = () => {
    setBiliableNote(!biliableNote);
  };

  const handleSubmitNote = async () => {
    await createNote(note, staff, intake);
    await handleGetIndividualIntake(intake.id);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleExit = () => {
    notification(
      "error",
      "If you want to exit, please click on the close button"
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleExit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New {biliableNote && "Biliable "} Note
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: "50%",
              justifyContent: "end"
            }}
          >
            {biliableNote && (
              <TextField
                id="outlined-textarea"
                label="Time"
                name="time"
                value={note.time ? note.time : ""}
                placeholder="Add time"
                onChange={handleFillNote}
                size="small"
                multiline
              />
            )}
            <FormGroup sx={{ width: "50%" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={biliableNote}
                    onChange={handleIsBiliableNote}
                  />
                }
                label="It's biliable?"
              />
            </FormGroup>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <ContentNote note={note} handleFillNote={handleFillNote} />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button className="btnDark px-5" onClick={handleSubmitNote}>
            Add Note
          </Button>
          <Button className="btnBlank px-5" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
