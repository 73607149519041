import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import useDocuments from "../../hooks/useDocuments";
import useIntakes from "../../hooks/useIntakes";

const DOCUMENT_LIST = [
  {
    doc: "g28",
    name: "G-28"
  },
  {
    doc: "doj361",
    name: "G-361"
  },
  {
    doc: "i130",
    name: "I-130"
  },
  {
    doc: "i130a",
    name: "I-130a"
  },
  {
    doc: "g639",
    name: "G-639"
  },
  {
    doc: "g1145",
    name: "G-1145"
  },
  {
    doc: "i765",
    name: "I-765"
  }
];

const DocumentsAutomations = () => {
  const { clientSelected, intake } = useIntakes();
  const { documentAutomation } = useDocuments();
  const clientName = `${clientSelected.first_name}_${clientSelected.last_name}`;
  return (
    <Row>
      <h4 className="mb-5 fw-bold">Auto generate docuements </h4>
      {DOCUMENT_LIST.map((doc, index) => (
        <Col md={2} key={index}>
          <Button
            className="btnDark px-5 mb-3 w-100 py-3"
            onClick={() =>
              documentAutomation(
                clientSelected.id,
                doc.doc,
                clientName,
                doc.name,
                intake.id
              )
            }
          >
            {doc.name}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

export default DocumentsAutomations;
