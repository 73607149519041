import { createContext, useState, useEffect } from "react";
import useStaff from "../hooks/useStaff";
// import JsSIP from 'jssip'; // Asegúrate de haber instalado jssip en tu proyecto
import JsSIP from 'jssip'; // Asegúrate de haber instalado jssip en tu proyecto
import useSound from 'use-sound';
import ring from './ringing.mp3';

const SoftphoneContext = createContext();

const SoftphoneProvider = ({ children }) => {
  const [incominModal, setIncominModal] = useState(false);
  const [callIncoming, setCallIncoming] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inCall, setInCall] = useState(false);
    // eslint-disable-next-line no-unused-vars
  const [forRender, setForRender] = useState("");
  const [play, { stop }] = useSound(ring);
  const { staff } = useStaff();
  const [activo, setActivo] = useState(false);
  const [ua, setUa] = useState();
  const [session, setSession] = useState();
    // eslint-disable-next-line no-unused-vars
  const [socket, setSocket] = useState(new JsSIP.WebSocketInterface('wss://143.198.139.233:8089/ws'));
  const [configuration, setConfiguration] = useState({
    sockets: [socket],
    uri: 'sip:' + staff.extension + '@143.198.139.233',
    password: staff.extension_password,
  });

 // Configuración de eventos
 const eventHandlers = {
   progress: (e) => {
     console.log(e);
      setCallIncoming(true);
     console.log('call is in progress');
   },
   failed: (e) => {
     console.log(e);
     setCallIncoming(false);
     setIncominModal(false);
   },
   ended: (e) => {
     console.log(e);
     setCallIncoming(false);
     setIncominModal(false);
     navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    }).then((mediaStream) => {
       // Stop the stream after 5 seconds
       const tracks = mediaStream.getTracks();
       tracks.forEach(t => {
       t.stop();
       // I don't think removeTrack is necessary, but just keeping it.
       mediaStream.removeTrack(t);
       })
     });
   },
   confirmed: (e) => {
     console.log('call confirmed');
   },
 };

 const options = {
   eventHandlers: eventHandlers,
   mediaConstraints: { audio: true, video: false },
   pcConfig: {
     rtcpMuxPolicy: 'negotiate',
     iceServers: [{ urls: ['stun:143.198.139.233:3478'] }],
   },
   rtcOfferConstraints: {
       offerToReceiveAudio: 1,
       offerToReceiveVideo: 0
   }
 };


 useEffect(() => {

  socket.via_transport = 'auto';
  setConfiguration ({
    sockets: [socket],
    uri: 'sip:' + staff.extension + '@143.198.139.233',
    password: staff.extension_password,
  });
 }, [socket, staff]);


 useEffect(() => {

  //setSocket ();
  
  setUa(new JsSIP.UA(configuration));

 }, [configuration])

 // Registro y manejo de nuevas sesiones RTC
 const register = () => {
  if(activo){
    setActivo(false);
    ua.unregister();
  }else{
    // Configuración de JsSIP
  
   ua.start();
   ua.register();
   setActivo(true);

   //ua.call(`sip:100@143.198.139.233`, options);
  //  console.log("Se registrò");

   ua.on('newRTCSession', (data) => {
     const originator = data.originator;
     const session_tmp = data.session;
    //  console.log("newRTCSession =>", data);
    //  const request = data.request;
     session_tmp.on("icecandidate", function (event) {
      event.ready();
      });
      session_tmp.on("failed", function (event) {
        setInCall(false);
        stop();
        setCallIncoming(false);
        setIncominModal(false);
        });
      session_tmp.on("ended", function (event) {
        setInCall(false);
        stop();
        setCallIncoming(false);
        setIncominModal(false);
        });
      session_tmp.on("started", function (event) {
        setInCall(true);
        stop();
        });
      

     setSession(session_tmp);
     // Responder a la nueva sesión
     // eslint-disable-next-line eqeqeq
     if(originator == "remote"){
          // Hacer Ring y mostrar ventana modal para contestar...
          play();
          setIncominModal(true);
     }else{
      session_tmp.connection.onaddstream = (e) => {
        const audio = new Audio();
        audio.srcObject = e.stream;
        audio.play();
      };
     }

    
   });
  }
 };

 const terminateJsSIP = () => {
    stop();
    session.terminate();
 };

 // Funciòn para contestar una llamada
 const answerJsSIP = () => {
  stop();
  navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  }).then((mediaStream) => {
     // Stop the stream after 5 seconds
     const tracks = mediaStream.getTracks();
     tracks.forEach(t => {
     t.stop();
     // I don't think removeTrack is necessary, but just keeping it.
     mediaStream.removeTrack(t);
     })
   });

  session.answer(options);

  session.connection.onaddstream = (e) => {
    const audio = new Audio();
    audio.srcObject = e.stream;
    audio.play();
  };
   // Manejar la corriente de audio cuando se agrega
   
 };

 // Función para realizar una llamada
 const callJsSIP = (number) => {
    // console.log("callJsSIP =>", number);
    // si no hay numero no hacer nada
    if(number === "") return;
    
   navigator.mediaDevices.getUserMedia({
       audio: true,
       video: false,
     }).then((mediaStream) => {
        // Stop the stream after 5 seconds
        // console.log("mediaStream =>", mediaStream);
        const tracks = mediaStream.getTracks();
        // console.log("tracks =>", tracks);
        tracks.forEach(t => {
        t.stop();
        // I don't think removeTrack is necessary, but just keeping it.
        mediaStream.removeTrack(t);
        })
      });
     setSession(ua.call(`sip:${number}`, options));

     
};
   


  return (
    <SoftphoneContext.Provider
      value={{
        callJsSIP,
        answerJsSIP,
        register,
        activo,
        incominModal,
        setIncominModal,
        callIncoming,
        terminateJsSIP,
        inCall,
      }}
    >
      {forRender}
      {children}
    </SoftphoneContext.Provider>
  );
};

export { SoftphoneProvider };
export default SoftphoneContext;
