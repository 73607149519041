import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import useDashboard from "../../hooks/useDashboard";

const PhoneNumber = () => {
  const { getPhoneNumbers, phoneNumberList } = useDashboard();
  useEffect(() => {
    getPhoneNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      headerName: "Number",
      field: "number",
      valueGetter: (parame) => {
        return parame.row.attributes.number;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Active",
      field: "active",
      valueGetter: (parame) => {
        return parame.row.attributes.active;
      },
      minWidth: 70,
      maxWidth: 90
    },
    {
      headerName: "Group",
      field: "group",
      valueGetter: (parame) => {
        return parame.row.attributes.group;
      },
      minWidth: 70,
      maxWidth: 90
    },
    {
      headerName: "Select Number for calls",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {}}
          >
            Select number
          </Button>
        );
      },
      minWidth: 250,
      maxWidth: 300
    }
  ];

  return (
    <Row className="px-5 mt-5">
      <DataGrid
        autoHeight
        rows={phoneNumberList}
        columns={columns}
        sortModel={sortModel}
      />
    </Row>
  );
};

export default PhoneNumber;
