import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useStaff from "../../hooks/useStaff";
import useDashboard from "../../hooks/useDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true
    }
  }
};

const ChartsCallsPending = () => {
  const { userInfo } = useStaff();
  const { intakeForDash, loaded } = useDashboard();
  const [values, setValues] = useState([]);

  const loadValues = () => {
    const callsByAssignee = intakeForDash.reduce((acc, call) => {
      const { assignee, pending_call } = call.attributes;
      if (pending_call) {
        if (acc[assignee]) {
          acc[assignee] += 1;
        } else {
          acc[assignee] = 1;
        }
      }
      return acc;
    }, {});

    const mergeData = Object.keys(callsByAssignee).reduce((acc, key) => {
      const found = userInfo.find((item) => item.id === Number(key));
      if (found) {
        acc[found.abbreviation_name] = callsByAssignee[key];
      }
      return acc;
    }, {});

    setValues(mergeData);
  };

  useEffect(() => {
    if (loaded) {
      loadValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "Calls pending",
          data: values,
          backgroundColor: "#183440"
        }
      ],
      labels: Object.keys(values)
    };
  });

  return <>{loaded && <Bar data={data} options={options} />}</>;
};

export default ChartsCallsPending;
