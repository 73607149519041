import axios from "axios";

export const GetMGData = async (url, token) => {
  const result = await axios(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return result.data;
};

export const PostMGData = async (url, token, data) => {
  const result = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return result.data;
};
