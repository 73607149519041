import ChartTypeOfCase from "../charts/ChatsTypeOfCase";
import ReportDateSelect from "./ReportDateSelect";
import ReportHeader from "./ReportHeader";

const ReportIntakeByTypeOfCase = () => {
  return (
    <>
      <ReportHeader>Charts Intake by Type of Case</ReportHeader>
      <ReportDateSelect/>
      <ChartTypeOfCase />
    </>
  );
};

export default ReportIntakeByTypeOfCase;
