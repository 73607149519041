import useBasicQuestions from "../../hooks/useBasicQuestions";
import QuestionTextField from "../questions/QuestionTextField";
import QuestionTitle from "../questions/QuestionTitle";
import QuestionHr from "../questions/QuestionHr";
import QuestionSelect from "../questions/QuestionSelect";

const ExtraQuestionValidate = ({ structure, extra_answer }) => {
  let showSelect = true;
  let showTittle = true;
  let showHr = true;
  let size;
  let showTextField = true;
  const { handleAnwswerExtraQuestions } = useBasicQuestions();

  return (
    <>
      {Array.isArray(structure) &&
        structure.map((question, index) => {
          if (question.type === "text") {
            showTextField = true;
            if (question.conditional) {
              showTextField = false;
              const result =
                extra_answer[question.conditional.when] ===
                question.conditional.is;
              showTextField = result;
            }

            if (showTextField) {
              //Retorna TextField
              return (
                <QuestionTextField
                  key={question.value || index}
                  question={question}
                  handler={handleAnwswerExtraQuestions}
                  answer={extra_answer}
                />
              );
            }
          } else if (question.type === "title") {
            if (question.conditional) {
              showTittle = false;
              const result =
                extra_answer[question.conditional.when] ===
                question.conditional.is;
              showTittle = result;
            }

            //Retorna titulos
            if (showTittle)
              return (
                <QuestionTitle
                  key={question.value || index}
                  question={question}
                />
              );
          } else if (question.type === "hr") {
            if (question.conditional) {
              showHr = false;
              const result =
                extra_answer[question.conditional.when] ===
                question.conditional.is;
              showHr = result;
            }

            //Retorna HR
            if (showHr)
              return (
                <QuestionHr key={question.value || index} question={question} />
              );
          } else if (question.type === "select") {
            size = question.size ? +question.size : 4;
            if (question.conditional) {
              showSelect = false;
              if (question.conditional.or) {
                const result =
                  extra_answer[question.conditional.when] ===
                    question.conditional.is ||
                  extra_answer[question.conditional.when] ===
                    question.conditional.or;
                showSelect = result;
              } else {
                const result =
                  extra_answer[question.conditional.when] ===
                  question.conditional.is;
                showSelect = result;
              }
            }

            if (showSelect) {
              //Retorna Select
              return (
                <QuestionSelect
                  key={question.value || index}
                  question={question}
                  answer={extra_answer}
                  size={size}
                  handler={handleAnwswerExtraQuestions}
                />
              );
            }
          }
          return null;
        })}
    </>
  );
};

export default ExtraQuestionValidate;
