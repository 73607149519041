/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BsCalendar4,
  BsPersonCircle,
  BsJournalText,
  BsCurrencyDollar
} from "react-icons/bs";

const TopMenu = ({ handleStep, step }) => {
  const MENU_LIST = [
    {
      name: "Contact Info",
      icon: <BsPersonCircle />,
      step: 1
    },
    {
      name: "Basic Questions",
      icon: <BsJournalText />,
      step: 2
    },
    {
      name: "Add Event",
      icon: <BsCalendar4 />,
      step: 3
    },
    {
      name: "Bill info",
      icon: <BsCurrencyDollar />,
      step: 4
    }
  ];

  return (
    <div className="d-flex justify-content-around bgDark p-2 rounded">
      {MENU_LIST.map((item, index) => (
        <a
          key={index}
          className={`text-light my-2 btn d-flex align-items-center gap-2 ${
            step === item.step && "fw-bold border-bottom border-3"
          }`}
          onClick={() => {
            handleStep(item.step);
          }}
        >
          {item.icon}
          {item.name}
        </a>
      ))}
    </div>
  );
};

export default TopMenu;
