import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import useIntakes from "../../../hooks/useIntakes";
import NotesLists from "./NotesLists";
import { ModalNewNote } from "./ModalNewNote";

const NotesContainer = () => {
  const { notes } = useIntakes();
  const [note, setNote] = useState({});

  const [addNewNote, setAddNewNote] = useState(false);

  const handleFillNote = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const handleAddNewNote = () => {
    setAddNewNote(!addNewNote);
    setNote({});
  };

  return (
    <>
      <Row className="px-5">
        <Col>
          <p className="fs-5 fw-semibolds my-auto">Notes</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button className="btnDark px-5" onClick={handleAddNewNote}>
            Add note
          </Button>
          {/* <DropdownComponent listMenu={LIST_MENU} title="New note" /> */}
        </Col>
      </Row>
      {/* <Row>
        {addNote && <AddNewNote type={addNote} setAddNote={setAddNote} />}
      </Row> */}
      <ModalNewNote
        open={addNewNote}
        handleClose={handleAddNewNote}
        note={note}
        handleFillNote={handleFillNote}
      />
      <Row className="px-5 py-2">
        {notes?.length !== 0 &&
          notes.map(
            (note) =>
              note.attributes.active === null ||
              (note.attributes.active === true && (
                <NotesLists
                  key={note.id}
                  note={note}
                  //  setAddNote={setAddNote}
                />
              ))
          )}
      </Row>
    </>
  );
};

export default NotesContainer;
