import React from "react";
import { Button } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";

const HeaderSettings = ({
  secctionTitle,
  setSecctionTitle,
  setSecction,
  children
}) => {
  return (
    <>
      <div className="d-flex mt-5 px-5 justify-content-between">
        <p className="fw-bold fs-4 txtDark">{secctionTitle}</p>
        {secctionTitle !== "Settings" && (
          <Button
            className="btnDark px-5"
            onClick={() => {
              setSecctionTitle("Settings");
              setSecction("menu-settings");
            }}
          >
            <BsChevronLeft /> Back
          </Button>
        )}
      </div>
      {children}
    </>
  );
};

export default HeaderSettings;
