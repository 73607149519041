
const ReportCards = ({ children }) => {
  return (
    <div className="text-white rounded bgDark py-5 fw-bold text-center">
      {children}
    </div>
  );
};

export default ReportCards;
