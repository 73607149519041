import { Row } from "react-bootstrap";

const Soon = () => {
  return (
    <Row style={{ height: "90vh" }}>
      <div className="d-flex justify-content-center align-self-center">
        <h2>Soon...</h2>
      </div>
    </Row>
  );
};

export default Soon;
