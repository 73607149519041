import { Link, useLocation } from "react-router-dom";
import {
  BsCalendar4,
  BsClipboardCheck,
  BsListTask,
  BsFilePerson,
  BsGrid1X2,
  BsTextareaResize,
  BsTelephoneForward
} from "react-icons/bs";
import useStaff from "../hooks/useStaff";
import useIntakes from "../hooks/useIntakes";
import { blankIntake } from "../Extra/interfaces";

const MenuStaff = () => {
  const location = useLocation();
  const urlActual = location.pathname;
  const { menuOpen } = useStaff();
  const { setIntake } = useIntakes();

  const sizeIcon = 20;

  return (
    <>
      <Link
        className={`${
          urlActual === "/dashboard" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none  d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/dashboard"
      >
        <BsGrid1X2 size={sizeIcon - 2} /> {menuOpen && "Dashboard"}
      </Link>
      {/* <Link
        className={`${
          urlActual === "/leads" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/leads"
      >
        <BsCommand size={sizeIcon} /> {menuOpen && "Leads"}
      </Link> */}
      <Link
        className={`${
          urlActual === "/inbox" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/inbox"
        onClick={() => setIntake(blankIntake)}
      >
        <BsListTask size={sizeIcon} /> {menuOpen && "Inbox"}
      </Link>
      {/* <Link
        className={`${
          urlActual === "/cases" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/cases"
        onClick={() => setIntake(blankIntake)}
      >
        <BsBriefcase size={sizeIcon} /> {menuOpen && "Cases"}
      </Link> */}
      <Link
        className={`${
          urlActual === "/agenda" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/agenda"
      >
        <BsClipboardCheck size={sizeIcon} /> {menuOpen && "Agenda"}
      </Link>
      <Link
        className={`${
          urlActual === "/new-intake" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/new-intake"
      >
        <BsTextareaResize size={sizeIcon} /> {menuOpen && "New Intake"}
      </Link>
      <Link
        className={`${
          urlActual === "/dialer" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/dialer"
      >
        <BsTelephoneForward size={sizeIcon} /> {menuOpen && "Dialer"}
      </Link>
      <Link
        className={`${
          urlActual === "/contacts" ? "text-dark bg-light" : "text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/contacts"
      >
        <BsFilePerson size={sizeIcon} /> {menuOpen && "Contacts"}
      </Link>
      <Link
        className={`${
          urlActual === "/calendar" ? "text-dark bg-light" : " text-light"
        }  h-text-dark text-decoration-none d-block py-2 ${
          menuOpen ? "px-5" : "px-2"
        }`}
        to="/calendar"
      >
        <BsCalendar4 size={sizeIcon} /> {menuOpen && "Calendar"}
      </Link>
    </>
  );
};

export default MenuStaff;
