import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import useStaff from "../hooks/useStaff";
import CallList from "./CallList";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useIntakes from "../hooks/useIntakes";

const TwilioApp = ({
  callsList,
  pendingCallsQuantity,
  statusSelect,
  setStatusSelect
}) => {
  const { setStatus, staff } = useStaff();
  const { contactStatus } = useIntakes();

  const handleStatusCalls = (e) => {
    setStatusSelect(e.target.value);
  };

  useEffect(() => {
    setStatus(staff.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const statusOptions = ["Online", "Bussy", "Offline"];

  return (
    <>
      {/* <Row>
        <Col>
          <h6>My status is:</h6>
        </Col>
        <Col>
          <Form.Select
            size="sm"
            className={`${colorSelect}`}
            onChange={(e) => {
              setStatus(e.target.value);
              handleUpdateStatus(e.target.value);
              handleStatus(e.target.value);
            }}
            value={status}
          >
            {statusOptions.map((opt, index) => (
              <option key={index} value={opt}>
                {opt}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={2}>
          <BsCircleFill className={`${colorSelect}`} />
        </Col>
        <hr className="mt-3" />
      </Row> */}
      <Row className="mb-3">
        <Col>
          <p>Filter Call list by Status:</p>
        </Col>
        <Col>
          <FormControl fullWidth size="small">
            <InputLabel id="imput-to">Status</InputLabel>
            <Select
              labelId="imput-to"
              value={statusSelect || ""}
              name="status"
              label="Status"
              onChange={handleStatusCalls}
            >
              {contactStatus?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.attributes.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Next client to call:</h6>
        </Col>
        <Col className="d-flex justify-content-end">
          <h6>Calls quantity {pendingCallsQuantity}</h6>
        </Col>
      </Row>
      <Row>
        {callsList?.length > 0
          ? callsList.map((call) => <CallList call={call} key={call.id} />)
          : null}
      </Row>
    </>
  );
};

export default TwilioApp;
