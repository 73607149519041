import useStaff from "../hooks/useStaff";
import { useNavigate } from "react-router-dom";
import IntakeForm from "../components/intake/IntakeForm";
import { Button, Col, Row } from "react-bootstrap";
import CheckPermissions from "../components/CheckPermissions";

const NewIntake = () => {
  const { menuOpen } = useStaff();
  const navigate = useNavigate();

  return (
    <CheckPermissions page={"new-intake"}>
      <Row className={`${!menuOpen && "mx-5"}`}>
        <Col>
          <h2 className="txtDark mt-3" style={{ fontWeight: "700" }}>
            New Intake
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="dark"
            className="px-5 bgDark py-2 mt-3 mx-5"
            style={{ borderRadius: "15px" }}
            onClick={() => navigate("/import")}
          >
            Import
          </Button>
        </Col>
      </Row>
      <IntakeForm />
    </CheckPermissions>
  );
};

export default NewIntake;
