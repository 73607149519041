import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import useEvents from "../../hooks/useEvents";
import { createEvent } from "../../context/eventsFunctions/createEvent";
import { notification } from "../../Extra/notification";

export const IntakeFormStepTree = () => {
  const { staff } = useStaff();
  const { clientSelected, intake, handleGetIndividualIntake } = useIntakes();

  const {
    handleEvent,
    events,
    event,
    eventStates,
    setModalShowCalendar,
    loadEventData,
    handleRefreshEvent
  } = useEvents();

  const { categoryEvent } = eventStates;

  const { userInfo } = useStaff();
  const { title, start, end, time_start, time_end, description } = event;
  const { first_name, last_name } = clientSelected;
  const staffResult = JSON.parse(localStorage.getItem("staff"));

  const handleLoad = () => {
    if (events.length === 0) {
      loadEventData();
    }
  };

  const submit = () => {
    if (event.time_start === "" || event.start === "" || event.title === "") {
      alert("Please fill all fields");
      return;
    } else {
      const res = createEvent(event, staff, clientSelected, intake);
      if (res) {
        notification("success", "Event created succesfuly");
        handleRefreshEvent();
        handleGetIndividualIntake(intake.id);
      } else {
        notification("error", "Error creating event");
      }
    }
  };

  return (
    <div className="px-5">
      <Row className="mt-3">
        <Col
          style={{
            borderBottom: "none",
            backgroundColor: staffResult.background_color,
            color: staffResult.text_color
          }}
          className="d-flex justify-content-center rounded"
        >
          <h2 className="my-2">{`${title ? title : ""}`}</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Container>
          <Form>
            <Row>
              <Col md={2}>
                <h6 className="mt-2">Title: </h6>
              </Col>
              <Col>
                <Form.Control
                  placeholder="Write the title event"
                  value={title}
                  name="title"
                  onChange={(e) => handleEvent(e)}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Start at:</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control
                        type="date"
                        value={start}
                        name="start"
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        name="time_start"
                        value={time_start}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label as="h6">Ends at:</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Form.Control
                        name="end"
                        type="date"
                        value={end}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="time"
                        name="time_end"
                        value={time_end}
                        onChange={(e) => handleEvent(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Label as="h6">Select Category</Form.Label>
                <Form.Select
                  name="category"
                  onChange={(e) => {
                    handleEvent(e);
                  }}
                >
                  <option> Select Category</option>
                  {categoryEvent.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col>
                <Form.Label as="h6">Name:</Form.Label>
                <Form.Control disabled value={`${first_name} ${last_name}`} />
              </Col>
            </Row>
            <Row className="px-2 mt-3">
              <Form.Label as="h6">Description:</Form.Label>
              <Form.Control
                name="description"
                value={description}
                as="textarea"
                placeholder="Write some description"
                onChange={(e) => {
                  handleEvent(e);
                }}
              />
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Label as="h6">Assignee:</Form.Label>
                <Form.Select
                  name="assignee"
                  value={event.assignee}
                  onChange={(e) => {
                    handleEvent(e);
                  }}
                >
                  <option> Select an option </option>
                  {userInfo?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.full_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={6}>
                <Form.Label as="h6">Created by:</Form.Label>
                <Form.Control value={staffResult.full_name} disabled />
              </Col>
            </Row>
          </Form>
        </Container>
      </Row>
      <Row className="my-5">
        <div className="d-flex justify-content-end gap-3">
          <Button
            variant="dark"
            className="fw-bold px-5 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              setModalShowCalendar(true);
              handleLoad();
            }}
          >
            Open calendar
          </Button>
          <Button
            variant="dark"
            className="fw-bold px-5 bgDark align-self-center justify-content-end"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              submit();
            }}
          >
            Save
          </Button>
        </div>
      </Row>
    </div>
  );
};
