import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { blankExtraQuestionForm, configToast } from "../Extra/interfaces";
import { GetQuery, PostQuery } from "../Extra/Querys";
import useIntakes from "../hooks/useIntakes";
import useStaff from "../hooks/useStaff";

const BasicQuestionContext = createContext();

const BasicQuestionProvider = ({ children }) => {
  const { load } = useStaff();
  const { intake, intakeIndividualLoad, clientSelected, setClientSelected } =
    useIntakes();

  const [basicQuestions, setBasicQuestions] = useState({});
  const [basicQuestionList, setBasicQuestionList] = useState([]);
  const [basicQuestionNumberForm, setBasicQuestionNumberForm] = useState(1);
  const [basicQuestionForm, setBasicQuestionForm] = useState({});
  const [editingBQ, setEditingBQ] = useState(false);

  const [extraQuestionSelected, setExtraQuestionSelected] = useState();
  const [extraQuestionsList, setExtraQuestionsList] = useState([]);
  const [extraQuestionsForm, setExtraQuestionsForm] = useState(
    blankExtraQuestionForm
  );
  const [showExtraQuestions, setShowExtraQuestions] = useState(false);

  const handleExtraQuestions = () => {
    setShowExtraQuestions(!showExtraQuestions);
  };

  const handleAnwswerExtraQuestions = (e) => {
    setClientSelected({
      ...clientSelected,
      extra_answer: {
        ...clientSelected.extra_answer,
        [e.target.name]: e.target.value
      }
    });
  };

  const handleSelectEQForm = (e) => {
    const actualExtraQuestions = extraQuestionsList.filter(
      (question) => question.id === e.target.value
    );
    setClientSelected({
      ...clientSelected,
      extra_question: e.target.value
    });
    setExtraQuestionSelected(e.target.value);
    setExtraQuestionsForm(actualExtraQuestions[0]);
  };

  const getExtraQuestionsForm = async (e) => {
    const getData = await GetQuery(`/extra-questions`);
    const actualExtraQuestions = getData.data.filter(
      (question) => question.id === clientSelected.extra_question
    );
    setExtraQuestionSelected(clientSelected.extra_question);
    setExtraQuestionsList(getData.data);
    setExtraQuestionsForm(actualExtraQuestions[0]);
  };

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  //functions handle
  const handleBasicQuestion = (e) => {
    setBasicQuestions({ ...basicQuestions, [e.target.name]: e.target.value });
  };

  const handleSelectBQForm = (e) => {
    setBasicQuestionNumberForm(e.target.value);
  };

  const handleEditBasicQuestions = async () => {
    const data = { data: { answer: basicQuestions } };
    await PostQuery(
      `/basic-question-answers/${intake.basic_question_answer}`,
      "put",
      data
    );
    notification("success", "Changes saved successfully");
  };

  const handleSubmitBasicQuestions = async () => {
    const data = { data: { answer: basicQuestions } };
    const res = await PostQuery(`/basic-question-answers`, "post", data);

    handleSubmitBQIntake(res.data.data.id);
  };

  const handleSubmitBQIntake = async (id) => {
    const data = JSON.stringify({
      data: {
        basic_question_answer: id,
        contact_BQ_complete: true,
        basic_question: basicQuestionNumberForm
      }
    });
    await PostQuery(`/intakes/${intake.id}`, "put", data);
    notification("success", "Basic Questions saved successfully");
  };

  const getBasicQuestionForm = async () => {
    const getData = await GetQuery(
      `/basic-questions/${basicQuestionNumberForm}`
    );
    setBasicQuestionForm(getData.data);
  };

  const getBasicQuestionIds = async () => {
    const getData = await GetQuery(`/basic-questions`);
    setBasicQuestionList(getData.data);
  };

  const getAnswers = async () => {
    const getData = await GetQuery(
      `/basic-question-answers/${intake.basic_question_answer}`
    );
    setBasicQuestions(getData.data.attributes.answer);
  };

  useEffect(() => {
    if (load) {
      getBasicQuestionForm();
      getBasicQuestionIds();
      getExtraQuestionsForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicQuestionNumberForm, load]);

  useEffect(() => {
    if (intakeIndividualLoad && intake.contact_BQ_complete) {
      setBasicQuestionNumberForm(intake.basic_question);
      getAnswers();
      setEditingBQ(true);
    }

    if (!intake.contact_BQ_complete) {
      setBasicQuestions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intakeIndividualLoad, intake.contact_BQ_complete]);

  return (
    <BasicQuestionContext.Provider
      value={{
        basicQuestions,
        setBasicQuestions,
        basicQuestionList,
        basicQuestionForm,
        handleSelectBQForm,
        handleBasicQuestion,
        handleSubmitBasicQuestions,
        handleEditBasicQuestions,
        getAnswers,
        editingBQ,
        setBasicQuestionNumberForm,
        setEditingBQ,
        showExtraQuestions,
        handleExtraQuestions,
        extraQuestionSelected,
        handleAnwswerExtraQuestions,
        extraQuestionsForm,
        extraQuestionsList,
        handleSelectEQForm
      }}
    >
      {children}
    </BasicQuestionContext.Provider>
  );
};

export { BasicQuestionProvider };
export default BasicQuestionContext;
