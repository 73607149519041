export const fillSetup = (data) => {
  const { group, group_name, incoming_call_number } = data.attributes;
  const { id } = data;
  const phone_number = data.attributes.phone_number.data.attributes.number;
  return {
    id,
    group,
    group_name,
    phone_number,
    incoming_call_number
  };
};
