import axios from "axios";
import { toast } from "react-toastify";
import { configToast } from "./interfaces";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const notification = (type, message) => {
  if (type === "error") return toast.error(message, configToast);
  if (type === "success") return toast.success(message, configToast);
};

export const FirstGetQuery = async (url, token) => {
  const config = {
    method: "GET",
    url: `${backendUrl}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  };

  const { data } = await axios(config);
  return data;
};

export const GetQuery = async (url) => {
  const tokenResult = localStorage.getItem("tokenCase");
  try {
    const config = {
      method: "GET",
      url: `${backendUrl}${url}`,
      headers: {
        Authorization: `Bearer ${tokenResult}`,
        "Content-Type": "application/json"
      }
    };

    const { data } = await axios(config);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const PostQuery = async (url, method, data) => {
  const tokenResult = localStorage.getItem("tokenCase");

  const config = {
    method: method,
    url: `${backendUrl}${url}`,
    headers: {
      Authorization: `Bearer ${tokenResult}`,
      "Content-Type": "application/json"
    },
    data
  };
  // let datatosend;
  try {
    const res = await axios(config);
    console.log(res);

    return res;
  } catch (error) {
    // datatosend = JSON.stringify({
    //   data: {
    //     title: error.response.data
    //       ? error.response.data.error.message
    //       : "No error title",
    //     cause: JSON.parse(
    //       error.config.data ? error.config.data : "No error detected"
    //     ),
    //     complete_log: { response: error.response, config: error.config }
    //   }
    // });
    // await axios.post(`${backendUrl}/error-logs`, datatosend, {
    //   headers: {
    //     Authorization: `Bearer ${tokenResult}`,
    //     "Content-Type": "application/json"
    //   }

    console.log(error);
    notification("error", error.response.data.error.message);
  }
};
