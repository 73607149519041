import ChartsStatus from "../charts/ChartsStatus";
import ReportDateSelect from "./ReportDateSelect";
import ReportHeader from "./ReportHeader";

const ReportIntakesByStatuses = () => {
  return (
    <>
      <ReportHeader>Charts Intake by Statuses</ReportHeader>
      <ReportDateSelect />
      <ChartsStatus />
    </>
  );
};

export default ReportIntakesByStatuses;
