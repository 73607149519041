import { Col } from "react-bootstrap";

const QuestionHr = ({ question }) => {
  return (
    <Col md={12} className="mb-3">
      <hr />
    </Col>
  );
};

export default QuestionHr;
