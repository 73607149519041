import { Button, Modal } from "react-bootstrap";
import { BsFillTelephoneXFill } from "react-icons/bs";
import useSoftphone from "../hooks/useSoftphone";

const ModalIncomingCall = ({ incominModal, handleClose, number }) => {
  const {
        // callJsSIP,
        answerJsSIP,
        // register,
        // activo,
        // setIncominModal,
        // callIncoming,
        terminateJsSIP
  } = useSoftphone();
 
  return (
    <Modal show={incominModal} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Incoming Call {number}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          className="btn-danger"
          onClick={() => {
            terminateJsSIP();
            handleClose();
            // handleEndIncoming();
          }}
        >
          <BsFillTelephoneXFill size={18} />
        </Button>
        <Button
          className="btnDark px-5"
          onClick={() => {
            answerJsSIP();
            handleClose();
            // actionCall(callIncoming.CallSid, "answer");
            // setCallIncoming({ ...callIncoming, Conected: true });
            // handleCallRespond();
          }}
        >
          Answer call
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalIncomingCall;
