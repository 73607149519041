import { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FiArrowUpRight } from "react-icons/fi";
import axios from "axios";
import ReportHeader from "./ReportHeader";
import {  formatMyTime } from "../../Extra";

const ReportSms = () => {
  const twilioUrl = process.env.REACT_APP_CALLCENTER_URL;
  const [smsMadee, setSmsMadee] = useState([]);
  const [pageState, setPageState] = useState({
    pageSize: 25,
    total: 0,
    page: 1,
    isLoadign: true
  });
  const [queryOptions, setQueryOptions] = useState({});
  const firstUpdate = useRef(true);

  const getSms = async () => {
    const getSmsMade = await axios(
      `${twilioUrl}/sms?page=${pageState.page}&pageSize=${pageState.pageSize}`
    );
    setSmsMadee(getSmsMade.data.allSMSList);

    setPageState((prev) => ({
      ...prev,
      total: getSmsMade.data.total,
      isLoadign: false
    }));
  };

  const searchData = async () => {
    const field = queryOptions.filterModel.items[0].columnField
      ? queryOptions.filterModel.items[0].columnField
      : "";
    const fieldValue = queryOptions.filterModel.items[0].value
      ? queryOptions.filterModel.items[0].value
      : "";


    setPageState((prev) => ({
      ...prev,
      isLoadign: true
    }));

    const getFiltersCalls = await axios(
      `${twilioUrl}/sms/search?searchField=${field}&searchValue=${fieldValue}`
    );

    setSmsMadee(getFiltersCalls.data.allSMSList);

    setPageState((prev) => ({
      pageSize: 25,
      total: 0,
      page: 1,
      isLoadign: false
    }));
  };

  useEffect(() => {
    getSms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue === ""
    ) {
      getSms();
      return;
    }

    if (
      queryOptions.filterModel &&
      queryOptions.filterModel.items[0]?.fieldValue !== ""
    ) {
      searchData();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptions]);

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const columns = [
    {
      headerName: "Intake",
      renderCell: (cellValues) => {
        return (
          <a
            href={cellValues.value}
            target="_blank"
            rel="noreferrer"
            className="bgDark text-white d-flex justify-content-center align-items-center m-auto"
            style={{ borderRadius: "50%", padding: "8px" }}
            onClick={() => {}}
          >
            <FiArrowUpRight />
          </a>
        );
      },
      field: "intakeId",
      width: 80
    },
    {
      headerName: "Agent",
      field: "Name",
      minWidth: 120,
      flex: 1
    },

    {
      headerName: "Client",
      field: "clientName",
      width: 130,
      flex: 1
    },
    {
      headerName: "Phone",
      field: "To",
      width: 130,
      flex: 1
    },
    {
      headerName: "Message",
      field: "Message",
      width: 130,
      flex: 2
    },
    {
      headerName: "Caller ID",
      field: "From",
      minWidth: 120,
      flex: 1
    },
   
    {
      headerName: "Date",
      field: "createdAt",
      renderCell: (cellValues) => {
        return formatMyTime(cellValues.value);
      },
      width: 100,
      flex: 1
    },
    {
      headerName: "Type",
      field: "_type",
      width: 120,
      flex: 1
    }
  ];
  return (
    <>
      <ReportHeader>Report calls</ReportHeader>
      <div className="px-5 mt-3">
        <DataGrid
          autoHeight
          loading={pageState.isLoadign}
          rows={smsMadee}
          rowCount={pageState.total}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          getRowId={(row) => row._id}
          sortModel={[{ field: "createdAt", sort: "desc" }]}
          paginationMode="server"
          pageSize={pageState.pageSize}
          page={pageState.page}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onPageChange={(newPage) =>
            setPageState((prev) => ({ ...prev, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((prev) => ({ ...prev, pageSize: newPageSize }))
          }
        />
      </div>
    </>
  );
};

export default ReportSms;
