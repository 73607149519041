import { FirstGetQuery } from "../../Extra/Querys";
import { fillSetup } from "../../Extra/fillSetupData";

export const getFirmData = async (gorup, token) => {
  const res = await FirstGetQuery(
    `/setups?filters[group][$eq]=${gorup}&populate=*`,
    token
  );

  return fillSetup(res.data[0]);
};
