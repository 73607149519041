import React from "react";
import ChartsSource from "../charts/ChartsSource";
import ReportDateSelect from "./ReportDateSelect";
import ReportHeader from "./ReportHeader";

const ReportIntakeBySource = () => {
  return (
    <>
      <ReportHeader>Charts Intake by Source</ReportHeader>
      <ReportDateSelect />
      <ChartsSource />
    </>
  );
};

export default ReportIntakeBySource;
