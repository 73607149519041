import { Button, Modal } from "react-bootstrap";
import useEvents from "../../hooks/useEvents";
import Loadign from "../Loadign";
import CalendarComp from "./CalendarComp";

const ModalShowCalendar = () => {
  const { modalShowCalendar, setModalShowCalendar, events, readyToRender } =
    useEvents();

  const onHide = () => {
    setModalShowCalendar(false);
  };

  return (
    <Modal
      show={modalShowCalendar}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      {readyToRender ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Calendar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CalendarComp events={events} />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btnDark px-5" onClick={onHide}>
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Loadign />
      )}
    </Modal>
  );
};

export default ModalShowCalendar;
