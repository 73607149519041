import { useState } from "react";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import { intakeImportFields } from "./intakeImportFields";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const IntaketConfiguration = ({ setAvalibleLoadFile }) => {
  const {
    typeOfContact,
    typeOfCase,
    contactStatus,
    source,
    handleAddtoIntake,
    intakeImport,
    handleIntakeImport
  } = useIntakes();

  const { userInfo } = useStaff();
  const [noCalls, setNoCalls] = useState(true);
  const [show, setShow] = useState(false);

  const INTAKE_CONFIGURATION_FIELDS = intakeImportFields({
    typeOfContact,
    typeOfCase,
    status: contactStatus,
    source,
    userInfo
  });

  const handleValidation = () => {
    if (
      intakeImport.type_of_contact === "" ||
      intakeImport.type_of_case === "" ||
      intakeImport.status === "" ||
      intakeImport.source === "" ||
      intakeImport.assignee === "" ||
      intakeImport.owner === ""
    ) {
      setShow(true);
      return;
    } else {
      setAvalibleLoadFile(false);
    }
  };

  if (show) {
    return (
      <Alert
        variant="danger"
        onClose={() => setShow(false)}
        dismissible
        className="px-5 m-5"
      >
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          We need to be sure that you fill in all the fields, please close this
          window and continue...
        </p>
      </Alert>
    );
  }

  return (
    <>
      <Form className=" px-5 ">
        <Row className="mt-4">
          {INTAKE_CONFIGURATION_FIELDS.map((field) => {
            return (
              <Col sm={12} md={4} lg={3} className="mt-3" key={field.name}>
                {console.log(field.items)}
                <FormControl fullWidth className="mt-lg-0" size="small">
                  <InputLabel id="imput-to">{field.label}</InputLabel>
                  <Select
                    labelId="imput-to"
                    value={intakeImport[field.name] || ""}
                    name={field.name}
                    label={field.label}
                    onChange={handleIntakeImport}
                    size="small"
                  >
                    {field.items.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.full_name || type.name || type.attributes.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            );
          })}
        </Row>
        <br />
        <Row>
          <Col>
            <FormLabel>Require Call?</FormLabel>
            <div className="mb-3">
              <Form.Check
                inline
                label="Yes"
                type="radio"
                value={true}
                name="pending_call"
                onChange={(e) => {
                  handleIntakeImport(e);
                  setNoCalls(true);
                }}
              />
              <Form.Check
                inline
                label="No"
                value={false}
                type="radio"
                name="pending_call"
                onChange={(e) => {
                  handleAddtoIntake(e);
                  setNoCalls(false);
                }}
              />
            </div>
            {!noCalls ? (
              <Alert variant="danger">You make no calls, ok!</Alert>
            ) : null}
          </Col>
          <Col>
            <FormLabel>
              Please confirm intake options to activate upload
            </FormLabel>

            <Button
              variant="dark"
              className="fw-bold px-3 bgDark justify-content-end mb-3"
              style={{ borderRadius: "15px" }}
              onClick={() => handleValidation()}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default IntaketConfiguration;
