import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { blankStatus, configToast } from "../../Extra/interfaces";
import { PostQuery } from "../../Extra/Querys";
import useIntakes from "../../hooks/useIntakes";
import useStaff from "../../hooks/useStaff";
import ModalEditStatus from "./ModalEditStatus";

const Statuses = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { staff } = useStaff();
  const { contactStatus } = useIntakes();
  const [openModalStatus, setOpenModalStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState(blankStatus);

  const handleSetStatus = (id) => {
    const type = contactStatus.filter((status) => status.id === id);
    const typeId = type[0].id;
    const { status, color, backgroundColor, message, activated, notification } =
      type[0].attributes;
    setSelectedStatus({
      id: typeId,
      status,
      color,
      backgroundColor,
      message,
      activated,
      notification
    });
  };

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  const closeAndNotification = (status) => {
    if (status === 200) {
      notification("success", "Saved successfully");
      setOpenModalStatus(false);
    } else {
      notification("error", "Ups... something went wrong");
    }
  };

  const handleSubmitStatus = async () => {
    const url = `${backendURL}/statuses`;
    if (selectedStatus.id === 0) {
      //nuevo
      const {
        status,
        color,
        backgroundColor,
        message,
        activated,
        notification
      } = selectedStatus;
      const dataToSend = {
        data: {
          status,
          color,
          backgroundColor,
          message,
          activated,
          notification,
          group: staff.group
        }
      };
      const res = await PostQuery(url, "POST", dataToSend);
      closeAndNotification(res.status);
    } else {
      //editado
      const dataToSend = { data: { ...selectedStatus } };
      const res = await PostQuery(
        `${url}/${selectedStatus.id}`,
        "PUT",
        dataToSend
      );

      closeAndNotification(res.status);
    }
  };

  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (parame) => {
        return parame.row.attributes.status;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Message",
      field: "message",
      valueGetter: (parame) => {
        return parame.row.attributes.message;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Colors",
      field: "color",
      renderCell: (parame) => {
        return (
          <p
            style={{
              color: parame.row.attributes.color,
              backgroundColor: parame.row.attributes.backgroundColor
            }}
          >
            {parame.row.attributes.color}
          </p>
        );
      },
      minWidth: 100,
      maxWidth: 120
    },
    {
      headerName: "Notification",
      field: "notification",
      valueGetter: (parame) => {
        return parame.row.attributes.notification === true ? "Yes" : "No";
      },
      minWidth: 80,
      maxWidth: 100
    },
    {
      headerName: "Group",
      field: "group",
      valueGetter: (parame) => {
        return parame.row.attributes.group;
      },
      minWidth: 70,
      maxWidth: 90
    },
    {
      headerName: "Edit",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {
              handleSetStatus(cellValues.row.id);
              setOpenModalStatus(true);
            }}
          >
            Edit
          </Button>
        );
      },
      minWidth: 180,
      maxWidth: 250
    }
  ];
  return (
    <Row className="px-5 mt-4">
      <Button
        className="my-3 btnDark"
        onClick={() => {
          setSelectedStatus(blankStatus);
          setOpenModalStatus(true);
        }}
      >
        Add new type of case
      </Button>
      <DataGrid
        autoHeight
        rows={contactStatus}
        columns={columns}
        sortModel={sortModel}
      />
      <ModalEditStatus
        openModalStatus={openModalStatus}
        setOpenModalStatus={setOpenModalStatus}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleSubmitStatus={handleSubmitStatus}
      />
    </Row>
  );
};

export default Statuses;
