import { Image, Row } from "react-bootstrap";

const ErrorPermissions = () => {
  return (
    <Row style={{ height: "90vh" }}>
      <div className="d-flex flex-column m-auto justify-content-center align-self-center">
        <Image className="m-auto" src="/img/logo.png" alt="logo" width={200} />
        <p className="m-auto fw-bold">
          Sorry you dont have permission to see this page.
        </p>
        <p className="m-auto">
          Please contact your administrator to granted access.
        </p>
      </div>
    </Row>
  );
};

export default ErrorPermissions;
