import { GetQuery } from "../../Extra/Querys";
import { fillDataArray } from "../../Extra/fillDataArray";
const CryptoJS = require("crypto-js");

export const loadCategoryNotes = async () => {
  const key = process.env.REACT_APP_Cjs;

  const statusNotesResult = localStorage.getItem("categoryNotes");

  if (statusNotesResult === null || statusNotesResult === undefined) {
    const { data } = await GetQuery(`/category-notes?sort=id:asc`);

    const dataToEncrypt = { data };

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(dataToEncrypt),
      key
    ).toString();
    localStorage.setItem("categoryNotes", encryptedData);

    return fillDataArray(data);
  } else {
    const bytes = CryptoJS.AES.decrypt(statusNotesResult, key);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return fillDataArray(decryptedData.data);
  }
};
