import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckPermissions from "../components/CheckPermissions";
import ReportCards from "../components/reports/ReportCards";

const Reports = () => {
  const REPORT_SECTION = [
    {
      title: "Recording Calls",
      path: "/reports/calls",
    },
    {
      title: "Recording Calls by User",
      path: "/reports/callsbyuser",
    },
    {
      title: "List SMS",
      path: "/reports/sms",
    },
    {
      title: "Charts Calls Made",
      path: "/reports/calls-made",
    },
    {
      title: "Charts Calls Pending",
      path: "/reports/calls-pending",
    },
    {
      title: "Charts Intake by Status",
      path: "/reports/intake-by-status",
    },
    {
      title: "Charts Intake by Type of Case",
      path: "/reports/intake-by-type-of-case",
    },
    {
      title: "Charts Intake by Source",
      path: "/reports/intake-by-source",
    },
    {
      title: "Charts Intake by Assignee",
      path: "/reports/intake-by-assignee",
    },
  ];

  return (
    <CheckPermissions page={"reports"}>
      <Row className="w-100 px-3">
        {REPORT_SECTION.map((report, index) => (
          <Col className="m-auto  mt-5 " md={5} lg={4} sm={6} key={index}>
            <Link to={report.path}>
              <ReportCards>{report.title}</ReportCards>
            </Link>
          </Col>
        ))}
      </Row>
    </CheckPermissions>
  );
};

export default Reports;
