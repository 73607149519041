import { Outlet } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import MenuStaff from "./MenuStaff";
import Header from "./Header";
import useStaff from "../hooks/useStaff";
import MenuExtraAdmin from "./MenuExtraAdmin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import ModalShowCalendar from "../components/calendar/ModalShowCalendar";
// import useTwilio from "../hooks/useTwilio";
// import ModalIncomingCall from "../components/ModalIncomingCall";
// import IncomingCallInProgress from "../components/IncomingCallInProgress";
import useSoftphone from "../hooks/useSoftphone";
import ModalIncomingCall from "../components/ModalIncomingCall";
// import IncomingCallInProgress from "../components/IncomingCallInProgress";

const Layout = () => {
	const { staff, menuOpen, setMenuOpen } = useStaff();
	// const { callIncoming, incominModal, setIncominModal } = useSoftphone();
		const {  incominModal, setIncominModal } = useSoftphone();

	const [width, setWidth] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};
	window.addEventListener("resize", handleWindowSizeChange);

	const handleClose = () => {
		setIncominModal(false);
	};

	useEffect(() => {
		if (width < 1199) {
			setMenuOpen(false);
		}
		if (width > 1200) {
			setMenuOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width]);

	// useEffect(() => {
	//   console.log("Layout ", callIncoming);
	//   if (
	//     callIncoming.CallStatus === "in-progress" &&
	//     callIncoming.Conected === false
	//   ) {
	//     setIncominModal(true);
	//   } else {
	//     setIncominModal(false);
	//   }
	// }, [callIncoming]);

	return (
		<Row className="mx-0 px-0">
			{menuOpen && (
				<Col
					style={{ backgroundColor: "#183440", height: "100vh" }}
					md={4}
					lg={3}
					xl={3}
					xxl={2}
					className="flex justify-content-center p-0 pt-4 text-light overflow-hidden position-fixed "
				>
					<Image
						className="rounded mx-auto d-block mb-5"
						src={
							menuOpen ? "/img/logo-horizontal.png" : "/img/LogoMini_Case.png"
						}
						width={menuOpen ? 200 : 50}
						// style={{ width: menuOpen ? "50px" : "200px", height: "58px" }}
					/>
					<p className="text-center m-0 ">
						Hello, <span className="fw-bold">{staff.full_name}</span>
					</p>
					{menuOpen && <p className="text-center m-0 mb-5">Welcome to CASE</p>}
					{staff.rol === "sales" ? <MenuStaff /> : null}
					{staff.rol === "admin" ? <MenuStaff /> : null}
					<MenuExtraAdmin />
				</Col>
			)}
			<Col
				md={menuOpen ? 8 : 12}
				lg={menuOpen ? 9 : 12}
				xl={menuOpen ? 9 : 12}
				xxl={menuOpen ? 10 : 12}
				className={`${
					!menuOpen && " h-100 px-0 mx-0"
				}  offset-xs-0 offset-md-4 offset-lg-3  offset-xxl-2 ml-5   `}
			>
				<Header />
				{/* <IncomingCallInProgress /> */}
				<Outlet />
				<ToastContainer />
				<ModalShowCalendar />
				<ModalIncomingCall
					incominModal={incominModal}
					handleClose={handleClose}
					number={"callIncoming.Caller"}
				/>
			</Col>
		</Row>
	);
};

export default Layout;
