import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  BsCloudDownload,
  BsCloudUpload,
  BsFolderSymlink
} from "react-icons/bs";
import { formatMyTime } from "../../../Extra";
import { getIcon } from "../../../Extra/returnIconDocument";
import useDocuments from "../../../hooks/useDocuments";

const CardDocument = ({ doc, setShow }) => {
  const { getFoldersInsideIntake, setFolderPath, setIdFolder } = useDocuments();
  const { name } = doc;
  const { createdDateTime, lastModifiedDateTime } = doc.fileSystemInfo;
  var downloadLink = doc["@microsoft.graph.downloadUrl"];
  const ext = `.${name.split(".").pop()}`;
  const conditionalExt = doc.folder ? "Folder" : ext;
  const childCount = doc.folder ? doc.folder.childCount : null;
  const id = doc.id;

  return (
    <Row
      className="mt-3 py-2 rounded d-flex justify-content-between"
      style={{ backgroundColor: "#EDEDED" }}
    >
      <Col sm={1} className="m-auto p-0">
        {doc.folder ? (
          <a
            type="button"
            className="m-auto d-flex justify-content-center dlIcon"
            href={downloadLink}
            onClick={(e) => {
              getFoldersInsideIntake(id);
              setIdFolder(id);
            }}
          >
            {getIcon(conditionalExt)}
          </a>
        ) : (
          <a
            type="button"
            className="m-auto d-flex justify-content-center dlIcon"
            href={downloadLink}
          >
            {getIcon(conditionalExt)}
          </a>
        )}
      </Col>
      <Col className="m-auto p-0" sm={8}>
        <p className="m-0 fw-semibold">{name}</p>
        <p className="m-0" style={{ fontSize: "12px" }}>
          {childCount ? `Files: ${childCount}` : `Format: ${ext}`}
        </p>
        <p className="m-0" style={{ fontSize: "12px" }}>
          Upload: {formatMyTime(createdDateTime)}
        </p>
        <p className="m-0" style={{ fontSize: "12px" }}>
          {createdDateTime !== lastModifiedDateTime &&
            `Last modified: ${formatMyTime(lastModifiedDateTime)}`}
        </p>
      </Col>
      <Col sm={2} className="d-flex flex-column justify-content-start p-0">
        {doc.folder ? (
          <Col className="m-auto mt-3 dlIcon">
            <BsFolderSymlink
              size={20}
              onClick={(e) => {
                getFoldersInsideIntake(id);
                setIdFolder(id);
                setFolderPath(name);
              }}
            />
          </Col>
        ) : (
          <>
            <OverlayTrigger overlay={<Tooltip>Download document</Tooltip>}>
              <a
                href={downloadLink}
                className="m-auto dlIcon d-flex justify-content-center p-0 "
              >
                <BsCloudDownload size={20} />
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Upload edited document</Tooltip>}
            >
              <div
                className="m-auto ulIcon d-flex justify-content-center p-0"
                onClick={() => setShow(true)}
              >
                <BsCloudUpload size={20} />
              </div>
            </OverlayTrigger>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CardDocument;
