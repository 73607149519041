export const fillClient = (intake) => {
  const client = {
    id: intake.attributes.contact.data.id,
    first_name: intake.attributes.contact.data.attributes.first_name,
    last_name: intake.attributes.contact.data.attributes.last_name,
    primary_phone: intake.attributes.contact.data.attributes.primary_phone,
    alias: intake.attributes.contact.data.attributes.alias
      ? intake.attributes.contact.data.attributes.alias
      : null,
    company_name: intake.attributes.contact.data.attributes.company_name
      ? intake.attributes.contact.data.attributes.company_name
      : null,
    contact_preference: intake.attributes.contact.data.attributes
      .contact_preference
      ? intake.attributes.contact.data.attributes.contact_preference
      : null,
    createdAt: intake.attributes.contact.data.attributes.createdAt,
    date_of_birth: intake.attributes.contact.data.attributes.date_of_birth
      ? intake.attributes.contact.data.attributes.date_of_birth
      : null,
    date_of_death: intake.attributes.contact.data.attributes.date_of_death
      ? intake.attributes.contact.data.attributes.date_of_death
      : null,
    email: intake.attributes.contact.data.attributes.email
      ? intake.attributes.contact.data.attributes.email
      : null,
    sex: intake.attributes.contact.data.attributes.sex
      ? intake.attributes.contact.data.attributes.sex
      : null,
    group: intake.attributes.contact.data.attributes.group
      ? intake.attributes.contact.data.attributes.group
      : null,
    home_phone: intake.attributes.contact.data.attributes.home_phone
      ? intake.attributes.contact.data.attributes.home_phone
      : null,
    job_title: intake.attributes.contact.data.attributes.job_title
      ? intake.attributes.contact.data.attributes.job_title
      : null,
    language: intake.attributes.contact.data.attributes.language
      ? intake.attributes.contact.data.attributes.language
      : null,
    marital_status: intake.attributes.contact.data.attributes.marital_status
      ? intake.attributes.contact.data.attributes.marital_status
      : null,
    nationality: intake.attributes.contact.data.attributes.nationality
      ? intake.attributes.contact.data.attributes.nationality
      : null,
    secondary_email: intake.attributes.contact.data.attributes.secondary_email
      ? intake.attributes.contact.data.attributes.secondary_email
      : null,
    ssn: intake.attributes.contact.data.attributes.ssn
      ? intake.attributes.contact.data.attributes.ssn
      : null,
    suffix: intake.attributes.contact.data.attributes.suffix
      ? intake.attributes.contact.data.attributes.suffix
      : null,
    work_phone: intake.attributes.contact.data.attributes.work_phone
      ? intake.attributes.contact.data.attributes.work_phone
      : null,
    address: intake.attributes.contact.data.attributes.address
      ? intake.attributes.contact.data.attributes.address
      : null,
    city: intake.attributes.contact.data.attributes.city
      ? intake.attributes.contact.data.attributes.city
      : null,
    state: intake.attributes.contact.data.attributes.state
      ? intake.attributes.contact.data.attributes.state
      : null,
    zip: intake.attributes.contact.data.attributes.zip
      ? intake.attributes.contact.data.attributes.zip
      : null,
    extra_answer: intake.attributes.contact.data.attributes.extra_answer
      ? intake.attributes.contact.data.attributes.extra_answer
      : {},
    extra_question: intake.attributes.contact.data.attributes.extra_question
      ? intake.attributes.contact.data.attributes.extra_question
      : 1,
    citizen_status: intake.attributes.contact.data.attributes.citizen_status
      ? intake.attributes.contact.data.attributes.citizen_status
      : null,
    a_number: intake.attributes.contact.data.attributes.a_number
      ? intake.attributes.contact.data.attributes.a_number
      : null,
    country_birth: intake.attributes.contact.data.attributes.country_birth
      ? intake.attributes.contact.data.attributes.country_birth
      : null,
    city_birth: intake.attributes.contact.data.attributes.city_birth
      ? intake.attributes.contact.data.attributes.city_birth
      : null,
    country_address: intake.attributes.contact.data.attributes.country_address
      ? intake.attributes.contact.data.attributes.country_address
      : null,
    type_address: intake.attributes.contact.data.attributes.type_address
      ? intake.attributes.contact.data.attributes.type_address
      : null,
    type_number: intake.attributes.contact.data.attributes.type_number
      ? intake.attributes.contact.data.attributes.type_number
      : null,
    province: intake.attributes.contact.data.attributes.province
      ? intake.attributes.contact.data.attributes.province
      : null,
    postal_code: intake.attributes.contact.data.attributes.postal_code
      ? intake.attributes.contact.data.attributes.postal_code
      : null,
    state_birth: intake.attributes.contact.data.attributes.state_birth
      ? intake.attributes.contact.data.attributes.state_birth
      : null
  };

  return client;
};
