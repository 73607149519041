import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import ModalShowEvent from "./ModalShowEvent";
import ModalAddEvent from "./ModalAddEvent";
import useEvents from "../../hooks/useEvents";

const CalendarComp = ({ events }) => {
  const [eventShow, setEventShow] = useState(false);
  const [addEventShow, setAddEventShow] = useState(false);
  const [dataEvent, setDataEvent] = useState({
    id: 0,
    title: "",
    extendedProps: {
      assignee: "Andres Cuellar",
      createdBy: "Juan Cuellar"
    }
  });

  const { loadEvent } = useEvents();

  const [newEventState, setNeweventState] = useState({
    title: "",
    allFavs: [],
    favObj: {},
    start: "",
    end: "",
    filledIn: false,
    practiceTimes: []
  });

  const handleSetNewEvent = (e) => {
    setNeweventState({ ...newEventState, [e.target.name]: e.target.value });
  };
  const handleDateClick = (values) => {
    setDataEvent(values.event);
    // console.log(values.event);
    loadEvent(values.event.id);
    setEventShow(true);
  };

  const createEvent = (e) => {
    e.preventDefault();
    setNeweventState({
      allFavs: [],
      favObj: {},
      start: "",
      end: "",
      filledIn: false,
      practiceTimes: []
    });
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay"
        }}
        editable={true}
        selectable={true}
        eventClick={handleDateClick}
        aspectRatio={6}
        height={800}
        events={events}
      />

      {newEventState.filledIn === true ? (
        <div>
          <h4>Date & Time</h4>
          <h4>Select assignee</h4>
          <button onClick={(e) => createEvent(e)}> Submit</button>
        </div>
      ) : null}

      <ModalShowEvent
        show={eventShow}
        dataEvent={dataEvent}
        onHide={() => setEventShow(false)}
      />
      <ModalAddEvent
        newEventState={newEventState}
        show={addEventShow}
        onHide={() => setAddEventShow(false)}
        handleSetNewEvent={handleSetNewEvent}
      />
    </>
  );
};

export default CalendarComp;
