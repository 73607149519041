import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { blankTypeOfCase } from "../../Extra/interfaces";
import useIntakes from "../../hooks/useIntakes";
import ModalEditTypeOfCase from "./ModalEditTypeOfCase";

const CaseTypes = () => {
  const { typeOfCase } = useIntakes();
  const [openModalTypeOdCase, setOpenModalTypeOdCase] = useState(false);
  const [selectedTypeOfCase, setSelectedTypeOfCase] = useState(blankTypeOfCase);

  const handleSetTypeOfCase = (id) => {
    const type = typeOfCase.filter((typeofcase) => typeofcase.id === id);
    const typeId = type[0].id;
    const type_of_case_name = type[0].attributes.type_of_case_name;
    setSelectedTypeOfCase({ id: typeId, type_of_case_name });
  };

  const handleEditTypeOfCase = (e) => {
    setSelectedTypeOfCase({
      ...selectedTypeOfCase,
      [e.target.name]: e.target.value
    });
  };

  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      headerName: "Type of case",
      field: "type_of_case_name",
      valueGetter: (parame) => {
        return parame.row.attributes.type_of_case_name;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Group",
      field: "group",
      valueGetter: (parame) => {
        return parame.row.attributes.group;
      },
      minWidth: 70,
      maxWidth: 90
    },
    {
      headerName: "Edit",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {
              handleSetTypeOfCase(cellValues.row.id);
              setOpenModalTypeOdCase(true);
            }}
          >
            Edit
          </Button>
        );
      },
      minWidth: 180,
      maxWidth: 250
    }
  ];
  return (
    <Row className="px-5 mt-4">
      <Button
        className="my-3 btnDark"
        onClick={() => {
          setSelectedTypeOfCase(blankTypeOfCase);
          setOpenModalTypeOdCase(true);
        }}
      >
        Add new type of case
      </Button>
      <DataGrid
        autoHeight
        rows={typeOfCase}
        columns={columns}
        sortModel={sortModel}
      />
      <ModalEditTypeOfCase
        openModalTypeOdCase={openModalTypeOdCase}
        setOpenModalTypeOdCase={setOpenModalTypeOdCase}
        selectedTypeOfCase={selectedTypeOfCase}
        handleEditTypeOfCase={handleEditTypeOfCase}
      />
    </Row>
  );
};

export default CaseTypes;
