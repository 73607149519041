import { Navigate, Outlet } from "react-router-dom";
import useStaff from "../hooks/useStaff";
import LoadignInitial from "../components/LoadignInitial";

const ProtectedRoutes = ({ isAllowed, children, redirecTo = "/login" }) => {
  const { load, staff } = useStaff();

  if (isAllowed === false) {
    return <Navigate to={redirecTo} />;
  }

  if (staff.status_account === "invalid") {
    return <Navigate to="/contact-support" />;
  }

  return load ? children ? children : <Outlet /> : <LoadignInitial />;
};

export default ProtectedRoutes;
