import { createContext, useState } from "react";
import { numberBlank } from "../Extra/interfaces";
import { GetQuery } from "../Extra/Querys";
import useStaff from "../hooks/useStaff";
import { getIntakesQuery } from "./dashboardFunctions/getIntakesQuery";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const { staff } = useStaff();
  const [intakeForDash, setIntakeForDash] = useState([]);
  const [totalIntakes, setTotalIntakes] = useState(0);
  const [phoneNumberList, setPhoneNumbersList] = useState(numberBlank);
  const [loaded, setLoaded] = useState(true);

  const getIntakes = async (startDate, finalDate) => {
    setLoaded(false);

    const query = getIntakesQuery(staff, startDate, finalDate);
    const res = await GetQuery(`/intakes?${query}`);

    setIntakeForDash(res.data);
    setTotalIntakes(res.meta.total);
    setLoaded(true);
  };

  const getPhoneNumbers = async () => {
    const res = await GetQuery(
      `/phone-numbers?filters[group][$eq]=${staff.group}`
    );
    setPhoneNumbersList(res.data);
  };

  return (
    <DashboardContext.Provider
      value={{
        intakeForDash,
        totalIntakes,
        loaded,
        getIntakes,
        getPhoneNumbers,
        phoneNumberList,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardProvider };
export default DashboardContext;
