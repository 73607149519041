import { PostQuery } from "../../Extra/Querys";
import { notification } from "../../Extra/notification";

export const createEvent = async (event, staff, clientSelected, intake) => {
  const updatedData = {
    ...event, // Copia todas las propiedades del objeto original
    time_start: event.time_start + ":00.00",
    time_end: event.time_end + ":00.00",
    owner: staff.id,
    group: staff.group,
    contact: clientSelected.id,
    intake: intake.id
  };
  const dataToSend = { data: updatedData };

  try {
    const res = await PostQuery(`/events`, "post", dataToSend);
    console.log(res);
    notification("success", "Event created succesfuly");
    return true;
  } catch (error) {
    notification("error", "Error creating event");
    return false;
  }
};
