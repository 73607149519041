import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const DropdownComponent = ({ listMenu, title }) => {
  return (
    <DropdownButton
      title={title}
      variant="outline-success"
      className="text-secondary mx-1 rounded"
    >
      {listMenu.map((item, index) => (
        <Dropdown.Item
          key={index}
          style={{ borderRadius: "15px" }}
          as="button"
          onClick={item.function}
        >
          {item.title}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DropdownComponent;
