import {
  Box,
  Collapse,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider
} from "@mui/material";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import React, { useEffect, useState } from "react";
import SelectComponent from "../../SelectComponent";
import useStaff from "../../../hooks/useStaff";
import useEvents from "../../../hooks/useEvents";

const today = dayjs();

export const ContentNote = ({ note, handleFillNote }) => {
  const { staff, userInfo } = useStaff();
  const { eventStates, selectColorCategory } = useEvents();
  const { categoryNotes, statusNotes, categoryEvent } = eventStates;

  const [dateValue, setDateValue] = useState(today);
  const [timeValue, setTimeValue] = useState(today);
  const [addToEvent, setAddToEvent] = useState(false);

  const handleAddToEvent = () => {
    setAddToEvent(!addToEvent);
    handleFillNote({ target: { value: !addToEvent, name: "isEvent" } });
  };

  const [isToDO, setIsToDo] = useState(false);

  const handleIsToDo = () => {
    setIsToDo(!isToDO);
    const e = { target: { value: !isToDO, name: "todo" } };
    handleFillNote(e);
  };

  useEffect(() => {
    if (!note.assignee || note.assignee === "") {
      handleFillNote({
        target: {
          name: "assignee",
          value: Number(staff.id)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToEvent]);

  useEffect(() => {
    const e = {
      target: {
        name: "start",
        value: dateValue
      }
    };
    handleFillNote(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  return (
    <Box>
      <Box
        sx={{ mb: 2, display: "flex", justifyContent: "space-between", gap: 2 }}
      >
        <Box sx={{ width: "45%" }}>
          <SelectComponent
            label="Note Type"
            valueData={note.category || ""}
            nameData="category"
            mapOptions={categoryNotes}
            handleFunction={handleFillNote}
          />
        </Box>
        <FormGroup sx={{ width: "20%" }}>
          <FormControlLabel
            control={<Switch checked={isToDO} onChange={handleIsToDo} />}
            label="It's a ToDo?"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={addToEvent} onChange={handleAddToEvent} />
            }
            label="Add note to calendar?"
          />
        </FormGroup>
      </Box>
      <Box>
        <Collapse in={isToDO}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 2,
              gap: 2
            }}
          >
            <SelectComponent
              label="ToDo Status"
              valueData={note.todo_status || ""}
              nameData="todo_status"
              mapOptions={statusNotes}
              handleFunction={handleFillNote}
            />
          </Box>
        </Collapse>
      </Box>
      <Box>
        <Collapse in={addToEvent}>
          <Divider
            sx={{
              mt: 1,
              borderBottomWidth: 5,
              mb: 2,
              borderColor: note.event_category
                ? selectColorCategory(note.event_category)
                : null
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 2,
              gap: 2
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select a Date"
                value={dateValue}
                onChange={(newValue) => {
                  setDateValue(newValue);
                  handleFillNote({
                    target: { name: "start", value: newValue }
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
                name="start"
                size="small"
              />
              <TimePicker
                label="Select Time"
                fullWidth
                value={timeValue}
                name="time_start"
                onChange={(newValue) => {
                  setTimeValue(newValue);
                  handleFillNote({
                    target: { name: "time_start", value: newValue }
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Box sx={{ width: "40%" }}>
              <FormControl fullWidth className="mt-lg-0">
                <InputLabel id="imput-to">Select an Assignee</InputLabel>
                <Select
                  labelId="imput-to"
                  name="assignee"
                  label="Select an Assignee"
                  value={note.assignee || ""}
                  onChange={handleFillNote}
                >
                  {userInfo?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.full_name || type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <SelectComponent
              label="Event Category"
              valueData={note.event_category || ""}
              nameData="event_category"
              mapOptions={categoryEvent}
              handleFunction={handleFillNote}
            />
          </Box>
          <Divider
            sx={{
              borderBottomWidth: 5,
              mb: 2,
              borderColor: note.event_category
                ? selectColorCategory(note.event_category)
                : null
            }}
          />
        </Collapse>
      </Box>
      <TextField
        id="outlined-textarea"
        label="Note Description"
        name="note"
        value={note.note ? note.note : ""}
        placeholder="Text note"
        onChange={handleFillNote}
        fullWidth
        multiline
      />
    </Box>
  );
};
