import React from 'react'
import ChartsCallsMade from '../charts/ChartsCallsMade'
import ReportDateSelect from './ReportDateSelect'
import ReportHeader from './ReportHeader'

const ReportCallsMade = () => {
  return (
    <>
    <ReportHeader>Charts Calls Made</ReportHeader>
    <ReportDateSelect/>
    <ChartsCallsMade/>
    </>
  )
}

export default ReportCallsMade