import { PostQuery } from "../../Extra/Querys";
import { validateNote } from "./validateNote";

export const createNote = async (note, staff, intake) => {
  const dataToSend = {
    data: {
      ...note,
      owner: Number(staff.id),
      group: Number(staff.group),
      intake: Number(intake.id)
    }
  };

  console.log("dataToSend", dataToSend);

  const resValidate = validateNote(dataToSend.data);
  if (resValidate.pass) {
    const res = PostQuery("/notes", "POST", dataToSend);
    console.log("createNote", res);
  } else {
    console.log("validateNote", resValidate);
  }
};
