import { useEffect } from "react";
import useDocuments from "../../../hooks/useDocuments";
import Loadign from "../../Loadign";
import CardDocument from "./CardDocument";
import { Button } from "react-bootstrap";
import { BsArrow90DegLeft } from "react-icons/bs";

const DocumentsList = ({ handleStep, setShow }) => {
  const {
    filesByIntake,
    getItemsbyIntake,
    filesInsideIntake,
    loadignDocs,
    setFilesInsideIntake,
    setFolderPath
  } = useDocuments();
  const handleFilesInsideIntake = () => {
    setFilesInsideIntake([]);
  };

  console.log("filesByIntake", filesByIntake);

  useEffect(() => {
    getItemsbyIntake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStep]);

  return (
    <div>
      <div className="d-flex justify-content-between align-content-center">
        <h6 className="m-auto">Document List</h6>
        {filesInsideIntake.length > 0 && (
          <Button
            className="btnDark px-3 d-flex gap-2 align-items-center"
            onClick={(e) => {
              handleFilesInsideIntake();
              setFolderPath("");
            }}
          >
            <BsArrow90DegLeft /> Back
          </Button>
        )}
      </div>
      {loadignDocs ? (
        <Loadign />
      ) : filesInsideIntake.length > 0 ? (
        filesInsideIntake?.map((doc) => (
          <CardDocument key={doc.id} doc={doc} setShow={setShow} />
        ))
      ) : (
        filesByIntake?.map((doc) => (
          <CardDocument key={doc.id} doc={doc} setShow={setShow} />
        ))
      )}
    </div>
  );
};

export default DocumentsList;
