import React from "react";
import { Row } from "react-bootstrap";
import ChartsStatusbyAssignee from "../charts/ChartsStatusbyAssignee";
import ReportDateSelect from "./ReportDateSelect";
import ReportHeader from "./ReportHeader";

const ReportIntakesByAssignee = () => {
  return (
    <Row>
      <ReportHeader>Charts Intake by asssignee</ReportHeader>
      <ReportDateSelect />
      <ChartsStatusbyAssignee />
    </Row>
  );
};

export default ReportIntakesByAssignee;
