import { Col } from "react-bootstrap";
import BasicQuestionForm from "../BasicQuestionForm";

const QuestionTextField = ({ question, answer, handler, size }) => {
  return (
    <Col md={size} className="mb-3">
      <BasicQuestionForm
        formLabel={question.name}
        formName={question.value}
        formType={question.type}
        formValue={`${
          answer[question.value] !== undefined
            ? answer[question.value]
            : ""
        }`}
        formFunction={handler}
        maxLength={question.maxLength !== undefined ? question.maxLength : null}
      />
    </Col>
  );
};

export default QuestionTextField;
