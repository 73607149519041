import { createContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import axios from "axios";
import { Device } from "@twilio/voice-sdk";
// import { io } from "socket.io-client";
import _ from "lodash";
import { PostQuery } from "../Extra/Querys";
import { callIncomingBlank } from "../Extra/interfaces";
import useStaff from "../hooks/useStaff";
import { notification } from "../Extra/notification";

const TwilioContext = createContext();
const twilioUrl = process.env.REACT_APP_CALLCENTER_URL;
// const socket = io.connect(twilioUrl, {
//   path: "/socket.io",
//   transports: ["websocket", "polling"]
// });
const socket = null;

const TwilioProvider = ({ children }) => {
  const { firmInfo, staff } = useStaff();

  console.log("socket id", socket.id);
  //AudioContext
  const [audioContextActive, setAudioContextActive] = useState(false);

  const handleAudioContext = () => {
    setAudioContextActive(!audioContextActive);

    const audioContext = new AudioContext();

    console.log("Audio context actived", audioContext);
  };
  const [token, setToken] = useState("");

  const [calls, setCalls] = useImmer({ calls: [] });
  const [callIncoming, setCallIncoming] = useState(callIncomingBlank);
  const [incominModal, setIncominModal] = useState(false);
  const [number, setNumber] = useState([]);
  const [onCall, setOnCall] = useState([]);
  const [time, setTime] = useState();

  const [callStatus, setCallStatus] = useState("");
  const [callSid, setCallSid] = useState();

  const [disableButtonCall, setdisableButtonCall] = useState(false);

  const [callsOnHold, setCallsOnHold] = useState([]);
  const [showCallsOnHold, setShowCallsOnHold] = useState(false);

  const [outgointCall, setOutgoingCall] = useState(false);

  const handleShowCallsOnHold = () => {
    setShowCallsOnHold(!showCallsOnHold);
  };

  const handleEndIncoming = () => {
    actionCall(callSid, "end");
    setCallIncoming(callIncomingBlank);
  };

  const [talkingWith, setTalkingWith] = useState({
    id: "",
    phoneContact: "",
    nameContact: "",
    emailContact: "",
    statusContact: ""
  });

  const [formIntakeTwilio, setFormIntakeTwilio] = useState(false);

  const handleSetCall = (data) => {
    const { CallSid, CallStatus, To, CallerCountry, Caller, Called } = data;

    console.log("incoming", firmInfo.incoming_call_number);
    console.log("To", To);
    console.log(firmInfo.incoming_call_number === To);

    if (firmInfo.incoming_call_number === To) {
      setCallIncoming({
        CallSid,
        CallStatus,
        To,
        CallerCountry,
        Caller,
        Called,
        Conected: false
      });
      setIncominModal(true);
      console.log("Call in frontend");
    }
  };

  const handleCallRespond = () => {
    setCallSid(callIncoming.CallSid);
    axios.post(`${twilioUrl}/api/call-respond`, { respond: true });
  };

  const actionCall = async (callSid, _type) => {
    try {
      const resp = await axios.post(`${twilioUrl}/api/modify-call`, {
        CallSid: callSid,
        name: staff.abbreviation_name,
        _type,
        socketId: socket.id
      });
      console.log(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const startCall = async (numberToCall) => {
    setCallStatus("");
    setdisableButtonCall(true);

    if (number !== "") {
      try {
        const resp = await axios.post(`${twilioUrl}/api/call-client`, {
          name: staff.abbreviation_name,
          number: numberToCall,
          fromNumber: firmInfo.phone_number,
          group: 1,
          socketId: socket.id
        });

        console.log("startCall", resp);
        // console.log("CallSid startCall", resp.data.CallSid);
        setCallSid(resp.data.CallSid);
        setCallStatus(resp.data.message);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleStatusChange = async (id) => {
    console.log("Click en status change");
    const dataToSend = { data: { pending_call: false } };
    const result = await PostQuery(`/intakes/${id}`, "PUT", dataToSend);
    console.log(result);
  };

  const SendSMS = async (
    primary_phone,
    message,
    from,
    name,
    intakeId = "",
    clienName = ""
  ) => {
    const staffResult = JSON.parse(localStorage.getItem("staff"));
    const urlBackend = process.env.REACT_APP_CALLCENTER_URL;

    var data = JSON.stringify({
      primary_phone,
      message,
      from,
      name,
      intakeId,
      clienName,
      group: staffResult.group
    });

    await PostQuery(`${urlBackend}/sms/send-sms`, "POST", data);
    notification("success", "SMS sent");
  };

  const connectTwilio = async (staffResult) => {
    const res = await axios.post(`${twilioUrl}/api/get-token`, {
      name: staffResult.abbreviation_name
    });
    setToken(res.data);
    const twilioToken = res.data;
    const device = new Device(twilioToken);

    device.on("registered", (device) => {
      console.log(
        `El dispositivo está listo para recibir llamadas entrantes. | ${staffResult.abbreviation_name}`
      );
    });

    device.register();

    device.on("incoming", function (connection) {
      console.log("incoming call");
      connection.accept();
      setOnCall(connection.parameters);
    });

    device.on("registering", function (conn) {
      console.log("Device offline", conn);
    });

    device.on("unregistered", function (conn) {
      console.log("Device offline", conn);
    });
  };

  useEffect(() => {
    const staffResult = JSON.parse(localStorage.getItem("staff"));
    if (staffResult === null) return;
    if (staffResult && audioContextActive) {
      connectTwilio(staffResult);

      socket.on("connect", () => {
        console.log("connect");
      });

      socket.on("calls-rining", ({ data }) => {
        console.log("Data llama entrante", data);
        handleSetCall(data);
      });

      socket.on("status-callback", (data) => {
        setCallStatus(data);
        console.log("status-callback", data);
      });

      socket.on("call-sid", (data) => {
        setCallSid(data);
        console.log("call-sid", data);
      });

      socket.on("already-respond", (data) => {
        console.log("Llamada entrante recibida por otra persona", data);
        setIncominModal(false);
      });

      socket.on("calls-done", (data) => {
        let callsDone = calls;
        const newCalls = _.filter(callsDone, (e) => e.CallSid !== data.CallSid);
        setCalls({ calls: newCalls });
      });

      socket.on("transfer-call", (data) => {
        if (staff.abbreviation_name === data.agent) {
          console.log("transfer-call", data);
          setCallsOnHold((prev) => [...prev, { ...data, time: new Date() }]);
          alert("New call waiting for you");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioContextActive]);

  return (
    <TwilioContext.Provider
      value={{
        calls,
        setCalls,
        number,
        setNumber,
        onCall,
        setOnCall,
        token,
        setToken,
        actionCall,
        startCall,
        talkingWith,
        setTalkingWith,
        time,
        setTime,
        disableButtonCall,
        setdisableButtonCall,
        formIntakeTwilio,
        setFormIntakeTwilio,
        twilioUrl,
        handleStatusChange,
        SendSMS,
        callIncoming,
        setCallIncoming,
        handleEndIncoming,
        incominModal,
        setIncominModal,
        handleCallRespond,
        callStatus,
        callSid,
        audioContextActive,
        handleAudioContext,
        //Nuevas funciones
        showCallsOnHold,
        handleShowCallsOnHold,
        callsOnHold,
        outgointCall,
        setOutgoingCall
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export { TwilioProvider };
export default TwilioContext;
