import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { FirstGetQuery, GetQuery, PostQuery } from "../Extra/Querys";
import { blankUser, configToast } from "../Extra/interfaces";
import { fillUserSelected } from "../Extra/fillUserSelected";
import { toast } from "react-toastify";
import { fillSetup } from "../Extra/fillSetupData";
import { fillBillType } from "../Extra/fillBillType";
import { getFirmData } from "./staffFunctions/getFirmData";
import { getUsersData } from "./staffFunctions/getUsersData";

const StaffContext = createContext();

// 6013534311
// 6013534324
// 3172141287
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StaffProvider = ({ children }) => {
  const [dataLogin, setDataLogin] = useState({
    identifier: "",
    password: ""
  });
  const [twoFactorData, setTwoFactorData] = useState();
  const [token, setToken] = useState("");
  const [staff, setStaff] = useState();
  const [logged, setLogged] = useState(false);
  const [status, setStatus] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const [fullUsersInfo, setFullUsersInfo] = useState([]);
  const [menuOpen, setMenuOpen] = useState(true);
  const [load, setLoad] = useState(false);
  const [permissionsUser, setPermissionsUser] = useState([]);
  const [userSelected, setUserSelected] = useState(blankUser);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [firmInfo, setFirmInfo] = useState({});
  const [billType, setBillType] = useState();

  //Set view Intake One at active call
  const [callActive, setCallActive] = useState(false);

  const notification = (type, message) => {
    if (type === "error") return toast.error(message, configToast);
    if (type === "success") return toast.success(message, configToast);
  };

  const HandleChangeDataLogin = (e) => {
    setDataLogin({ ...dataLogin, [e.target.name]: e.target.value });
  };

  const handleChangeDataTwoFactor = (e) => {
    setTwoFactorData(e.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    setLoad(false);
    setLogged(false);
  };

  const validateLogin = (data) => {
    setToken(data.jwt);
    setStaff(data.user);
    console.log(data.user);
    localStorage.setItem("tokenCase", data.jwt);
    localStorage.setItem("staff", JSON.stringify(data.user));
    if (data.jwt) {
      setLogged(true);
      setTwoFactor(false);
      setTwoFactorData();
      notification("success", `Welcome! ${data.user.full_name}`);
    }
  };

  const handleValidateTwoFactor = async () => {
    const res = await axios(`${backendUrl}/twofactorvalidate/${twoFactorData}`);

    if (res.data.data.jwt) {
      validateLogin(res.data.data);
    } else {
      notification("error", `InvalidCode`);
    }
  };

  const twoFactorSendCode = (data) => {
    const dataToSend = { temporalData: { data } };
    try {
      PostQuery(`/twofactor/${data.user.id}`, "POST", dataToSend);
    } catch (error) {
      return error;
    }
  };

  const handleLogin = async () => {
    try {
      const { data } = await axios.post(`${backendUrl}/auth/local`, dataLogin);

      if (data.user.twofactor) {
        setTwoFactor(true);
        twoFactorSendCode(data);
      } else {
        validateLogin(data);
      }
    } catch (error) {
      return error;
    }
  };

  const handleStatus = async (newStatus) => {
    const data = JSON.stringify({
      status: `${newStatus}`
    });
    const url = `/users/${staff.id}`;
    await PostQuery(url, "put", data);
  };

  const handleSetUserSelected = (id) => {
    const user = fillUserSelected(id, userInfo);
    setUserSelected(user);
  };

  const handleEditUser = async (e) => {
    setUserSelected({ ...userSelected, [e.target.name]: e.target.value });
  };

  const getUserInfo = async () => {
    const data = await getUsersData(staff, token);
    setUserInfo(data);
  };

  const getBusinessData = async (tokenResult) => {
    const data = await getUsersData(staff, token);
    setUserInfo(data);

    const permissions = await FirstGetQuery(
      `/users-permissions?populate=users_permissions_users&[filters][users_permissions_users][id][$eq]=${staff.id}`,
      tokenResult
    );

    if (firmInfo === null) {
      handleGetFirmData(staff.group, tokenResult);
    } else {
      const firmResult = localStorage.getItem("firm");
      setFirmInfo(firmResult);
    }

    const res = await FirstGetQuery(
      `/setups?filters[group][$eq]=${staff.group}&populate=*`,
      tokenResult
    );
    setFirmInfo(fillSetup(res.data[0]));
    localStorage.setItem("firm", JSON.stringify(fillSetup(res.data[0])));

    permissions.data.map((permission) =>
      setPermissionsUser((prev) => [...prev, permission.attributes.secction])
    );
    setLoad(true);
  };

  const getUserAdmin = async () => {
    const data = await GetQuery(`/users?[filters][group][$eq]=${staff.group}`);
    setFullUsersInfo(data);
  };

  const handleSubmitEditUser = async () => {
    const dataToSend = { ...userSelected };
    const result = await PostQuery(
      `/users/${userSelected.id}`,
      "PUT",
      dataToSend
    );
    if (result.status === 200) {
      notification("success", "User modified");
    } else {
      notification("error", "Sorry, something went wrong");
    }
  };

  const updadateStatus = async () => {
    const data = await GetQuery(
      `/users?populate=*&sort=id:asc&[filters][activation][$eq]=yes&[filters][group][$eq]=${staff.group}`
    );
    setUserInfo(data);
  };

  useEffect(() => {
    const tokenResult = localStorage.getItem("tokenCase");
    const staffResult = localStorage.getItem("staff");

    if (staffResult === null || tokenResult === null) return;

    if (tokenResult) {
      setToken(tokenResult);
      setStaff(JSON.parse(staffResult));
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);

  const loadInitialData = async (tokenResult) => {
    const billTypeResult = localStorage.getItem("billType");
    if (billTypeResult) {
      setBillType(fillBillType(JSON.parse(billTypeResult)));
    } else {
      console.log("pasa por aca 3");
      const { data } = await FirstGetQuery(
        `/bill-types?[filters][group][$eq]=${staff.group}`,
        tokenResult
      );
      setBillType(fillBillType(data));
      localStorage.setItem("billType", JSON.stringify(data));
    }
  };

  useEffect(() => {
    if (logged) {
      const tokenResult = localStorage.getItem("tokenCase");
      getBusinessData(tokenResult);
      loadInitialData(tokenResult);

      // Establece un intervalo para llamar a la función cada 3 minutos (180,000 milisegundos)
      const intervalo = setInterval(getUserInfo, 180000);

      // Limpia el intervalo cuando el componente se desmonta para evitar fugas de memoria
      return () => {
        clearInterval(intervalo);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  //disconnect all users when the admin change the status of the account
  useEffect(() => {
    if (logged) {
      if (staff.status_account === undefined || staff.status_account === null) {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff]);

  const handleGetFirmData = async (group, tokenResult) => {
    const res = await getFirmData(group, tokenResult);
    localStorage.removeItem("firm");
    setFirmInfo(res);
    localStorage.setItem("firm", JSON.stringify(res));
  };

  return (
    <StaffContext.Provider
      value={{
        dataLogin,
        logged,
        HandleChangeDataLogin,
        handleLogin,
        token,
        staff,
        setStaff,
        status,
        setStatus,
        handleLogout,
        handleStatus,
        callActive,
        setCallActive,
        userInfo,
        menuOpen,
        setMenuOpen,
        load,
        permissionsUser,
        userSelected,
        handleSetUserSelected,
        handleEditUser,
        openModalUser,
        setOpenModalUser,
        getUserAdmin,
        fullUsersInfo,
        handleSubmitEditUser,
        firmInfo,
        updadateStatus,
        twoFactor,
        handleChangeDataTwoFactor,
        twoFactorData,
        handleValidateTwoFactor,
        billType,
        //new functions
        handleGetFirmData
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};

export { StaffProvider };
export default StaffContext;
