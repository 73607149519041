import { TextField } from "@mui/material";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import useIntakes from "../../hooks/useIntakes";
import ModalNewContact from "../contacts/ModalNewContact";
import ExtraQuestions from "../contacts/ExtraQuestions";
import { contactInformationFields } from "./contactInformationFields";

const ContactInformation = () => {
  const {
    intake,
    clientSelected,
    source,
    handleSubmitEditContact,
    handleEditContact
  } = useIntakes();
  const [modalShow, setModalShow] = useState(false);

  const CONTACT_INFORMTION_FIELDS = contactInformationFields;

  const filterSource = (id) => {
    if (id === null || id === undefined || id === "") {
      return "No source";
    } else {
      const data = source.filter((data) => data.id === parseInt(id));
      return data[0].attributes.source || "Source error";
    }
  };

  return (
    <>
      <Row>
        {CONTACT_INFORMTION_FIELDS.map((field) => (
          <Col className="mb-3" key={field.name} md={6} sx={12}>
            <TextField
              id="outlined-basic"
              name={field.name}
              value={clientSelected[field.name] || ""}
              onChange={handleEditContact}
              variant="outlined"
              size="small"
              label={field.label}
              fullWidth
            />
          </Col>
        ))}
      </Row>
      <Col className="mb-3 d-flex gap-2">
        <TextField
          label="Date of birth"
          name="date_of_birth"
          value={clientSelected.date_of_birth || "2023-01-01"}
          variant="outlined"
          onChange={handleEditContact}
          InputLabelProps={{ shrink: true }}
          type="text"
          size="small"
          fullWidth
        />
        <TextField
          label="Source"
          name="source"
          value={filterSource(intake.source) || ""}
          onChange={() => {}}
          disabled
          size="small"
          fullWidth
        />
        <TextField
          label="City"
          name="city"
          value={clientSelected.city || ""}
          onChange={handleEditContact}
          size="small"
          fullWidth
        />
      </Col>
      <div className="d-flex gap-2">
        <Button
          variant="dark"
          className="px-3 col btnDark"
          style={{ borderRadius: "15px" }}
          onClick={() => {
            setModalShow(true);
          }}
        >
          Open full contact
        </Button>
        <Button
          className="px-3 col btnDark"
          style={{ borderRadius: "15px" }}
          onClick={() => handleSubmitEditContact()}
        >
          Save contact changes
        </Button>
      </div>
      <ModalNewContact show={modalShow} onHide={() => setModalShow(false)} />
      <ExtraQuestions reOpenModalConmtact={() => setModalShow(true)} />
    </>
  );
};

export default ContactInformation;
