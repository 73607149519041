export const intakeImportFields = (data) => {
  const fields = [
    {
      label: "Type of contact",
      items: data.typeOfContact,
      name: "type_of_contact"
    },
    {
      label: "Type of case",
      items: data.typeOfCase,
      name: "type_of_case"
    },
    {
      label: "Status",
      items: data.status,
      name: "status"
    },
    {
      label: "Source",
      items: data.source,
      name: "source"
    },
    {
      label: "Assignee",
      items: data.userInfo,
      name: "assignee"
    },
    {
      label: "Owner",
      items: data.userInfo,
      name: "owner"
    }
  ];

  return fields;
};
