import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import useStaff from "../../hooks/useStaff";
import { GetQuery } from "../../Extra/Querys";

const ManagerPermissions = () => {
  const { userInfo } = useStaff();
  const [permissionsUser, setPermissionsUser] = useState([]);

  const getUserInfo = async () => {
    const permissions = await GetQuery(
      `/users-permissions?populate=users_permissions_users&sort=id:asc`
    );
    setPermissionsUser(permissions.data);
  };

  useEffect(() => {
    getUserInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className="px-3 mt-3">
      {userInfo.map((user) => (
        <div key={user.id}>
          <p className="fw-bold">{user.full_name}</p>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              {permissionsUser.map((permission) => {
                const isValidPermission =
                  permission?.attributes.users_permissions_users.data.some(
                    (permiso) => permiso.id === user.id
                  );
                // console.log(isValidPermission);
                return (
                  <FormControlLabel
                    value="top"
                    control={<Checkbox checked={isValidPermission} />}
                    label={permission.attributes.secction}
                    labelPlacement="top"
                  />
                );
              })}
              <Button className="btnDark">Edit</Button>
            </FormGroup>
          </FormControl>
          <hr className="mt-3" />
        </div>
      ))}
    </Row>
  );
};

export default ManagerPermissions;
