export const fillUserSelected = (id, userInfo) => {
  const {
    username,
    email,
    confirmed,
    full_name,
    phone,
    abbreviation_name,
    job_title,
    hourly_rate,
    billiable_staff,
    rol,
    group,
    background_color,
    text_color,
    activation,
    expiration
  } = userInfo.find((user) => user.id === id);
  return {
    id,
    username,
    email,
    confirmed,
    full_name,
    phone,
    abbreviation_name,
    job_title,
    hourly_rate,
    billiable_staff,
    rol,
    group,
    background_color,
    text_color,
    activation,
    expiration
  };
};
