import { Col } from "react-bootstrap";
import "../App.css";

const Loadign = () => {
  return (
    <Col className="p-5 m-auto d-flex flex-column justify-content-center">
      <div className="lds-ring m-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="m-auto">
        <p className="fw-bold fs-4 mt-5">Loading...</p>
      </div>
    </Col>
  );
};

export default Loadign;
