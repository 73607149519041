import { TextField } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useIntakes from "../../hooks/useIntakes";

const ModalEditTypeOfCase = ({
  openModalTypeOdCase,
  setOpenModalTypeOdCase,
  selectedTypeOfCase,
  handleEditTypeOfCase
}) => {
  const { handleSubmitTypeOfCase } = useIntakes();

  return (
    <Modal
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      show={openModalTypeOdCase}
    >
      <Modal.Header closeButton onHide={() => setOpenModalTypeOdCase(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedTypeOfCase.id ? "Edit type of case" : "Add new type of case"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <TextField
          className="mt-3"
          required
          name="type_of_case_name"
          value={selectedTypeOfCase?.type_of_case_name || ""}
          onChange={handleEditTypeOfCase}
          variant="outlined"
          size="small"
          label="Type of case name"
          fullWidth
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderRadius: "15px" }}
          onClick={() => setOpenModalTypeOdCase(false)}
        >
          Close
        </Button>
        <Button
          variant="dark"
          className="bgDark px-5"
          style={{ borderRadius: "15px" }}
          onClick={() => {
            handleSubmitTypeOfCase(selectedTypeOfCase);
            setOpenModalTypeOdCase(false);
          }}
          type="submit"
        >
          {selectedTypeOfCase.id
            ? "Edit type of case"
            : "Save new type of case"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditTypeOfCase;
