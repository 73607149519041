import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useDashboard from "../../hooks/useDashboard";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useStaff from "../../hooks/useStaff";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartsStatusbyAssignee = () => {
  const { userInfo, staff } = useStaff();
  const { intakeForDash, loaded } = useDashboard();
  const [selectedUser, setSelectedUser] = useState(staff.id);
  const [totalIntakes, setTotalIntakes] = useState(0);
  const [values, setValues] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: `Total intakes ${totalIntakes} `,
      },
    },
  };

  console.log("selectedUser", selectedUser);

  const handleSetSelectedUser = (e) => {
    setSelectedUser(e.target.value);
  };

  const loadValues = () => {
    const intakesByAssignee = intakeForDash.filter(
      (intake) => intake.attributes.assignee === selectedUser
    );

    setTotalIntakes(intakesByAssignee.length);

    const statusinIntake = intakesByAssignee.reduce(
      (acc, { attributes: { status } }) => {
        if (
          status &&
          status.data &&
          status.data.attributes &&
          status.data.attributes.status
        ) {
          acc[status.data.attributes.status] =
            (acc[status.data.attributes.status] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    setValues(statusinIntake);
  };

  useEffect(() => {
    if (loaded) {
      loadValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, selectedUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: `Intakes by status by assignee `,
          data: values,
          backgroundColor: "#183440",
        },
      ],
      labels: Object.keys(values),
    };
  });

  return (
    <>
      <Box className="mb-4 d-flex align-items-center gap-5">
        <div>
          <p className="fw-bold m-auto">Select</p>
        </div>
        <div className="w-50">
          <FormControl fullWidth size="small">
            <InputLabel id="imput-to">Assignee</InputLabel>
            <Select
              labelId="imput-to"
              name="status"
              label="Assignee"
              onChange={handleSetSelectedUser}
              value={selectedUser}
            >
              {userInfo?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.abbreviation_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
      {loaded && <Bar data={data} options={options} />}
    </>
  );
};

export default ChartsStatusbyAssignee;
