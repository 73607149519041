import { notification } from "../../Extra/notification";

export const validateNewIntake = (intake) => {
  const fields = {
    type_of_contact: "Please select a contact type",
    type_of_case: "Please select a case type",
    status: "Please select a status",
    source: "Please select a source",
    assignee: "Please select an assignee",
    owner: "Please select an owner",
    contact: "Please enter a contact"
  };

  for (const [field, message] of Object.entries(fields)) {
    if (!intake[field]) {
      notification("error", message);
      return { continue: false, message };
    }
  }

  return { continue: true };
};

export const validateNewContactImport = (intake) => {
  const fields = {
    type_of_contact: "Please select a contact type",
    type_of_case: "Please select a case type",
    status: "Please select a status",
    source: "Please select a source",
    assignee: "Please select an assignee",
    owner: "Please select an owner"
  };

  for (const [field, message] of Object.entries(fields)) {
    if (!intake[field]) {
      Notification("error", message);
      return { continue: false, message };
    }
  }

  return { continue: true };
};
