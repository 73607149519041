import { BsPencilSquare, BsXCircle } from "react-icons/bs";
import { Box, Button, Grid } from "@mui/material";
import useStaff from "../../../hooks/useStaff";
import useEvents from "../../../hooks/useEvents";
import { formatMyTime } from "../../../Extra";

const NotesLists = ({ note, setAddNote }) => {
  const { userInfo } = useStaff();
  const { handleNoteToModal, handleDeleteNote } = useEvents();
  const intakeNote = note.attributes.intake.data.id || null;

  const filterUser = (id) => {
    if (userInfo.length !== 0) {
      if (id === 3) {
        return "Juan Cuellar";
      } else if (id === 8) {
        return "Juan Cuellar";
      } else {
        const data = userInfo?.filter((data) => data.id === id);
        return data[0].full_name;
      }
    }
  };

  return (
    <>
      <Box className="mb-3">
        <p className="m-1" style={{ fontSize: "14px" }}>
          Note: {note.attributes.note}
        </p>
        <Grid container rowSpacing={1}>
          <Grid item xs={4}>
            <p className="m-1" style={{ fontSize: "12px" }}>
              <span className="fw-semibold">Name </span>:{" "}
              {filterUser(note.attributes.owner)}
            </p>
          </Grid>
          <Grid item xs={5}>
            <p className="m-1" style={{ fontSize: "12px" }}>
              <span className="fw-semibold">
                {note.attributes.createdAt === note.attributes.updatedAt
                  ? "Date"
                  : "Modified"}
              </span>
              : {formatMyTime(note.attributes.createdAt)}
            </p>
          </Grid>

          <Grid item xs={3} className="d-flex">
            {note.attributes.biliable ? (
              <small className="m-auto">Biliable note</small>
            ) : (
              <>
                <Button
                  className="p-0 m-0"
                  onClick={() => {
                    handleNoteToModal(note, note.id, intakeNote);
                    setAddNote("regular");
                  }}
                >
                  <BsPencilSquare />
                </Button>
                <Button
                  className="p-0 m-0"
                  sx={{ color: "red" }}
                  onClick={() => {
                    if (window.confirm(`Do you want to delete this note?`)) {
                      handleDeleteNote(note, note.id, intakeNote);
                    }
                  }}
                >
                  <BsXCircle />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <hr />
    </>
  );
};

export default NotesLists;
