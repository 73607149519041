const qs = require("qs");

export const getIntakesQuery = (staff, startDate, finalDate) => {
  const query = qs.stringify(
    {
      populate: "*",
      group: { $eq: staff.group },
      filters: {
        createdAt: { $between: [startDate, finalDate] },
        open: { $eq: true },
      },
      pagination: { pageSize: 5000 },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  console.log("query", query);

  return query;
};
