import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { formatMyDate } from "../../../Extra";
import { BsTelephoneForwardFill } from "react-icons/bs";
import useIntakes from "../../../hooks/useIntakes";
// import useStaff from "../../../hooks/useStaff";
import useSoftphone from "../../../hooks/useSoftphone";
import { toast } from "react-toastify";
// import { CurrentCall } from "../../../twilio/CurrentCall";
// import { ModalTranferCall } from "../../call/ModalTranferCall";

export const TopSubMenu = ({ setModalShow }) => {
	// const { setCallActive } = useStaff();
	const { intake, clientSelected } = useIntakes();

	// const {
	//   startCall,
	//   audioContextActive,
	//   setdisableButtonCall,
	//   setTalkingWith,
	//   outgointCall,
	//   setOutgoingCall
	// } = useTwilio();

        
		const { callJsSIP, terminateJsSIP,  callIncoming, activo} = useSoftphone();

	// const [transferCall, setTransferCall] = useState(false);

	// const handleCloseTransferCall = () => {
	//   setTransferCall(!transferCall);
	// };

	// const { primary_phone, first_name, last_name } = clientSelected;
	const { primary_phone } = clientSelected;
	// const primary_phone = "+526143983970";


	console.log("Llamaras=>", primary_phone);

	// const handleTalkingWith = () => {
	//   setTalkingWith({
	//     id: intake.id,
	//     phoneContact: primary_phone,
	//     nameContact: `${first_name}, ${last_name}`,
	//     emailContact: ""
	//   });
	// };

	// const handleCall = async () => {
	// 	console.log("handleCall");
	// 	await setLoad(false);
	// 	try {
	// 		await callJsSIP(primary_phone)
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setLoad(true);
	// 	}
	// };

	return (
		<>
			<Row className="px-0 mx-0 d-flex justify-content-center align-items-center w-100 mt-2">
				<Col className="d-flex justify-content-center">
					<h6>Intake No. {intake.id} </h6>
				</Col>
				<Col className="d-flex justify-content-center">
					<h6>Create at {formatMyDate(intake.createdAt)}</h6>
				</Col>
				<Col className="d-flex gap-3 justify-content-end align-self-center">
					{callIncoming===false ? (
						<Button
							style={{ borderRadius: "15px" }}
							className="md-px-1 lg-px-2  xl-px-5 d-flex gap-2 align-items-center"
							size={"sm"}
							variant="outline-success"
							onClick={() => {
								if (activo === false) {
									toast.error("You are not active", {
										position: "top-right",
										autoClose: 1000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
									});
									return;
								}

								toast.success("Calling...", {
									position: "top-center",
									autoClose: 1000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
								});
								// setLoad(false);
								callJsSIP(primary_phone);
								// setCallActive(true);
								// setdisableButtonCall(true);
								// setOutgoingCall(true);
								// handleTalkingWith();
							}}
							// disabled={!audioContextActive}
						>
							<BsTelephoneForwardFill />
							Call now
						</Button>
					) : (
						<Button
							style={{ borderRadius: "15px" }}
							className="md-px-1 lg-px-2  xl-px-5 d-flex gap-2 align-items-center"
							size={"sm"}
							variant="outline-dark"
							disabled={true}
							// onClick={() => {
							//   setLoad(true);
							// 	callJsSIP(primary_phone);
							// 	// setCallActive(true);
							// 	// setdisableButtonCall(true);
							// 	// setOutgoingCall(true);
							// 	// handleTalkingWith();
							// }}
							// disabled={!audioContextActive}
						>
							Calling...
							<svg
								className="animate-spin h-5 w-5 text-white inline-block"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						</Button>
					)}
					<Button
						style={{ borderRadius: "15px" }}
						className="md-px-1 lg-px-2  xl-px-5 d-flex gap-2 align-items-center"
						size={"sm"}
						variant="outline-success"
						onClick={() => {
							terminateJsSIP();
							// setCallActive(true);
							// setdisableButtonCall(true);
							// setOutgoingCall(true);
							// handleTalkingWith();
						}}
						// disabled={!audioContextActive}
					>
						<BsTelephoneForwardFill />
						Terminate Call
					</Button>

					<Button
						style={{ borderRadius: "15px" }}
						className="md-px-1 lg-px-2  xl-px-5 d-flex gap-2 align-items-center"
						size={"sm"}
						variant="outline-success"
						// disabled={!audioContextActive}
						onClick={() => {
							setModalShow(true);
						}}
					>
						<BsTelephoneForwardFill />
						Send new SMS
					</Button>
				</Col>
			</Row>
			{/* {transferCall && (
        <ModalTranferCall
          show={transferCall}
          handleClose={handleCloseTransferCall}
          setOutgoingCall={setOutgoingCall}
        />
      )}
      {outgointCall === true ? (
        <CurrentCall
          setOutgoingCall={setOutgoingCall}
          outgointCall={outgointCall}
          transferCall={handleCloseTransferCall}
        />
      ) : null} */}
		</>
	);
};
