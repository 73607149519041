import { Button, Col, Image, Row } from "react-bootstrap";
import useStaff from "../hooks/useStaff";
import { useEffect, useState } from "react";

const LoadignInitial = () => {
  const { handleLogout } = useStaff();
  const [count, setCount] = useState(5);
  const [dots, setDots] = useState(".");

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return "";
        } else {
          return prevDots + ".";
        }
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <Row style={{ height: "90vh" }}>
      <div className="d-flex flex-column m-auto justify-content-center align-self-center">
        <Image className="m-auto" src="/img/logo.png" alt="logo" width={200} />
        <p className="m-auto fw-bold">Loading{dots}</p>
        <p className="m-auto">
          If it is taking a long time to load please refresh the page or login
          again.
        </p>
        {count === 0 && (
          <Col className="d-flex justify-content-center mt-5">
            <Button className="btnDark px-5" onClick={handleLogout}>
              Re login
            </Button>
          </Col>
        )}
      </div>
    </Row>
  );
};

export default LoadignInitial;
