import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import useIntakes from "../../hooks/useIntakes";
import ContactInformation from "./ContactInformation";
import IntakeInformation from "./IntakeInformation";
import BottomMenu from "./menu/BottomMenu";
import { TopSubMenu } from "./menu/TopSubMenu";
import { useState } from "react";
// import { CallsContainer } from "./callsSmsEvents/CallsContainer";
import DocumentsAutomations from "./DocumentsAutomations";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import DocumentsContainer from "./documents/DocumentsContainer";
import NotesContainer from "./notes/NotesContainer";

export const IntakeFormStepOne = () => {
  const { intake } = useIntakes();

  const [handleStep, setHandleStep] = useState(1);
  const [open, setOpen] = useState(true);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <Button
        className="btnBlank"
        onClick={() => {
          setOpen(!open);
          decoratedOnClick();
        }}
      >
        {children}
      </Button>
    );
  }

  return (
    <>
      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen flush>
        <Card>
          <Card.Header>
            <Row>
              <Col md={11}>
                <TopSubMenu />
              </Col>
              <Col>
                <CustomToggle eventKey="0">
                  {open ? <BsChevronCompactUp /> : <BsChevronCompactDown />}
                </CustomToggle>
              </Col>
            </Row>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {intake.contact !== 0 && (
                <Row className="px-5 py-2">
                  <Col className="border-end" xs={12} md={6}>
                    <ContactInformation />
                  </Col>
                  <Col xs={12} md={6} className="mt-5 mt-md-0">
                    <IntakeInformation />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Accordion.Item eventKey="1">
          <Accordion.Body>
            <BottomMenu handleStep={handleStep} setHandleStep={setHandleStep} />
            <Row>
              {/* {handleStep === 1 && <CallsContainer />} */}
              {handleStep === 2 && <DocumentsAutomations />}
              {handleStep === 3 && <NotesContainer />}
              {handleStep === 4 && <DocumentsContainer />}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
