export const blankEvent = {
  time_start: "",
  time_end: "",
  start: "",
  end: "",
  assignee: "",
  description: "",
  owner: "",
  group: "",
  category: "",
  title: "",
  allDay: false
};

export const blankIntake = {
  type_of_contact: "",
  type_of_case: "",
  status: "",
  source: "",
  assignee: "",
  owner: "",
  group: "",
  pending_call: false,
  open: true,
  contact_BQ_complete: false
};

export const configToast = {
  position: "top-center",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark"
};

export const blankNote = {
  note: "",
  owner: "",
  group: "",
  active: true
};

export const blankClient = {
  first_name: "",
  last_name: "",
  primary_phone: "",
  alias: null,
  company_name: null,
  contact_preference: null,
  createdAt: null,
  date_of_birth: null,
  date_of_death: null,
  email: null,
  gender: null,
  group: null,
  home_phone: null,
  job_title: null,
  language: null,
  marital_status: null,
  nationality: null,
  secondary_email: null,
  ssn: null,
  suffix: null,
  work_phone: null,
  address: null,
  extra_question: 1,
  extra_answer: {},
  citizen_status: null,
  a_number: null,
  country_birth: null,
  city_birth: null,
  country_address: null,
  type_address: null,
  type_number: null,
  province: null,
  postal_code: null,
  state_birth: null
};

export const blankUser = {
  username: "",
  email: "",
  confirmed: true,
  full_name: "",
  phone: "",
  abbreviation_name: "",
  job_title: "",
  hourly_rate: 0,
  billiable_staff: false,
  rol: "",
  group: 1,
  background_color: "#",
  text_color: "#",
  activation: "",
  expiration: null,
  status_account: "",
  extension: "",
  extension_password: ""
};

export const blankTypeOfCase = {
  id: 0,
  type_of_case_name: ""
};

export const blankStatus = {
  id: 0,
  status: "",
  color: "",
  backgroundColor: ""
};

export const numberBlank = [
  {
    id: 0,
    attributes: {
      number: "",
      active: true,
      group: 1
    }
  }
];

export const typeOfClient = {
  id: 0,
  type_of_contact_name: "",
  message: ""
};

export const callIncomingBlank = {
  CallSid: "",
  CallStatus: "",
  To: "+",
  CallerCountry: "",
  Caller: "",
  Called: "",
  Conected: false
};

export const blankExtraQuestionForm = {
  attributes: {
    title: "",
    structure: []
  }
};
