const moment = require("moment-timezone");

export function formatMyDate(value, locale = "en-US") {
  return new Date(value).toLocaleDateString(locale);
}

export function formatMyTime(value) {
  const date = new Date(value);
  let formatedTimeZone = moment
    .tz(date, "America/Los_Angeles")
    .format("YYYY-MM-DD HH:mm:ss");
  return formatedTimeZone;
}

export function timeSeparation(value) {
  const date = new Date(value);
  let formatedTimeZone = moment
    .tz(date, "America/Los_Angeles")
    .format("HH:mm:ss");
  return formatedTimeZone;
}
