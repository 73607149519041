import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";

const ReportHeader = ({ children }) => {
  return (
    <Row className="mt-3 px-5">
      <Col>
        <p className="fw-bold fs-4 txtDark"> {children}</p>
      </Col>
      <Col className="d-flex justify-content-end">
        <Link to="/reports">
          <Button className="btnDark px-5">
            <BsChevronLeft /> Back
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default ReportHeader;
