import { Col, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import FormLogin from "../components/FormLogin";
import useStaff from "../hooks/useStaff";
import TwoFactor from "../components/TwoFactor";

const Login = () => {
  const { logged, twoFactor } = useStaff();

  let route = "/";

  return (
    <>
      {logged && <Navigate to={route} />}
      <Row
        style={{ height: "100vh" }}
        className="align-items-center pl-md-5 px-xs-2  px-xs-2 mx-0"
      >
        <Col sm={12} md={6} lg={5} xl={4}>
          {twoFactor ? <TwoFactor /> : <FormLogin />}
        </Col>
        <Col
          height={100}
          className="mt-5 mt-md-0"
          width={100}
          sm={12}
          md={6}
          lg={7}
          xl={8}
          style={{ backgroundColor: "#183440", height: "100vh" }}
        ></Col>
      </Row>
    </>
  );
};

export default Login;
