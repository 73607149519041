import useIntakes from "../../hooks/useIntakes";
import { Button, Row } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import CheckPermissions from "../CheckPermissions";
import { formatMyDate } from "../../Extra";

export const IntakeFormStepFour = () => {
  const { intake } = useIntakes();

  const sortModel = [
    {
      field: "id",
      sort: "asc"
    }
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      headerName: "Description",
      field: "description",
      valueGetter: (parame) => {
        return parame.row.attributes.description;
      },
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Date",
      field: "Date",
      valueGetter: (parame) => {
        return formatMyDate(parame.row.attributes.createdAt);
      },
      maxWidth: 120
    },
    {
      headerName: "Time",
      field: "time",
      valueGetter: (parame) => {
        return `${parame.row.attributes.time} min.`;
      },
      maxWidth: 120
    },
    {
      headerName: "Total",
      field: "total",
      valueGetter: (parame) => {
        return `$ ${parame.row.attributes.total}`;
      },
      maxWidth: 120
    },
    {
      headerName: "Edit",
      renderCell: (cellValues) => {
        return (
          <Button
            className="btnDark text-white d-flex gap-3 px-5 justify-content-center align-items-center m-auto"
            onClick={() => {
              console.log(cellValues.row.id);
            }}
            disabled
          >
            Edit
          </Button>
        );
      },
      minWidth: 180,
      maxWidth: 250
    }
  ];

  return (
    <CheckPermissions page="setup">
      <Row className="p-3">
        <Row className="my-2">
          <h4>Bill information</h4>
        </Row>
        {intake.bill_histories && (
          <DataGrid
            autoHeight
            rows={intake.bill_histories}
            columns={columns}
            sortModel={sortModel}
          />
        )}
      </Row>
    </CheckPermissions>
  );
};
