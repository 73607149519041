import { Col, Row } from "react-bootstrap";
import { BsListUl } from "react-icons/bs";
import DropdownComponent from "../../DropdownComponent";
import DocumentsList from "./DocumentsList";
import CreateFolderModal from "./CreateFolderModal";
import { useState } from "react";
import UploadDocumentModal from "./UploadDocumentModal";

const DocumentsContainer = () => {
  const [newFolderShow, setNewFolderShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleModalNewFolder = () => {
    setNewFolderShow(!newFolderShow);
  };

  const handleModal = () => {
    setShow(!show);
  };

  const LIST_MENU = [
    {
      function: () => handleModalNewFolder(),
      title: "New Folder"
    },
    {
      function: () => handleModal(),
      title: "Upload new document"
    }
  ];

  return (
    <>
      <Row>
        <Col>
          <p className="fs-5 fw-semibolds my-auto">Documents</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <DropdownComponent listMenu={LIST_MENU} title={<BsListUl />} />
        </Col>
      </Row>
      <Row>
        <DocumentsList setShow={setShow} />
      </Row>
      <CreateFolderModal show={newFolderShow} onHide={handleModalNewFolder} />
      <UploadDocumentModal show={show} onHide={handleModal} />
    </>
  );
};

export default DocumentsContainer;
