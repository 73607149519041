import { Link, useLocation } from "react-router-dom";
import { BsGear, BsFileEarmarkBarGraph } from "react-icons/bs";
import useStaff from "../hooks/useStaff";

const MenuExtraAdmin = () => {
  const location = useLocation();
  const urlActual = location.pathname;
  const { menuOpen, permissionsUser } = useStaff();

  const sizeIcon = 20;

  return (
    <>
      {permissionsUser.includes("reports") && (
        <>
          <Link
            className={`${
              urlActual === "/reports" ? "text-dark bg-light" : " text-light"
            }  h-text-dark text-decoration-none ${
              menuOpen ? "px-5" : "px-2"
            } d-block py-2`}
            to="/reports"
          >
            <BsFileEarmarkBarGraph size={sizeIcon} /> {menuOpen && "Reports"}
          </Link>
          <Link
            className={`${
              urlActual === "/setup" ? "text-dark bg-light" : " text-light"
            }  h-text-dark text-decoration-none ${
              menuOpen ? "px-5" : "px-2"
            } d-block py-2`}
            to="/setup"
          >
            <BsGear size={sizeIcon} /> {menuOpen && "Setup"}
          </Link>
        </>
      )}
    </>
  );
};

export default MenuExtraAdmin;
