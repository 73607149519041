/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft, BsCloudUploadFill, BsSave } from "react-icons/bs";
import Papa from "papaparse";
import useIntakes from "../hooks/useIntakes";
import { blankIntake } from "../Extra/interfaces";
import IntaketConfiguration from "../components/intake/IntaketConfiguration";
import CheckPermissions from "../components/CheckPermissions";
import { PostQuery } from "../Extra/Querys";
import { notification } from "../Extra/notification";

const allowedExtensions = ["csv"];

const Import = () => {
  const navigate = useNavigate();

  const { handleImportContacts, setIntake } = useIntakes();

  const [jsonResult, setJsonResult] = useState();
  const [avalibleLoadFile, setAvalibleLoadFile] = useState(true);
  const [time, setTime] = useState();

  useEffect(() => {
    setIntake(blankIntake);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (!jsonResult) return notification("error", "Please load a file");

    const postError = async (errorMessage, contact) => {
      const url = `/error-logs`;
      const datatosend = JSON.stringify({
        data: {
          title: errorMessage,
          cause: contact
        }
      });
      notification("error", errorMessage);
      await PostQuery(url, "POST", datatosend);
    };

    jsonResult.map(async (contact) => {
      const number = parseInt(contact.primary_phone);

      if (!contact.primary_phone)
        return postError(
          "Please load a file with primary_phone column",
          contact
        );

      if (!contact.first_name)
        return postError("Please load a file with first_name column", contact);

      if (!contact.last_name)
        return postError("Please load a file with last_name column", contact);

      if (!contact.group)
        return postError("Please load a file with group column", contact);

      if (isNaN(number))
        return postError("Please enter a valid phone number", contact);

      if (contact.primary_phone && contact.primary_phone.length < 10)
        return postError(
          "The phone number must be at least 10 digits",
          contact
        );

      if (contact.primary_phone.substring(0, 1) !== "+")
        return postError("Please enter a valid phone number", contact);

      // contact.group = parseInt(contact.group);

      await handleImportContacts(contact);
    });

    notification("success", "Import finished successfully");
  };

  const hanldeLoad = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setJsonResult(results.data);
      }
    });
  };

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 130);
    }
  }, [time]);

  return (
    <CheckPermissions page={"import"}>
      <Row className="mt-3 px-4">
        <Col>
          <h2 className="txtDark m-0 p-0" style={{ fontWeight: "700" }}>
            Import from csv
          </h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="btnDark px-3 mx-3"
            style={{ borderRadius: "15px" }}
            onClick={() => navigate("/import-notes")}
          >
            <BsSave /> Import notes
          </Button>
          <Button
            className="btnDark px-3"
            style={{ borderRadius: "15px" }}
            onClick={() => navigate("/new-intake")}
          >
            <BsChevronLeft /> Back
          </Button>
        </Col>
      </Row>
      <IntaketConfiguration setAvalibleLoadFile={setAvalibleLoadFile} />
      <Row className="px-5">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Enter CSV File</Form.Label>
            <Form.Control
              onChange={(e) => hanldeLoad(e)}
              id="csvInput"
              name="file"
              type="File"
              accept=".csv"
            />
          </Form.Group>
        </Col>
        <Col className="align-self-end">
          <Button
            variant="dark"
            className="fw-bold px-3 bgDark align-self-center justify-content-end mb-3"
            style={{ borderRadius: "15px" }}
            onClick={() => {
              handleSubmit();
              setTime(200);
            }}
            disabled={avalibleLoadFile}
          >
            <BsCloudUploadFill /> Load File
          </Button>
        </Col>
        <Row className="px-5">
          {time > 0 && <ProgressBar variant="success" now={time} />}
        </Row>
      </Row>
    </CheckPermissions>
  );
};

export default Import;
